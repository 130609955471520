const timeFormatoSimple = (time) => {
    


    let meridiano = {
        1 : "PM",
        2 : "AM"
    }

    

    let tipoMeridiado = 1;
    let horas = parseInt(time.substring(0,2));


    if(horas >= 13){        
        tipoMeridiado = 2;
        horas = horas - 12
    }

    if(horas < 10){
        horas = "0"+horas

    }

    let minutos = time.substring(3,5);
    let segundos = time.substring(6,8);
    
    return horas +':'+ minutos+':'+segundos+' '+meridiano[tipoMeridiado];
}

const Time = {
    timeFormatoSimple
}

export default Time;