import { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupText,    
    Button,
    FormFeedback,
} from "reactstrap";
import {
    AccountCircle,
    Lock,
    VisibilityOff,
    Visibility,
} from '@material-ui/icons';
import userLogo from "../../Multimedia/undraw_website_builder_re_ii6e.svg";
import colors from "../../Configuration/Colors.config"
import Cookies from "universal-cookie";
import swal2 from 'sweetalert2';
import userModel from "../../Model/Usuario.model";

const cookies = new Cookies();

class LoginTeamp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: false,
            inputType: "password",
            usuario: {
                username: "",
                password: "",
                IdUsuarioTipo: 2
            },
            style: {
                label: {
                    fontSize: "20px",
                },
                input: {
                    boxShadow: "none",
                    borderColor: colors.azul_1,
                },
                row: {
                    marginRight: "10px",
                    marginLeft: "10px",
                    //textAlign: "center"
                },
                button: {
                    width: "100%",
                    backgroundColor: colors.azul_1,
                    borderColor: "#f5f5f5",
                    borderRadius: "8px",
                    color: colors.blanco,
                    fontSize: "19px",
                }
            },
            input : {                
                username : false,
                password : false,
            },
            usuarioStatus : 0,
        }
    }


    async componentDidMount() {

        document.body.style.backgroundColor = "#00548D"//colors.azul_1;
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        // if (navigator.geolocation) {
        //     console.log("Si lo soporta");
        //     navigator.geolocation.getCurrentPosition(function(position) { 
        //         let lat = position.coords.latitude;
        //         let long = position.coords.longitude;
        //         let posicion = {
        //             lat,
        //             long
        //         }
        //         console.log(posicion);
        //         cookies.set("posicion", posicion , { path: "/"})
        //     });
        // }else{
        //     console.log("No lo soporta");
        // }

        this.setState({
            isMobileDevice: isMobileDevice,
        });



    }

    handleChangeUsuario = async (e) => {

        let { name, value } = e.target;
        console.log(name, value);
        await this.setState({
            usuario: {
                ...this.state.usuario,
                [name]: value,
            },
            input : {
                ...this.state.input,
                [name] : value.length === 0 ? true : false,
            }
        });       
       
    }

    fucusElement = (Id) => {
        document.getElementById(Id).focus();
    }

    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 60000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });

    iniciarSesion = async () => {

        

        if(this.state.usuario.username.length === 0 || this.state.usuario.password.length === 0){

            this.Toast.fire({   
                icon:'warning',
                text:"Faltan datos para poder loguearte"
            });

            this.setState({
                input : {
                    username : this.state.usuario.username.length === 0 ? true : false,
                    password : this.state.usuario.password.length === 0 ? true : false,
                }
            });

            if(this.state.usuario.username.length === 0){
                this.fucusElement("IdIptUsername");
            }else{
                this.fucusElement("IdIptPassword");
            }

        }else{
            this.Toast.fire({   
                icon:'info',
                text:"Iniciando sesión"
            });
            await userModel.usuario_login(this.state.usuario, async (data) => {

                let { status, usuario } = data;
    
                let icon = "";
                let title = "";
                let text = "";
    
                if (status === 200 && usuario.IdStatus === 1) {

                    cookies.set("usuario", usuario, { path: "/" })
                    cookies.set("token", usuario.token, { path: "/" })
                    //alert(usuario.IdUsuarioTipo)
                    window.location.href = '/listado-cursos-publico'

                } else {

                    if (status === 200 && usuario.IdStatus === 2) {
                        icon = "warning";
                        title = "Cuenta Bloqueada";
                        text = "Esta cuenta ha sido bloqueada por los administradores";
                        await this.setState({
                            usuarioStatus : 201
                        });
                    }
    
                    if (status === 404) {
                        icon = "error";
                        title = "Acceso denegado";
                        text = "No se encontró un perfil con ese nombre de usuario y/o contraseña";
                        await this.setState({
                            usuarioStatus : 404
                        });
                    }
                    
                    swal2.fire({
                        icon,
                        title,
                        text,
                        timer: "60000",
                        confirmButtonColor: colors.azul_1,
                        confirmButtonText: 'Okey',
                        allowEscapeKey: true,
                        timerProgressBar: true,
        
                    });

                }
            });
        }
    }

    render() {
        return (
            <>
                <div

                >
                    <div
                        style={{
                            backgroundColor: colors.blanco,
                            marginTop: this.state.isMobileDevice ? "20%" : "3%",
                            marginLeft: this.state.isMobileDevice ? "10px" : "32.5%",
                            marginRight: this.state.isMobileDevice ? "10px" : "32.5%",
                            width: this.state.isMobileDevice ? "" : "35%",
                            borderRadius: "8px"
                        }}
                    >
                        <Row style={this.state.style.row}>
                            <Col
                                style={{
                                    marginTop: "40px",
                                    textAlign: "center"
                                }}
                            >
                                <Row>
                                    <Col>
                                        <img
                                            src={userLogo}
                                            height="150px"
                                            style={{                                                
                                                borderColor: "#f5f5f5",
                                            }}
                                            alt=""
                                        />
                                    </Col>
                                </Row>

                                {/* <br /> */}
                                <Row>
                                    <Col
                                        style={{
                                            marginTop: "20px",

                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "35px"
                                            }}
                                        >
                                            LOGIN TEAM
                                        </Label>`
                                    </Col>`
                                </Row>
                                {
                                this.state.usuarioStatus === 404 ?
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            color: "red"
                                        }}
                                    >
                                        Acceso Nedegado
                                    </Label>
                                    :
                                    this.state.usuarioStatus === 201 ?
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "24px",
                                                color:"orange"
                                            }}
                                        >
                                            Cuenta Bloqueada
                                        </Label> 
                                        :
                                        null
                                }
                                
                                <Row>
                                    <Col>
                                
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        <Row
                            style={this.state.style.row}
                        >
                            <Col
                                style={{
                                    marginTop: "30px"
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={this.state.style.label}
                                    >
                                        Nombre de Usuario
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText
                                            style={{
                                                borderColor: colors.azul_1,
                                                backgroundColor: colors.azul_1,
                                            }}
                                        >
                                            <AccountCircle style={{ color: "#fff", }} />
                                        </InputGroupText>
                                        <Input
                                            id="IdIptUsername"
                                            autoFocus
                                            style={this.state.style.input}
                                            name="username"
                                            onChange={(e) => {
                                                this.handleChangeUsuario(e);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") this.fucusElement("IdIptPassword");
                                            }}
                                            value={this.state.usuario.username}
                                            type="text"
                                            invalid={this.state.input.username}
                                        />
                                        <FormFeedback
                                            tooltip
                                            // style={this.state.formFeedbackStyle}
                                        >
                                            Falta el Nombre de Usuario.
                                        </FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row
                            style={this.state.style.row}
                        >
                            <Col
                                style={{
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={this.state.style.label}
                                    >
                                        Contraseña
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText
                                            style={{
                                                borderColor: colors.azul_1,
                                                backgroundColor: colors.azul_1,
                                            }}
                                        >
                                            <Lock style={{ color: "#fff", }} />
                                        </InputGroupText>
                                        <Input
                                            id="IdIptPassword"
                                            style={this.state.style.input}
                                            type={this.state.inputType}
                                            name="password"
                                            onChange={(e) => {
                                                this.handleChangeUsuario(e);
                                            }}
                                            value={this.state.usuario.password}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    // Mandamos a llamar la funcion para loguearse
                                                   
                                                    this.iniciarSesion();

                                                }
                                            }}
                                            invalid={this.state.input.password}
                                        />
                                        <FormFeedback
                                            tooltip
                                            // style={this.state.formFeedbackStyle}
                                        >
                                            Falta el contraseña del Usuario.
                                        </FormFeedback>
                                        <InputGroupText
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: "#fff",
                                                opacity: this.state.opacity,
                                            }}
                                            onClick={async () => {
                                                await this.setState({
                                                    isVisibilityPassword: !this.state.isVisibilityPassword,
                                                    inputType: this.state.isVisibilityPassword ? "password" : "text",
                                                });
                                            }}>
                                            {this.state.isVisibilityPassword ?
                                                <Visibility style={{ color: colors.azul_1 }} />
                                                :
                                                <VisibilityOff style={{ color: colors.azul_1 }} />
                                            }
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                marginTop: "30px",
                                marginBottom: "30px"
                            }}
                        >
                            <Col

                            >
                                <Button
                                    style={this.state.style.button}
                                    onClick={async ()=>{
                                        await this.setState({
                                            input : {                
                                                username : false,
                                                password : false,
                                            },
                                            usuario:{
                                                username : "",
                                                password: "",
                                            },
                                            usuarioStatus : 0,
                                        });
                                        this.fucusElement("IdIptUsername");
                                        
                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    style={this.state.style.button}
                                    onClick={() => {
                                        this.iniciarSesion();

                                    }}
                                >
                                    {
                                    this.state.isMobileDevice?
                                        "Iniciar"                                                
                                        :
                                        "Iniciar sesión"
                                }
                                    
                                    {/* Iniciar sesión */}
                                </Button>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                marginTop: "30px",
                                marginBottom: "30px"
                            }}>
                            <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.azul_1,
                                    }}
                                >
                                    ¿Eres administrador?
                                    <a
                                        href="/login-administrador"
                                        style={{
                                            marginLeft: "7px",
                                            //fontWeight: "normal",
                                            color: colors.azul_1,
                                        }}
                                    >ir al login del panel de administrador</a>
                                </Label>
                            </Col>
                        </Row>

                        <Row><Col></Col></Row>


                    </div>
                </div>
            </>
        );
    }

}

export default LoginTeamp;