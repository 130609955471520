import { Component } from "react";
import colors from "../../Configuration/Colors.config"
import NavBar from "../../Components/NavBar";
import swal2 from 'sweetalert2';
import laminaModel from "../../Model/Lamina.model";
import imgNoData from "../../Multimedia/imgNoData.svg";
// import laminaModel from "../../Model/Lamina.model";
import IconButton from "@material-ui/core/IconButton";
import {
    Row,
    Col,
    Button,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup
} from "reactstrap";
import ReactPlayer from 'react-player/lazy';
import TableData from 'react-data-table-component'
import {
    ArrowBackIos,
    ArrowForwardIos,
    Home,
    Cached,
    PlayArrow,
} from "@material-ui/icons";
import Cookies from 'universal-cookie';
import gifCarga from "../../Multimedia/gifCarga.gif"
let isFullScreen = false;
const cookies = new Cookies();

let t1 = false;
let imgUltima

let cont = 0;


class CursoBodyPrivate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imgUltima : false,
            urlVideo: "",
            isVideo: false,
            videoList: [
            ],
            columsVideo: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre del video</p>,
                    selector: row =>
                        <Label
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            {row.nombreVideo}
                        </Label>,
                    sortable: true
                },
                // {
                //     name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>URL</p>,
                //     selector: row =>
                //         <abbr
                //             title={row.url}
                //             style={{
                //                 textDecoration: 'none'
                //             }}
                //         >
                //             <button
                //                 style={{
                //                     backgroundColor: "#fff",
                //                     border: 'none'
                //                 }}
                //                 onClick={() => {
                //                     window.open(row.url);
                //                 }}
                //             >
                //                 <YouTube
                //                     style={{
                //                         color: 'red'
                //                     }}
                //                 />
                //                 <Label
                //                     style={{
                //                         marginLeft: "3px",
                //                         marginTop: "2px",
                //                         fontWeight: "bold"
                //                     }}
                //                 >
                //                     ver video en youtube
                //                 </Label>
                //             </button>
                //         </abbr>,
                //     sortable: true
                // },
                //https://img.youtube.com/vi/UokZMBmnUGM/maxresdefault.jpg
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Miniatura</p>,
                    selector: row =>
                        <img
                            src={`https://img.youtube.com/vi/${row.code}/maxresdefault.jpg`}
                            height={50}
                        />
                    ,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones</p>,
                    selector: row =>
                        <>

                            {/* <IconButton
                                style={{
                                    color: colors.azul_1
                                }}
                                onClick={() => {
                                    this.setState({
                                        dataVideo: row,
                                        openModalDataVideo: true
                                    });
                                }}
                            >
                                <Edit />
                            </IconButton> */}
                            <IconButton
                                style={{
                                    color: colors.azul_1
                                }}
                                onClick={async () => {
                                    await this.setState({
                                        dataVideo: row,
                                        isOpenVideoPlay: true
                                    });
                                }}
                            >
                                <PlayArrow />
                            </IconButton>
                        </>,
                    sortable: true
                },
            ],
            openModalDataVideo: false,
            openModalVideo: false,
            blurImg: "0px",
            isMobileDevice: false,
            isOpenVideoPlay: false,
            dataVideo: {
                IdVideo: 0,
                IdCurso: 0,
                nombreVideo: "",
                url: "",
                code: ""
            },
            curso: {
                IdCurso: "",
                img: null,
                titulo: "",
                cursoNombre: "",
                subtitulo: "",
                textoInformativo: ""

            },
            lamina: {
                IdCurso: "",
                img: imgNoData,
                IdLamina: "",
                nombre: "",
                posicion: ""
            },
            usuario: {
                IdUsuario: "",
                IdStatus: "",
                status: "",
                nombre: "",
                paterno: "",
                materno: "",
                telefono: "",
                correo: "",
                username: "",
                IdUsuarioTipo: ""
            }
        }



    }


    // componentWillUnmount() {

    //     let details = navigator.userAgent;
    //     let regexp = /android|iphone|kindle|ipad/i;
    //     let isMobileDevice = regexp.test(details);

    //     let IdCurso = cookies.get("IdCurso", { path: "/" });
    //
    //     if (IdCurso == null) {
    //         swal2.fire({
    //             icon: 'error',
    //             title: 'Error al cargar el curso',
    //             text: "No se pudo mostrar el curso, intentelo de nuevo",
    //             timer: "15000",
    //             confirmButtonColor: '#016390',
    //             confirmButtonText: 'Okey',
    //             allowEscapeKey: true,
    //             timerProgressBar: true,
    //         }).then(() => {
    //             window.location.href = "/";
    //         });
    //     }

    //     this.cargarDataPage(IdCurso);

    //     this.setState({
    //         isMobileDevice: isMobileDevice,

    //     });

    //     document.addEventListener('keydown', (event) => {
    //         var key = event.key;
    //         var value = event.code;

    //         if (key === "ArrowRight" && value === "ArrowRight") {
    //             this.siguienteLamina();
    //         } else if (key === "ArrowLeft" && value === "ArrowLeft") {
    //             this.anteriorLamina();
    //         } else if (value === "Space") {
    //             this.cargarDataPage(this.state.lamina.IdCurso);
    //         }

    //     }, false);

    // }

    cargarDataPage = async (IdCurso) => {

        if (IdCurso == null) {
            swal2.fire({
                icon: 'warning',
                title: 'Presentación vacía',
                text: "Este curso no tiene diapositivas para mostrar todavía, espere a que los administradores carguen las diapositivas",
                //timer: "15000",
                confirmButtonColor: '#016390',
                confirmButtonText: 'Okey',
                allowEscapeKey: true,
                timerProgressBar: true
            });
            await this.setState({
                lamina: {
                    IdCurso: "",
                    img: imgNoData,
                    IdLamina: "",
                    nombre: "",
                    posicion: ""
                }
            })

            return;
        }

        await this.setState({
            //blurImg: "8px",
            lamina: {
                ...this.state.lamina,
                img: gifCarga,
                ref: "-"
            }
        });

        laminaModel.lamina_obtener_primera(IdCurso, async (data) => {
            cont = 0;
            if (data.lamina.length === 0) {
                swal2.fire({
                    icon: 'warning',
                    title: 'Presentación vacía',
                    text: "Este curso no tiene diapositivas para mostrar todavía, espere a que los administradores carguen las diapositivas",
                    //timer: "15000",
                    confirmButtonColor: '#016390',
                    confirmButtonText: 'Okey',
                    allowEscapeKey: true,
                    timerProgressBar: true
                });
                await this.setState({
                    lamina: {
                        IdCurso: "",
                        img: imgNoData,
                        IdLamina: "",
                        nombre: "",
                        posicion: ""
                    }
                })
            } else {
                if (data.lamina[0].urlVideoAsociado.length > 0) {
                    await this.setState({
                        isVideo: true,
                        urlVideo: data.lamina[0].urlVideoAsociado,
                        lamina: {
                            IdCurso: data.lamina[0].IdCurso,
                            img: data.lamina[0].img,
                            IdLamina: data.lamina[0].IdLamina,
                            nombre: data.lamina[0].nombre,
                            posicion: data.lamina[0].posicion
                        },
                    });
                } else {
                    await this.setState({
                        isVideo: false,
                        urlVideo: "",
                        lamina: {
                            IdCurso: data.lamina[0].IdCurso,
                            img: data.lamina[0].img,
                            IdLamina: data.lamina[0].IdLamina,
                            nombre: data.lamina[0].nombre,
                            posicion: data.lamina[0].posicion
                        },

                    });
                }
                // this.setState({
                //     lamina: {
                //         IdCurso: data.lamina[0].IdCurso,
                //         img: data.lamina[0].img,
                //         IdLamina: data.lamina[0].IdLamina,
                //         nombre: data.lamina[0].nombre,
                //         posicion: data.lamina[0].posicion
                //     }
                // });
            }
        });
    }




    async componentDidMount() {

        document.body.style.backgroundColor = colors.azul_1;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);



        let usuario = await cookies.get('usuario', { path: "/" })
        //console.log(usuario);
        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',

                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {
                window.location.href = '/login-administrador'
            });


        } else {

            let IdCurso = await cookies.get("IdCurso", { path: "/" });
            if (IdCurso == null) {
                swal2.fire({
                    icon: 'error',
                    title: 'Error al cargar el curso',
                    text: "No se pudo mostrar el curso, intentelo de nuevo",
                    timer: "15000",
                    confirmButtonColor: '#016390',
                    confirmButtonText: 'Okey',
                    allowEscapeKey: true,
                    timerProgressBar: true
                }).then(() => {
                    window.location.href = "/";
                });
            }
            // await laminaModel.video_cursos_list(IdCurso, async (data) => {
            //     let videoList = data.videos
            //     //console.log(videoList);
            //     await this.setState({
            //         videoList
            //     });
            // });
            await this.setState({
                isMobileDevice: isMobileDevice,
                usuario: usuario,

            });

            this.cargarDataPage(IdCurso);


        }

        document.addEventListener('keydown', async (event) => {

            var key = event.key;
            var value = event.code;

            console.log("Comando");
            console.log(key, value);

            if (await (key === "ArrowRight" && value === "ArrowRight")) {
                await console.log("Derecha");
                await this.siguienteLamina();
            } else if (await (key === "ArrowLeft" && value === "ArrowLeft")) {
                await console.log("Izquierda");
                await this.anteriorLamina();
            } if (await ((key == "k" || key == "K") || (value == "k" || value == "K"))) {
                console.log(key, value);
                let element = await document.getElementById("imgLamina");
                if (!isFullScreen) {
                    await this.getFullscreen(element);
                    isFullScreen = await true;
                } else {
                    await document.exitFullscreen();
                    isFullScreen = await false;
                }

            } else if (await (value === "Space")) {
                this.cargarDataPage(this.state.lamina.IdCurso);
            }

        }, false);

    }

    sleep = (seconds) => new Promise(
        resolve => setTimeout(resolve, (seconds * 1000))
    )

    openCloseModal = async () => {
        await this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    getFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else {

        }
    }
    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 7000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });


    siguienteLamina = async () => {
        cont++;
        // t1 = false;
        if (this.state.isVideo) {
            await this.setState({
                isVideo: false,
            });
            return;
        }

        if (this.state.lamina.IdCurso === "") {
            swal2.fire({
                icon: 'warning',
                title: 'Presentación vacía',
                text: "Este curso no tiene diapositivas para mostrar todavía, espere a que los administradores carguen las diapositivas",
                //timer: "15000",
                confirmButtonColor: '#016390',
                confirmButtonText: 'Okey',
                allowEscapeKey: true,
                timerProgressBar: true
            });

            await this.setState({
                lamina: {
                    IdCurso: "",
                    img: imgNoData,
                    IdLamina: "",
                    nombre: "",
                    posicion: ""
                },
            })
            return;
        }

        await this.setState({
            //blurImg: "8px",
            lamina: {
                ...this.state.lamina,
                img: gifCarga,
                ref: "-"
            },
        });
        await laminaModel.lamina_siguiente(this.state.lamina, this.state.usuario.IdUsuario, async (data) => {
            if (data.lamina.length === 0) {
                this.Toast.fire({
                    icon: "info",
                    title: "Ultima diapositiva",
                });
                console.log(imgUltima)
                await this.setState({
                    ...this.state.lamina,
                    img : imgUltima + "",
                    ref: "",
                    IdLamina: -1
                });
                return;
            }

            if (data.lamina[0].urlVideoAsociado.length > 0) {
                await this.setState({
                    isVideo: true,
                    urlVideo: data.lamina[0].urlVideoAsociado,
                    lamina: {
                        IdCurso: data.lamina[0].IdCurso,
                        img: data.lamina[0].img,
                        IdLamina: data.lamina[0].IdLamina,
                        nombre: data.lamina[0].nombre,
                        posicion: data.lamina[0].posicion
                    },
                });
            } else {
                imgUltima = data.lamina[0].img;
                await this.setState({
                    
                    lamina: {
                        IdCurso: data.lamina[0].IdCurso,
                        img: data.lamina[0].img,
                        IdLamina: data.lamina[0].IdLamina,
                        nombre: data.lamina[0].nombre,
                        posicion: data.lamina[0].posicion
                    },

                });
            }
        });


    }

    anteriorLamina = async () => {

        let { IdCurso } = await this.state.lamina;
        if (cont === 0) {
            console.log(`cont = ${cont}`);
            await this.cargarDataPage(IdCurso);
            await this.Toast.fire({
                icon: "info",
                title: "Primera diapositiva"
            });
            return;
        }

        cont--;

        if (t1) {
            await this.setState({
                isVideo: true,
            });
            t1 = false;
            return;
        }

        if (this.state.lamina.IdCurso === "") {
            swal2.fire({
                icon: 'warning',
                title: 'Presentación vacía',
                text: "Este curso no tiene diapositivas para mostrar todavía, espere a que los administradores carguen las diapositivas",
                //timer: "15000",
                confirmButtonColor: '#016390',
                confirmButtonText: 'Okey',
                allowEscapeKey: true,
                timerProgressBar: true
            });
            await this.setState({
                lamina: {
                    IdCurso: "",
                    img: imgNoData,
                    IdLamina: "",
                    nombre: "",
                    posicion: ""
                }
            })
            return;
        }
        await this.setState({
            //blurImg: "8px",
            lamina: {
                ...this.state.lamina,
                img: gifCarga,
                ref: "-"
            }
        });

        await laminaModel.lamina_anterior(this.state.lamina, async (data) => {

            if (data.lamina.length === 0) {
                // alert();
                this.Toast.fire({
                    icon: "info",
                    title: "Primera diapositiva"
                });
                await this.setState({
                    isVideo: false,
                });
                this.cargarDataPage(IdCurso);
                return;
            }

            if (data.lamina[0].urlVideoAsociado.length > 0) {
                console.log("Tiene video");
                t1 = true;
                await this.setState({
                    isVideo: false,
                    urlVideo: data.lamina[0].urlVideoAsociado,
                    lamina: {
                        IdCurso: data.lamina[0].IdCurso,
                        img: data.lamina[0].img,
                        IdLamina: data.lamina[0].IdLamina,
                        nombre: data.lamina[0].nombre,
                        posicion: data.lamina[0].posicion
                    },
                });
            } else {
                console.log("No tiene video");
                await this.setState({
                    urlVideo: "",
                    isVideo: false,
                    lamina: {
                        IdCurso: data.lamina[0].IdCurso,
                        img: data.lamina[0].img,
                        IdLamina: data.lamina[0].IdLamina,
                        nombre: data.lamina[0].nombre,
                        posicion: data.lamina[0].posicion
                    },

                });
            }



        });
    }







    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} usuario={this.state.usuario} />
                {!this.state.isMobileDevice ?
                    <div
                        style={{
                            marginTop: "80px"
                        }}
                    >

                        <div
                            style={{
                                textAlign: "center",
                                marginTop: "10px"
                            }}

                        >

                            <Row>
                                <abbr
                                    title="Poner imagen en pantalla completa"
                                >
                                    {this.state.isVideo ?
                                        <div
                                            style={{
                                                textAlign: "center",
                                                marginLeft: "310px",
                                                marginTop: "40px"
                                            }}
                                        >
                                            <ReactPlayer
                                                url={`${this.state.urlVideo}`}
                                                className='react-player'
                                                onStart={() => {
                                                    console.log("Le dieron play al video Play");
                                                }}
                                                onEnded={() => {
                                                    console.log("Le termino el video");
                                                }}
                                                volume={0.05}
                                                // light={false}
                                                playing                                                
                                                // width='100%'
                                                // height='300px'
                                                controls
                                                height={"65vh"}
                                                width={"124vh"}
                                                playing={false}
                                            //muted
                                            />
                                        </div>
                                        :
                                        <img
                                            alt=""
                                            onDoubleClick={() => {
                                                if (!isFullScreen) {
                                                    isFullScreen = true;
                                                    let element = document.getElementById("imgLamina");
                                                    this.getFullscreen(element);
                                                } else {
                                                    document.exitFullscreen()
                                                    isFullScreen = false;
                                                }

                                            }}
                                            onClick={async (e) => {
                                                if (isFullScreen) {
                                                    if (e.clientX > 100) {

                                                        this.siguienteLamina();
                                                    } else {
                                                        this.anteriorLamina();
                                                    }
                                                }
                                            }}
                                            style={{
                                                height: this.state.lamina.IdCurso === "" ? "50vh" : "70vh",
                                                cursor: "pointer",
                                                marginTop: this.state.lamina.IdCurso === "" ? "70px" : "",
                                                filter: `blur(${this.state.blurImg})`,
                                            }}
                                            id="imgLamina"
                                            src={this.state.lamina.IdCurso === "" || this.state.lamina.ref === "-" ? this.state.lamina.img : "data:image/*;base64," + this.state.lamina.img}
                                        />
                                    }

                                </abbr>
                            </Row>
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                marginTop: "20px"
                            }}
                        >
                            <Button
                                style={{
                                    backgroundColor: colors.rojo,
                                    width: "200px"
                                }}
                                onClick={async () => {

                                    // alert();
                                    this.anteriorLamina();
                                }}
                            >
                                <ArrowBackIos />
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: colors.rojo,
                                    width: "200px",
                                    marginRight: "23vh",
                                    marginLeft: "23vh"
                                }}
                                onClick={async () => {
                                    this.cargarDataPage(this.state.lamina.IdCurso);
                                }}
                            >
                                <Home />
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: colors.rojo,
                                    width: "200px"

                                }}
                                onClick={async () => {

                                    this.siguienteLamina();
                                }}
                            >
                                <ArrowForwardIos />
                            </Button>
                        </div>
                        <div
                            style={{
                                textAlign: "center",
                                marginTop: "10px"
                            }}
                        >
                            {/* <Button
                                style={{
                                    width: "950px",
                                    backgroundColor: "#163776",
                                }}
                                onClick={() => {
                                    this.setState({
                                        openModalVideo: true,
                                    })
                                }}
                            >
                                Ver videos
                            </Button> */}
                        </div>
                    </div>
                    :
                    <div>

                        <div
                            style={{
                                marginTop: "10px",
                                textAlign: "center"
                            }}
                        >
                            {
                                this.state.isVideo ?
                                    <div
                                        style={{
                                            textAlign: "center",
                                            // marginLeft: "310px",
                                            marginTop: "40px"
                                        }}
                                    >
                                        <ReactPlayer
                                            className='react-player'
                                            onStart={() => {
                                                console.log("Le dieron play al video Play");
                                            }}
                                            onEnded={() => {
                                                console.log("Le termino el video");
                                            }}
                                            volume={0.05}
                                            url={`${this.state.urlVideo}`}
                                            // className='react-player'
                                            style={{
                                                marginLeft: "20px"
                                            }}
                                            // playing                                                
                                            // width='100%'
                                            // height='300px'
                                            controls
                                            width="90%"
                                            height="175px"
                                            playing={true}
                                        //muted
                                        />
                                    </div>
                                    :

                                    <img
                                        alt=""
                                        //src={"data:image/*;base64," + this.state.lamina.img}
                                        src={this.state.lamina.IdCurso === "" || this.state.lamina.ref === "-" ? this.state.lamina.img : "data:image/*;base64," + this.state.lamina.img}
                                        id="imgLamina"
                                        onDoubleClick={() => {

                                            if (!isFullScreen) {
                                                isFullScreen = true;
                                                let element = document.getElementById("imgLamina");
                                                this.getFullscreen(element);
                                            } else {
                                                document.exitFullscreen()
                                                isFullScreen = false;
                                            }

                                        }}
                                        style={{
                                            width: "90%",
                                            height: this.state.lamina.IdCurso === "" ? "10px" : "80%",
                                            marginTop: this.state.lamina.IdCurso === "" ? "70px" : "",
                                        }}
                                        onClick={async (e) => {
                                            if (isFullScreen) {
                                                if (e.clientX > 100) {

                                                    this.siguienteLamina();
                                                } else {
                                                    this.anteriorLamina();

                                                }
                                            }
                                        }}
                                    />
                            }
                        </div>
                        <Row
                            style={{
                                marginTop: "10px"
                            }}
                        >
                            <Col>
                                <p align="right">
                                    <Button
                                        style={{
                                            backgroundColor: colors.rojo_2
                                        }}
                                        onClick={async () => {

                                            this.anteriorLamina();
                                        }}
                                    >
                                        <ArrowBackIos />
                                    </Button>
                                </p>
                            </Col>
                            <Col
                                style={{
                                    width: "100px"
                                }}
                            >
                                <p align="center">
                                    <Button
                                        style={{
                                            backgroundColor: colors.rojo_2
                                        }}
                                        onClick={() => {

                                            this.cargarDataPage(this.state.lamina.IdCurso);
                                        }}
                                    >
                                        <Home />
                                    </Button>
                                </p>
                            </Col>
                            <Col>
                                <p align="left">
                                    <Button
                                        style={{
                                            backgroundColor: colors.rojo_2
                                        }}
                                        onClick={async () => {

                                            this.siguienteLamina();
                                        }}
                                    >
                                        <ArrowForwardIos />
                                    </Button>
                                </p>
                            </Col>
                        </Row>
                        {/* <div
                            style={{
                                textAlign: "center",
                                marginTop: "10px",
                                //width:"80%"
                            }}
                        >
                            <Button
                                style={{
                                    width: "950px",
                                    backgroundColor: "#163776",
                                    width: "275px"
                                }}
                                onClick={() => {
                                    this.setState({
                                        openModalVideo: true,
                                    })
                                }}
                            >
                                Ver videos
                            </Button>
                        </div> */}
                    </div>

                }
                <Modal
                    isOpen={this.state.openModalVideo}
                    size="xl"
                >

                    <ModalHeader>
                        Videos cargados a este curso
                    </ModalHeader>

                    <ModalBody>
                        <div
                            style={{
                                textAlign: "right",
                            }}
                        >
                            {/* <Button
                                style={{
                                    marginRight: "5px",
                                    backgroundColor: colors.azul_1
                                }}
                                onClick={() => {
                                    this.setState({
                                        dataVideo: {
                                            IdVideo: 0,
                                            IdCurso: this.state.curso.IdCurso,
                                            nombreVideo: "",
                                            url: "",
                                            code: "",
                                        },
                                        openModalDataVideo: true
                                    });
                                }}
                            >
                                <Add />
                            </Button> */}
                            <Button
                                style={{
                                    marginRight: "5px",
                                    backgroundColor: colors.azul_1
                                }}
                                onClick={() => {
                                    laminaModel.video_cursos_list(this.state.curso.IdCurso, async (data) => {
                                        let videoList = data.videos
                                        // console.log(videoList);
                                        await this.setState({
                                            videoList
                                        });
                                        this.Toast.fire({
                                            icon: "info",
                                            text: "Tabla actualizada"
                                        });
                                    });
                                }}
                            >
                                <Cached />
                            </Button>
                        </div>
                        <Row
                            style={{
                                marginTop: "5px"
                            }}
                        >
                            <TableData
                                columns={this.state.columsVideo}
                                data={this.state.videoList}
                                clearSelectedRows
                                pagination
                                dense
                                noDataComponent={<p style={{ fontWeight: "bold" }}>No hay videos cargados</p>}
                            />
                        </Row>

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul_1
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalVideo: false,
                                })
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>

                <Modal
                    isOpen={this.state.openModalDataVideo}
                    size="md"
                    onOpened={() => {
                        this.focusElement("IdNpmbreVideo");
                    }}
                >

                    <ModalHeader>
                        Video
                    </ModalHeader>

                    <ModalBody>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label >
                                        Nombre del video
                                    </Label>
                                    <Input
                                        id="IdNpmbreVideo"
                                        name="nombreVideo"
                                        placeholder="Nombre del video"
                                        type="text"
                                        value={this.state.dataVideo.nombreVideo}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                this.focusElement("IdUrlVideo");
                                            }
                                        }}
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            this.setState({
                                                dataVideo: {
                                                    ...this.state.dataVideo,
                                                    nombreVideo: value
                                                }
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label >
                                        URL
                                    </Label>
                                    <Input
                                        id="IdUrlVideo"
                                        name="url"
                                        placeholder="URL de YouTube del video"
                                        type="text"
                                        value={this.state.dataVideo.url}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                this.focusElement("btnGuartarVideo");
                                            }
                                        }}
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            let url = value;
                                            url = url.split("");
                                            url = url.reverse();
                                            url = url.join("");
                                            let code = "";
                                            //console.log(url);

                                            for (let i = 0; i < url.length; i++) {
                                                //console.log(url[i]);
                                                if (url[i] === "=" && url[i + 1] === "v" && url[i + 2] === "?") break
                                                code = code + url[i];
                                            }

                                            code = code.split("");
                                            code = code.reverse();
                                            code = code.join("");

                                            let temp = code;
                                            code = "";
                                            //console.log(code);

                                            for (let i = 0; i < temp.length; i++) {
                                                //console.log(temp[i]);
                                                if (temp[i] === "&") break
                                                code = code + temp[i];
                                            }

                                            //console.log(code);

                                            // this.setState({
                                            //     ...this.state.dataVideo,
                                            //     code,
                                            // });


                                            this.setState({
                                                dataVideo: {
                                                    ...this.state.dataVideo,
                                                    url: value,
                                                    code
                                                }
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row

                        >
                            <Col
                                style={{
                                    textAlign: "right",
                                }}
                            >
                                <Button
                                    style={{
                                        width: "30%",
                                        marginRight: "10px",
                                        backgroundColor: "red"
                                    }}
                                    onClick={() => {

                                        swal2.fire({
                                            icon: 'question',
                                            title: '¿Eliminar video?',
                                            text: `¿Estas seguro de que quieres eliminar este video?`,
                                            timer: "10000",
                                            confirmButtonColor: '#016390',
                                            confirmButtonText: 'Si',
                                            showCancelButton: true,
                                            cancelButtonText: "No",
                                            allowEscapeKey: true,
                                            timerProgressBar: true,
                                            reverseButtons: true,
                                            footer: `<p style="color:${colors.azul_1}; font-weight: bold;">No se podra recuperar el video una ves borrado</p>`
                                        }).then((value) => {
                                            //console.log(value.isConfirmed)
                                            if (value.isConfirmed) {
                                                laminaModel.video_curso_eliminar(this.state.dataVideo.IdVideo, (data) => {
                                                    //console.log(data);
                                                    laminaModel.video_cursos_list(this.state.curso.IdCurso, (data) => {
                                                        let videoList = data.videos;
                                                        this.setState({
                                                            videoList
                                                        });
                                                    });
                                                });
                                            }
                                        });


                                    }}
                                >
                                    Eliminar
                                </Button>
                                <Button
                                    id="btnGuartarVideo"
                                    style={{
                                        width: "30%",
                                        backgroundColor: colors.azul_1
                                    }}
                                    onClick={() => {

                                        if (this.state.dataVideo.nombreVideo.length === 0 || this.state.dataVideo.url.length === 0) {

                                            this.Toast.fire({
                                                icon: "warning",
                                                text: "Falta el nombre o la URL del video"
                                            });
                                        } else {

                                            laminaModel.video_cursos_guardar(this.state.dataVideo, (data) => {
                                                //console.log(data);
                                                laminaModel.video_cursos_list(this.state.curso.IdCurso, (data) => {
                                                    let videoList = data.videos;
                                                    this.setState({
                                                        videoList
                                                    });
                                                    this.Toast.fire({
                                                        icon: "success",
                                                        text: "Se ha guardado el video"
                                                    });
                                                });

                                            });
                                        }

                                    }}
                                >
                                    Guardar
                                </Button>
                            </Col>


                        </Row>

                    </ModalBody>

                    <ModalFooter>
                        {/* <Button
                            style={{
                                backgroundColor: colors.azul_1,
                                width: "30%",
                                //width: "29%",
                                //marginRight: "1px",
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalDataVideo: false,
                                    openModalVideo: false,
                                })
                            }}
                        >
                            Cerrar Todo
                        </Button> */}
                        <Button
                            style={{
                                backgroundColor: colors.azul_1,
                                width: "30%",
                                //width: "29%",                                
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalDataVideo: false,
                                })
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>
                <Modal
                    isOpen={this.state.isOpenVideoPlay}
                    size="lg"
                >
                    <ModalHeader>
                        {this.state.dataVideo.nombreVideo}
                    </ModalHeader>

                    <ModalBody
                    // style={{
                    //     height: '450px'
                    // }}
                    >
                        <div
                            style={{
                                height: 300
                            }}
                        >
                            {/* <Row>
                                <Col>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                             fontSize: "28px"
                                        }}
                                    >
                                        {this.state.dataVideo.nombreVideo}
                                    </Label>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    <ReactPlayer
                                        url={`${this.state.dataVideo.url}`}
                                        className='react-player'
                                        playing
                                        width='100%'
                                        height='300px'
                                        controls
                                    //playing={false}
                                    //muted
                                    />
                                </Col>
                            </Row>

                        </div>

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul_1,
                                width: "30%",
                                //width: "29%",                                
                            }}
                            onClick={() => {

                                this.setState({
                                    isOpenVideoPlay: false,
                                    dataVideo: {
                                        IdVideo: "",
                                        nombreVideo: "",
                                        url: "",
                                        code: ""
                                    }
                                })
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>
            </>
        );
    }

}

export default CursoBodyPrivate;