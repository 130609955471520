import axios from 'axios';
import API from '../Network/Api.js';
import swal2 from 'sweetalert2';
import md5 from 'md5';


import Cookies from 'universal-cookie';
const cookies = new Cookies();


const mensajeError = (error) => {
    swal2.fire({
        icon: 'error',
        title: 'Error',
        text: "Error de Red",
        //timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true
    }).then(() => {
        //window.location.href="/";
    });
}


const usuario_formato = {
    IdUsuario: "",
    IdStatus: "",
    status: "",
    nombre: "",
    paterno: "",
    materno: "",
    telefono: "",
    correo: "",
    username: ""
}


const usuario_login = (usuario, callback) => {
    usuario = {
        ...usuario,
        password: md5(usuario.password)
    }


    axios({
        method: "POST",
        url: API + "/usuario/login",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            usuario
        }
    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const usuarios_list = (usuario, callback) => {
   
    axios({
        method: "GET",
        url: API + "/usuario/usuarios_list/" + usuario.IdUsuario,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {    
        mensajeError(error);
    });
}

const buscar_usuario = (usuario, callback) => {
 
    axios({
        method: "GET",
        url: API + "/usuario/buscar_usuario/" + usuario.IdUsuario + "/" + usuario.referencia,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {    
        mensajeError(error);
    });
}

const usuario_crear = (usuario, callback) => {
    usuario = {
        ...usuario,
        password: md5(usuario.password),
        IdUsuarioCreador : cookies.get("usuario",{path:"/"}).IdUsuario
    }
     axios({
         method: "POST",
         url: API + "/usuario/usuario_crear",
         headers: {
             //token : cookies.get('token')
             "Access-Control-Allow-Origin": "*",
            //  token: cookies.get("usuario", {
            //      path: "/"
            //  }).token,
         },
         data:{
            usuario
         }
     }).then(response => {
         let data = response.data;
 
         callback(data);
 
     }).catch((error) => {    
         mensajeError(error);
     });
 }

 const usuario_actualizar = (usuario, callback) => {

    usuario = {
        ...usuario,
        password: usuario.password === "" ? "" : md5(usuario.password),
        IdUsuarioMayor : cookies.get("usuario",{path:"/"}).IdUsuario
    }
  
     axios({
         method: "PUT",
         url: API + "/usuario/usuario_actualizar",
         headers: {
             //token : cookies.get('token')
             "Access-Control-Allow-Origin": "*",
            //  token: cookies.get("usuario", {
            //      path: "/"
            //  }).token,
         },
         data:{
            usuario 
         }
     }).then(response => {
         let data = response.data;
 
         callback(data);
 
     }).catch((error) => {    
         mensajeError(error);
     });
 }


 const usuario_elimiar_porId = (IdUsuario, callback) => {

     axios({
         method: "DELETE",
         url: API + "/usuario/usuario_elimiar_porId/"+IdUsuario,
         headers: {
             //token : cookies.get('token')
             "Access-Control-Allow-Origin": "*",
            //  token: cookies.get("usuario", {
            //      path: "/"
            //  }).token,
         },
         data : { 
            IdUsuarioMayor : cookies.get("usuario",{path:"/"}).IdUsuario
         }       
     }).then(response => {
         let data = response.data;
 
         callback(data);
 
     }).catch((error) => {    
         mensajeError(error);
     });
 }



const usuarioModel = {
    usuario_login,
    usuarios_list,
    usuario_formato,
    buscar_usuario,
    usuario_crear,
    usuario_actualizar,
    usuario_elimiar_porId
}

export default usuarioModel;