import { Component } from "react";
import NavBar from "../../Components/NavBar";
import colors from "../../Configuration/Colors.config";
import Cookies from "universal-cookie";
import userModel from "../../Model/Usuario.model";
//import browserModel from "../Model/Browser.model.js";
//import Table from "react-data-table-component";
//import phoneNumber from "../../Model/Phone.model.js";
import IconButton from "@material-ui/core/IconButton";
import {
    Visibility,
    VisibilityOff,
    Cached,
    Save,
    Delete,
    TrendingUp,
    MoreHoriz,
    Sort,
} from "@material-ui/icons/";
import {
    Row,
    Col,
    Label,
    Input,
    FormGroup,
    InputGroup,
    InputGroupText,
    FormFeedback,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
} from "reactstrap"

import swal2 from 'sweetalert2'



const cookies = new Cookies();


class UsuarioView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: true,
            colorStatus: {
                1: "#57AF57",
                2: "#A1A1A1",
                //3: "#C20000",
            },
            colorGenero: {
                1: "#A1A1A1",
                2: "#00BAEF",
                3: "#E32977",
            },
            isOpenModal: false,
            isOpenModalButton: false,
            dataUsuario: {
                IdUsuario: "",
                IdStatus: 1,
                IdGenero: 1,
                nombre: "",
                paterno: "",
                materno: "",
                telefono: "",
                correo: "",
                username: "",
                password: "",
                IdUsuarioTipo: 2
            },
            password: {
                value_1: "",
                value_2: ""
            },
            invalid: {
                username: false,
                password: false
            },
            isVisibilityPassword: false,
            colums: [
                // {
                //     name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Id Usuario</p>,
                //     selector: row => row.IdUsuario,
                //     sortable: true
                // },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.nombre + " " + row.paterno + " " + row.materno} >{row.nombre + " " + row.paterno + " " + row.materno}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre de Usuario</p>,
                    selector: row => row.username,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Telefono</p>,
                    selector: row =>
                        <button
                            style={{
                                border: "none",
                                backgroundColor: "#FFFFFF"
                            }}
                            onClick={async () => {
                                await this.setState({
                                    dataUsuario: row
                                })
                                this.openCloseModal();
                            }}
                        >
                            {row.telefono}
                        </button>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Correo</p>,
                    selector: row => <abbr title={`${row.correo}`} style={{ textDecoration: "none" }}>{row.correo}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Estatus</p>,
                    selector: row => <p style={{ fontWeight: "bold", color: this.state.colorStatus[row.IdStatus], marginTop: "15px" }}>{row.status}</p>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Genero</p>,
                    selector: row => <p style={{ fontWeight: "bold", color: this.state.colorGenero[row.IdGenero], marginTop: "15px" }}>{row.genero}</p>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Acciones</p>,
                    selector: row =>
                        <>
                            <abbr
                                style={{ textDecoration: "none" }}
                                title="Ver al usuario"
                            >
                                <IconButton
                                    style={{
                                        color: colors.azul_1
                                    }}
                                    onClick={() => {
                                        //cookies.set("IdUsuariosVire", row.IdUsuarios, { path: "/" });
                                        //window.location.href = "/users-view";
                                    }}
                                >
                                    <Visibility />
                                </IconButton>
                            </abbr>
                        </>,
                    sortable: true
                },
            ],
        }
    }

    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });

    focusElement = (IdElemento) => {
        document.getElementById(IdElemento).focus();
    }

    nuevoUsuario = async () => {
        await this.setState({
            dataUsuario: {
                IdUsuario: "",
                IdStatus: 1,
                IdGenero: 1,
                nombre: "",
                paterno: "",
                materno: "",
                telefono: "",
                correo: "",
                username: "",
                password: "",
                IdUsuarioTipo: 2
            },
            invalid: {
                username: false,
                password: false
            }
        });
        await this.focusElement("idNombreUsuario");
    }

    handleChangeDataUsuario = async (e) => {
        let { name, value } = e.target;
        await this.setState({
            dataUsuario: {
                ...this.state.dataUsuario,
                [name]: value
            },
            invalid: {
                ...this.state.invalid,
                [name]: value.length === 0 ? true : false
            }
        });
    }

    async componentDidMount() {

        document.body.style.backgroundColor = colors.azul_1;
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });

        this.cargarDatosPagina();

    }


    cargarDatosPagina = async () => {
        let usuario = cookies.get('usuario', { path: "/" })

        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',

                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {
                window.location.href = '/login-administrador'
            });

        } else {
            let dataUsuario = cookies.get('dataUsuario', { path: "/" })

            if (dataUsuario != null) {
                await this.setState({
                    dataUsuario: {
                        ...dataUsuario,
                        password: ""
                    }
                });
                // cookies.remove("dataUsuario", { path: "/" });
            }
        }
        await this.setState({
            usuario
        });

    }

    eliminarUsuario = async () => {

        await swal2.fire({
            title: 'Eliminar Usuario',
            text: "Estas seguro de que quieres eliminar este usuario",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: colors.azul_1,
            timer: "15000",
            reverseButtons: true,
            timerProgressBar: true,
            confirmButtonText: 'Si, eliminar',
            footer: '<p style="color:#E60026;">No se podra recuperar al usuario una ves eliminado</p>',
        }).then((result) => {
            if (result.isConfirmed) {
                userModel.usuario_elimiar_porId(this.state.dataUsuario.IdUsuario, (data) => {
                    let { status } = data;
                    if (status === 200) {
                        this.Toast.fire({
                            icon: "success",
                            title: "Usuario eliminado"
                        });
                        this.nuevoUsuario();
                    }
                });
            }
        });


    }

    validarInputus = async (callback) => {

        let value = true;

        if (this.state.dataUsuario.IdUsuario === "") {
            if (this.state.dataUsuario.username === "" && this.state.dataUsuario.password === "") {
                value = false;
                await this.setState({
                    invalid: {
                        username: true,
                        password: true,
                    }
                })

            } else {
                if (this.state.dataUsuario.username === "") {
                    value = false;
                    await this.setState({
                        invalid: {
                            username: true,
                            password: false,
                        }
                    })

                }
                if (this.state.dataUsuario.password === "") {
                    value = false;
                    await this.setState({
                        invalid: {
                            username: false,
                            password: true,
                        }
                    })

                }
            }
        } else {
            if (this.state.dataUsuario.username === "") {
                value = false;
                await this.setState({
                    invalid: {
                        ...this.state.invalid,
                        username: true,

                    }
                })

            }
        }

        return callback(value);

    }

    guardarUsuario = async () => {

        await this.validarInputus(async (value) => {


            await swal2.fire({
                title: `${(this.state.dataUsuario.IdUsuario === "" ? "Guardar" : "Actualizar")} Usuario`,
                text: `Estas seguro de que quieres ${(this.state.dataUsuario.IdUsuario === "" ? "guardar" : "actualizar")} este usuario`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: colors.azul_1,
                timer: "15000",
                reverseButtons: true,
                timerProgressBar: true,
                confirmButtonText: 'Si, ' + (this.state.dataUsuario.IdUsuario === "" ? "guardar" : "actualizar"),
                //footer: '<p style="color:#E60026;">No se podra recuperar al usuario una ves eliminado</p>',
            }).then((result) => {
                if (result.isConfirmed) {
                    if (value) {
                        if (this.state.dataUsuario.IdUsuario === "") {
                            // Crear usuario
                            userModel.usuario_crear(this.state.dataUsuario, (data) => {
                                console.log(data);
                                let { IdUsuario, status } = data;
                                this.setState({
                                    dataUsuario: {
                                        ...this.state.dataUsuario,
                                        IdUsuario
                                    }
                                });
                                if (status === 200) {
                                    this.Toast.fire({
                                        icon: "success",
                                        title: "Usuario Creado"
                                    });
                                }
                            });
                        } else {
                            // Actualizar Usuario

                            userModel.usuario_actualizar(this.state.dataUsuario, (data) => {
                                let { status } = data;
                                if (status === 200) {
                                    this.Toast.fire({
                                        icon: "success",
                                        title: "Usuario Actualizado"
                                    });
                                }
                            });
                        }
                    }
                }
            });



        });

    }


    openCloseModal = async () => {
        await this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} usuario={cookies.get("usuario", { path: "/" })} />
                <div

                    style={{
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginTop: this.state.isMobileDevice ? "20px" : "80px",
                        height: "90%",
                    }}
                >
                    <Row
                        style={{
                            margin: "15px"
                        }}
                    >
                        <Label
                            style={{
                                //marginTop: "10px",
                                //marginLeft: "25px",
                                fontWeight: "bold",
                                fontSize: "20px"
                            }}
                        >
                            Datos de Usuario
                        </Label>
                        <Col
                            md={3}

                        >
                            <FormGroup>
                                <Label>Nombre de usuario</Label>
                                <InputGroup>

                                    <Input
                                        id="idNombreUsuario"
                                        value={this.state.dataUsuario.username}
                                        autoFocus
                                        type="text"
                                        name="username"
                                        onChange={(e) => {
                                            let { length } = e.target.value;
                                            if (length > 50) return;
                                            this.handleChangeDataUsuario(e)

                                        }}
                                        autoComplete="nope"
                                        invalid={this.state.invalid.username}
                                    />
                                    <FormFeedback
                                        tooltip
                                        style={this.state.formFeedbackStyle}
                                    >
                                        Falta el nombre de usuario.
                                    </FormFeedback>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md={3}
                        >
                            <FormGroup>
                                <Label>Contraseña de usuario</Label>
                                <InputGroup>

                                    <Input
                                        style={{
                                            backgroundColor: "#FFFFFF",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            this.openCloseModal();
                                        }}
                                        onKeyDown={(e) => {
                                            let { key } = e;
                                            if (key === "Enter") this.openCloseModal();
                                        }}
                                        value={!this.state.dataUsuario.IdUsuario === "" ? "" : "*********"}
                                        type="text"
                                        invalid={this.state.invalid.password}
                                    />
                                    <FormFeedback
                                        tooltip
                                        style={this.state.formFeedbackStyle}
                                    >
                                        Falta la contraseña del usuario.
                                    </FormFeedback>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md={3}

                        >
                            <FormGroup>
                                <Label>Estatus *</Label>
                                <InputGroup>

                                    <Input
                                        value={this.state.dataUsuario.IdStatus}
                                        type="select"
                                        name="IdStatus"
                                        onChange={(e) => {
                                            let { length } = e.target.value;
                                            if (length > 50) return;
                                            this.handleChangeDataUsuario(e)
                                        }}
                                    >
                                        <option value={1}>Activo</option>
                                        <option value={2}>Bloqueado</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md={3}

                        >
                            <FormGroup>
                                <Label>Tipo de Usuario</Label>
                                <InputGroup>

                                    <Input
                                        value={this.state.dataUsuario.IdUsuarioTipo}
                                        type="select"
                                        name="IdUsuarioTipo"
                                        onChange={(e) => {
                                            this.handleChangeDataUsuario(e)
                                        }}
                                    >
                                        <option value={2}>Team</option>
                                        <option value={3}>Gerente</option>
                                        <option value={1}>Administrador</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row><Col></Col></Row>
                </div>
                <div

                    style={{
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginTop: "10px",
                        height: "90%",
                    }}
                >
                    <Row
                        style={{
                            margin: "15px"
                        }}
                    >
                        <Label
                            style={{
                                //marginTop: "10px",
                                //marginLeft: "25px",
                                fontWeight: "bold",
                                fontSize: "20px"
                            }}
                        >
                            Datos de Persona
                        </Label>
                        <Col
                            md={4}

                        >
                            <FormGroup>
                                <Label>Nombre(s)</Label>
                                <InputGroup>

                                    <Input
                                        value={this.state.dataUsuario.nombre}
                                        name="nombre"
                                        type="text"
                                        onChange={(e) => {
                                            let { length } = e.target.value;
                                            if (length > 50) return;
                                            this.handleChangeDataUsuario(e)
                                        }}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md={4}
                        >
                            <FormGroup>
                                <Label>Apellido Paterno</Label>
                                <InputGroup>

                                    <Input
                                        value={this.state.dataUsuario.paterno}
                                        name="paterno"
                                        type="text"
                                        onChange={(e) => {
                                            let { length } = e.target.value;
                                            if (length > 50) return;
                                            this.handleChangeDataUsuario(e)
                                        }}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md={4}

                        >
                            <FormGroup>
                                <Label>Apellido Materno</Label>
                                <InputGroup>

                                    <Input
                                        value={this.state.dataUsuario.materno}
                                        type="text"
                                        name="materno"
                                        onChange={(e) => {
                                            let { length } = e.target.value;
                                            if (length > 50) return;
                                            this.handleChangeDataUsuario(e)
                                        }}
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            margin: "15px"
                        }}
                    >
                        <Col
                            md={4}

                        >
                            <FormGroup>
                                <Label>Telefono</Label>
                                <InputGroup>

                                    <Input
                                        value={this.state.dataUsuario.telefono}
                                        name="telefono"
                                        onChange={(e) => {
                                            let { length } = e.target.value;
                                            if (length > 12) return;
                                            this.handleChangeDataUsuario(e)
                                        }}
                                        type="text"
                                        autoComplete="off"
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md={4}
                        >
                            <FormGroup>
                                <Label>Correo</Label>
                                <InputGroup>

                                    <Input
                                        value={this.state.dataUsuario.correo}
                                        name="correo"
                                        onChange={(e) => {
                                            let { length } = e.target.value;
                                            if (length > 50) return;
                                            this.handleChangeDataUsuario(e)
                                        }}
                                        autoComplete="nope"
                                        type="text"
                                    />

                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col
                            md={4}

                        >
                            <FormGroup>
                                <Label>Genero</Label>
                                <InputGroup>

                                    <Input
                                        value={this.state.dataUsuario.IdGenero}
                                        type="select"
                                        name="IdGenero"
                                        onChange={(e) => {
                                            let { length } = e.target.value;
                                            if (length > 50) return;
                                            this.handleChangeDataUsuario(e)
                                        }}
                                    >
                                        <option value={1}>Sin espesificar</option>
                                        <option value={2}>Hombre</option>
                                        <option value={3}>Mujer</option>
                                    </Input>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            margin: "15px"
                        }}
                    >
                        <div
                            style={{
                                textAlign: "right",
                            }}
                        >
                            {
                                this.state.dataUsuario.IdUsuario !== "" ?
                                    <>
                                        <Button
                                            style={{
                                                backgroundColor: colors.azul_1,
                                                width: this.state.isMobileDevice ? "100%" : "175px",
                                                marginRight: "15px",

                                            }}
                                            onClick={async () => {
                                                this.setState({
                                                    isOpenModalButton: true,
                                                })
                                            }}
                                        >
                                            <MoreHoriz //FeaturedPlayList
                                                style={{
                                                    marginRight: "5px",

                                                }}
                                            />
                                            Mas
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: "red",
                                                width: this.state.isMobileDevice ? "100%" : "175px",
                                                marginRight: "15px",

                                            }}
                                            onClick={() => {
                                                this.eliminarUsuario();
                                            }}
                                        >
                                            <Delete
                                                style={{
                                                    marginRight: "5px",

                                                }}
                                            />
                                            Eliminar
                                        </Button>
                                    </>
                                    :
                                    null
                            }
                            <Button
                                style={{
                                    backgroundColor: colors.azul_1,
                                    width: this.state.isMobileDevice ? "100%" : "175px",
                                    marginRight: "15px",
                                    marginTop: this.state.isMobileDevice ? "10px" : "",
                                }}
                                onClick={() => {
                                    this.nuevoUsuario();
                                }}
                            >
                                <Cached
                                    style={{
                                        marginRight: "5px",

                                    }}
                                />
                                Nuevo
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: colors.azul_1,
                                    width: this.state.isMobileDevice ? "100%" : "175px",
                                    marginTop: this.state.isMobileDevice ? "10px" : "",
                                }}
                                onClick={() => {
                                    this.guardarUsuario();
                                }}
                            >
                                <Save
                                    style={{
                                        marginRight: "5px"
                                    }}
                                />
                                {this.state.dataUsuario.IdUsuario === "" ? "Guardar" : "Actualizar"}
                            </Button>
                        </div>
                    </Row>
                    <Row><Col></Col></Row>
                </div>
                <Modal
                    isOpen={this.state.isOpenModal}
                    style={{
                        marginTop: "1%"
                    }}
                    onOpened={() => {
                        this.focusElement("idpasswprd")
                        this.setState({
                            password: {
                                value_1: "",
                                value_2: "",
                            }
                        });
                    }}
                >
                    <ModalHeader>
                        {this.state.dataUsuario.IdUsuario === "" ? "Crear contraseña" : "Cambiar contraseña"}
                    </ModalHeader>

                    <ModalBody>
                        <Label
                            style={{
                                fontSize: "20px ",
                                fontWeight: "bold",
                                color: "red"
                            }}
                        >
                            {

                                (this.state.password.value_1 === this.state.password.value_2) ?
                                    ""
                                    :
                                    "Las contraseñas no coinciden"
                            }
                        </Label>
                        <FormGroup>
                            <Label>Nueva contraseña</Label>
                            <InputGroup>

                                <Input
                                    id="idpasswprd"
                                    type={!this.state.isVisibilityPassword ? "password" : "text"}
                                    value={this.state.password.value_1}
                                    onChange={(e) => {
                                        let { value } = e.target;

                                        this.setState({
                                            password: {
                                                ...this.state.password,
                                                value_1: value
                                            }
                                        })
                                    }}
                                    autoComplete="nope"

                                //invalid={this.state.password.value_1 === this.state.password.value_2 ? false:true}


                                />

                                <InputGroupText
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: "#fff",
                                        opacity: this.state.opacity,
                                    }}
                                    onClick={async () => {
                                        await this.setState({
                                            isVisibilityPassword: !this.state.isVisibilityPassword,
                                            inputType: this.state.isVisibilityPassword ? "password" : "text",
                                        });
                                    }}>
                                    {this.state.isVisibilityPassword ?
                                        <Visibility style={{ color: colors.azul_1 }} />
                                        :
                                        <VisibilityOff style={{ color: colors.azul_1 }} />
                                    }
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label>Confirmar nueva contraseña</Label>
                            <InputGroup>

                                <Input
                                    //value={}
                                    type="password"
                                    value={this.state.password.value_2}
                                    onChange={(e) => {
                                        let { value } = e.target;

                                        this.setState({
                                            password: {
                                                ...this.state.password,
                                                value_2: value
                                            }
                                        })
                                    }}

                                    autoComplete="off"
                                />
                            </InputGroup>
                        </FormGroup>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: "#D2CDCA",
                                color: "#000"
                            }}
                            onClick={() => {
                                this.openCloseModal();
                            }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.azul_1
                            }}
                            onClick={async () => {

                                if ((this.state.password.value_1 === this.state.password.value_2)) {
                                    if (this.state.password.value_1.length > 0) {

                                        await this.setState({
                                            dataUsuario: {
                                                ...this.state.dataUsuario,
                                                password: this.state.password.value_1
                                            }
                                        })
                                        await this.openCloseModal();
                                    } else {
                                        swal2.fire({
                                            icon: 'error',
                                            title: 'Contraseña no valida',
                                            text: `la contraseña no puede ser un campo vacio`,
                                            timer: "10000",
                                            confirmButtonColor: colors.azul_1,
                                            confirmButtonText: 'Okey',

                                            allowEscapeKey: true,
                                            timerProgressBar: true,

                                        })
                                    }

                                } else {
                                    if ((this.state.password.value_1.length === 0) || (this.state.password.value_2.length === 0)) {
                                        swal2.fire({
                                            icon: 'error',
                                            title: 'Campo vacio',
                                            text: `Los o uno de los compos esta vacio, se tiene que validar la contraseña`,
                                            timer: "10000",
                                            confirmButtonColor: colors.azul_1,
                                            confirmButtonText: 'Okey',

                                            allowEscapeKey: true,
                                            timerProgressBar: true,

                                        });
                                    } else {
                                        swal2.fire({
                                            icon: 'error',
                                            title: 'Contraseña no valida',
                                            text: `Las contraseñas no coinciden`,
                                            timer: "10000",
                                            confirmButtonColor: colors.azul_1,
                                            confirmButtonText: 'Okey',

                                            allowEscapeKey: true,
                                            timerProgressBar: true,

                                        });
                                    }

                                }
                            }}
                        >
                            Guardar
                        </Button>
                    </ModalFooter>

                </Modal>

                <Modal
                    style={{
                        marginTop: "15%"
                    }}
                    isOpen={this.state.isOpenModalButton}
                >
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody
                    >
                        <Row>
                            <Col
                                md={3}
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <abbr
                                    style={{
                                        textDecoration: "none",
                                        title: "Ver Historial",
                                    }}
                                >
                                    <Button
                                        style={{
                                            margin: "5px",
                                            backgroundColor: "#F24801",
                                            borderColor: "#F24801",
                                        }}
                                        onClick={ async () => {
                                            await this.setState({
                                                isOpenModalButton: false,
                                            })
                                            await cookies.set("IdUsuario", this.state.dataUsuario.IdUsuario, { path: "/" });
                                            await window.open("/historial-de-usuarios");
                                        }}
                                    >
                                        <Sort />
                                    </Button>
                                </abbr>
                                <Label>Ver Historial</Label>
                            </Col>
                            <Col
                                md={3}
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <abbr
                                    style={{
                                        textDecoration: "none",
                                        title: "Ver Progreso",
                                    }}
                                >
                                    <Button
                                        style={{
                                            margin: "5px",
                                            backgroundColor: "#58AE58",
                                            borderColor: "#58AE58",
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                isOpenModalButton: false,
                                            })
                                            await cookies.set("IdUsuarioProgreso", this.state.dataUsuario.IdUsuario, { path: "/" });
                                            await window.open("/usuario-progreso");
                                        }}
                                    >
                                        <TrendingUp />
                                    </Button>
                                </abbr>
                                <Label>Ver Progreso</Label>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                this.setState({
                                    isOpenModalButton: false,
                                })
                            }}
                            style={{
                                backgroundColor: colors.azul_1,
                                borderColor: colors.azul_1,
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

export default UsuarioView;

