import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

// Importaciones de clases publicas
import ListaCursosPublio from "./Pages/Public/ListaCursos";
import LoginAdministrador from "./Pages/Public/LoginAdministrador";
import CursoBody from "./Pages/Public/CursoBody";
import LoginTeamp from "./Pages/Public/LoginTeam";

// Importaciones de clases privadas 
import CursoView from "./Pages/Private/CursoView";
import ListaCursosPrivado from "./Pages/Private/ListarCursos";
import Lamina from "./Pages/Private/Laminas";
import UsuarioList from "./Pages/Private/UsuarioList";
import UsuarioView from "./Pages/Private/UsuarioView";
import CursoBodyPrivate from "./Pages/Private/CursoBody";
import HistorialList from './Pages/Private/HistorialList';
import HistorialUsuario from './Pages/Private/HistorialUsuario';
import CursoVideo from './Pages/Private/CursoVideo';
import UsuarioProgreso from "./Pages/Private/UsuarioProgreso";

const  App = () => {
  return (
    <>
      <Router>
        <Routes>

          {/* Rutas publicas */}
          <Route exact path="/" element={<LoginTeamp/>}/>   
          <Route exact path="/listado-cursos-publico" element={<ListaCursosPublio/>}/>
          <Route exact path="/curso" element={<CursoBody/>}/>
          <Route exact path="/login-administrador" element={<LoginAdministrador/>}/>                 

          {/* Rutas privadas */}
          <Route exact path="/listado-cursos" element={<ListaCursosPrivado/>}/>
          <Route exact path="/ver-curso" element={<CursoView/>}/>        
          <Route exact path="/diapositivas" element={<Lamina/>}/>
          <Route exact path="/usuarios" element={<UsuarioList/>}/>  
          <Route exact path="/ver-usuario" element={<UsuarioView/>}/>  
          <Route exact path="/curso-privado" element={<CursoBodyPrivate/>}/>
          <Route exact path="/historial-usuarios" element={<HistorialList/>}/>     
          <Route exact path="/historial-de-usuarios" element={<HistorialUsuario/>}/>
          <Route exact path="/videos-del-curso" element={<CursoVideo/>}/>     
          <Route exact path="/usuario-progreso" element={<UsuarioProgreso/>}/>    

        </Routes>
      </Router>
    </>
  );
}

export default App;
