import { Component } from "react";
import NavBar from "../../Components/NavBar";
import colors from "../../Configuration/Colors.config";
import Cookies from "universal-cookie";
import Table from "react-data-table-component";
import noData from "../../Multimedia/noData.svg";
import swal2 from 'sweetalert2'
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Button,
    CardImg,
    Row,
    Col,
    //Modal,
    Label,
    InputGroup,
    Input,
    //ModalBody,
    Spinner,
    InputGroupText,
    Progress
} from "reactstrap";
import imgNueva from "../../Multimedia/imgNueva.png";
import IconButton from "@material-ui/core/IconButton";
import {
    Edit,
    Search,
    Cached,
    Visibility,
    VisibilityOff
} from '@material-ui/icons';
import historialModel from "../../Model/Hisrorial.modal";
const cookies = new Cookies();

class UsuarioProgreso extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: true,
            dataList: [],
            dataColums: []
        }
    }

    async componentDidMount() {
        document.body.style.backgroundColor = colors.azul_1;
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });

        this.cargarDatosPagina();
    }

    cargarDatosPagina = async () => {
        let usuario = await cookies.get('usuario', { path: "/" })

        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',

                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {
                window.location.href = '/login-administrador'
            });

        } else {
            let dataUsuario = cookies.get('dataUsuario', { path: "/" })
            if (dataUsuario != null) {
                await this.setState({
                    dataUsuario: {
                        ...dataUsuario,
                        password: ""
                    }
                });
                // cookies.remove("dataUsuario", { path: "/" });
                historialModel.usuario_cursos_vistos(cookies.get("IdUsuarioProgreso", { path: "/" }), (data) => {
                    let dataList = data.historial;
                    if (dataList.length == 0) {
                        swal2.fire({
                            icon: 'warning',
                            title: 'No hay progreso registrado',
                            text: ``,
                            confirmButtonColor: colors.azul_1,
                            confirmButtonText: 'Okey',
                            allowEscapeKey: true,
                            timerProgressBar: true,
                        })
                    }
                    // for (let i = 0; i < dataList.length; i++) {
                    //     console.log(dataList[i].img);
                    //     dataList[i].img = dataList[i].img.substring(); 
                    // }

                    this.setState({
                        dataList
                    })
                });
            }
        }
        await this.setState({
            usuario
        });

    }


    calcularProgreso = (cont, total) => {

        if (cont >= total) {
            cont = total;
        }

        let porcentaje = (cont / total) * 100;
        return porcentaje.toFixed(2);
    }

    render() {
        return (<>
            <NavBar isMobileDevice={this.state.isMobileDevice} usuario={cookies.get("usuario", { path: "/" })} />

            {
                this.state.dataList == 0 ?
                    <div
                    style={{
                        marginTop: "120px",
                        textAlign: "center"
                    }}   
                    >
                        <img
                            src={noData}
                        />
                    </div>
                    :
                    <Row
                        style={{
                            marginTop: "70px",
                            marginRight: "10px",
                            marginLeft: "10px"
                        }}
                    >
                        {this.state.dataList.map((curso) => {

                            return (
                                <Col
                                    md={3}
                                    style={{
                                        height: "425px",
                                        marginTop: "20px",
                                    }}
                                >
                                    <Card>
                                        <CardImg
                                            alt="Card image cap"
                                            src={curso.img.length === 0 ? imgNueva : "data:image/*;base64," + curso.img}
                                            style={{
                                                height: "200px"
                                            }}
                                            top
                                        />
                                        <CardBody>

                                            <CardTitle
                                                tag="h5"
                                                style={{
                                                    fontSize: "18px"
                                                }}
                                            >
                                                {curso.titulo}
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                                style={{
                                                    fontSize: "18px"
                                                }}
                                            >
                                                {curso.subTitulo}
                                            </CardSubtitle>
                                            <Label
                                                style={{
                                                    fontSize: "18px",
                                                    fontWeight: "bold",
                                                    color: this.calcularProgreso(curso.laminasVistas, curso.num) > 0 && this.calcularProgreso(curso.laminasVistas, curso.num) <= 25 ? "grey" : this.calcularProgreso(curso.laminasVistas, curso.num) > 25 && this.calcularProgreso(curso.laminasVistas, curso.num) <= 75 ? "orange" : "green"
                                                }}
                                            >
                                                Progreso de Avance {curso.laminasVistas >= curso.num ? `${curso.num}/${curso.num}` : `${curso.laminasVistas}/${curso.num}`}
                                            </Label>
                                            <Progress
                                                style={{
                                                    width: "100%",
                                                    height: "30px",
                                                    fontSize: "20px",

                                                }}
                                                //animated
                                                className="my-2"

                                                value={this.calcularProgreso(curso.laminasVistas, curso.num)}
                                            >
                                            </Progress>
                                            <div
                                                style={{
                                                    textAlign: "center"
                                                }}
                                            >
                                                <Label
                                                    style={{
                                                        color: colors.azul_1,
                                                        marginTop: "10px",
                                                        fontWeight: "bold"
                                                    }}
                                                >
                                                    {this.calcularProgreso(curso.laminasVistas, curso.num)}% Completado
                                                </Label>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )

                        })}
                    </Row>
            }

        </>
        );
    }

}

export default UsuarioProgreso;