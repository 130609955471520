

const colors = {
    azul_1: "#005F8A",
    amarillo: "#F2E227",
    rojo_2: "#D71734",
    rojo : "#FC003B",
    blanco:"#FFFFFF",
    celeste : "#0069D9"
}

export default colors 