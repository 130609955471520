
import React, { useState } from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Navbar,
    Nav
} from 'reactstrap';
import {
    List,
    Group,
    Add,
    Apps,
    Person,
    ExitToApp,
    Dashboard,
    ViewCarousel,
    PersonAdd,
    Update
} from '@material-ui/icons';
import historialModel from "../Model/Hisrorial.modal";
import Cookies from 'universal-cookie';

const cookies = new Cookies();


const NavBarComputer = (props) => {
    // const [isOpen, setIsOpen] = useState(false);
    const [style] = useState({
        backgroundColor: "#fff",
        color: '#016390',
        fontWeight: "bold",
        border: "none",
        boxShadow: "none"
    });
    const [styleIcon] = useState({
        marginTop: "-5px",
        marginRight: "5px",
        color: "#016390"
    });

    //const open = () => setIsOpen(!isOpen);

    const logOut = () => {

        let usuario = cookies.get('usuario', { path: "/" })
        let dataMovimiento = {
            IdMovimiento: 2,
            IdUsuario: usuario.IdUsuario
        }

        historialModel.historial_nuevo_movimiento(dataMovimiento, (data) => { });
        cookies.remove('usuario', { path: "/" });
        if (props.usuario.IdUsuarioTipo === 1) {
            window.location.href = "/login-administrador";
        } else {
            window.location.href = "/";
        }
    }




    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: "100"
                }}
            >
                <Navbar
                    expand="md"
                    style={{

                        backgroundColor: "#fff",
                        height: "62px"
                    }}>
                    <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                        {/* <NavItem>
                            <NavLink style={{ fontWeight: "bold", color: "#016390" }} href="/"><Home style={styleIcon} />Cursos</NavLink>
                        </NavItem> */}
                        <UncontrolledDropdown
                            className="me-2"
                            direction="down"
                            setActiveFromChild
                        >
                            <DropdownToggle
                                caret
                                style={style}

                            >
                                <ViewCarousel style={styleIcon} />Cursos
                            </DropdownToggle>
                            <DropdownMenu>
                                {/*                               IdUsuarioTipo */}
                                <DropdownItem href={props.usuario.IdUsuarioTipo === 1 ? '/listado-cursos' : '/listado-cursos-publico'}>
                                    <List style={styleIcon} />Ver Cursos
                                </DropdownItem>
                                {props.usuario.IdUsuarioTipo === 1 ?
                                    <>
                                        <DropdownItem divider />
                                        <DropdownItem stle={styleIcon} href="/ver-curso">
                                            <Add style={styleIcon} />Agregar Curso
                                        </DropdownItem>
                                    </>
                                    :
                                    null
                                }
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {props.usuario.IdUsuarioTipo === 1 ?
                            <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={style}
                                >
                                    <Group style={styleIcon} />Usuario
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href='/usuarios'>
                                        <List style={styleIcon} />Ver Usuarios
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => { window.location.href = '/ver-usuario' }}>
                                        <PersonAdd style={styleIcon} />Agregar Usuario
                                    </DropdownItem>
                                    {/* <DropdownItem divider />
                                    <DropdownItem onClick={() => { window.location.href = '/historial-usuarios' }}>
                                        <Update style={styleIcon} />Ver Hisotial
                                    </DropdownItem> */}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            :
                            null
                        }
                        {/* <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={style}
                                >
                                    <CreditCard style={styleIcon} />Creditos
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href='/lista-credito'>
                                        <List style={styleIcon} />Ver Creditos
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem href="/solicitud-credito">
                                        <MonetizationOn style={styleIcon} />Solicitar Credito
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}
                        {/* <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={style}
                                >
                                    <Description style={styleIcon} /> Fichas de Pago
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href='/ficha-pago-list'>
                                        <List style={styleIcon} /> Ver Fichas de Pago
                                    </DropdownItem>
                                    <DropdownItem href='/ficha-deposito'>
                                        <InsertDriveFile style={styleIcon} /> Generar Ficha de Pago
                                    </DropdownItem>
                                    <DropdownItem href='/pagar-ficha-pago'>
                                        <AttachMoney style={styleIcon} /> Pagar Ficha de Pago
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}
                    </Nav>
                    <UncontrolledDropdown>
                        <DropdownToggle
                            //caret
                            style={style}
                        >
                            <Apps />
                        </DropdownToggle>
                        <DropdownMenu>
                            {props.usuario.IdUsuarioTipo === 2 ?
                                <>
                                    <DropdownItem href='/login-administrador'>
                                        <Dashboard style={styleIcon} /> Panel de administración
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => logOut()}>
                                        <ExitToApp style={styleIcon} /> Cerrar Sesion
                                    </DropdownItem>
                                </>
                                :
                                <>
                                    {/* <DropdownItem href='/login-administrador'>
                                        <Person style={styleIcon} /> Mi Perfil
                                    </DropdownItem>
                                    <DropdownItem divider /> */}
                                    {/* <DropdownItem href='/login-administrador'>
                                        <PersonOutline style={styleIcon} /> Login
                                    </DropdownItem> */}
                                    <DropdownItem onClick={() => logOut()}>
                                        <ExitToApp style={styleIcon} /> Cerrar Sesion
                                    </DropdownItem>
                                </>
                            }

                            {/*  */}
                            {/* <DropdownItem onClick={() => {}}>
                                    <ExitToApp style={styleIcon} />Cerrar sesion
                                </DropdownItem> */}
                            {/* <DropdownItem divider /> */}
                            {/* <UncontrolledDropdown
                                    className="me-2"
                                    direction="start"
                                >
                                    <DropdownToggle
                                        caret
                                        //color="primary"
                                        style={style}
                                    >
                                        Dropstart
                                    </DropdownToggle>
                                     <DropdownMenu>
                                        <DropdownItem>
                                            Another Action
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>
                                            Another Action
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>  */}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Navbar>
            </div>
        </>
    );
}

export default NavBarComputer;