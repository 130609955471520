import NavBarComputer from "./NavBarComputer";
import NavBarMovil from "./NavBarMovil";

const NavBar = (props) => {
   

    return (
        <>
            {!props.isMobileDevice ?
                <NavBarComputer isUser={props.isUser} usuario={props.usuario}/>
                :
                <NavBarMovil isUser={props.isUser} usuario={props.usuario}/>
            }
        </>
    );
}

export default NavBar;