import { Component } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Button,
    CardImg,
    Row,
    Col,
    //Modal,
    Label,
    InputGroup,
    Input,
    //ModalBody,
    Spinner,
    InputGroupText
} from "reactstrap";
import {
    Edit,
    Search,
    Cached,
    Visibility,
    VisibilityOff
} from '@material-ui/icons';
import imgNueva from "../../Multimedia/imgNueva.png";
import IconButton from "@material-ui/core/IconButton";
//import imgLoadPage from "../../Multimedia/loadPage.svg"
// import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import colors from "../../Configuration/Colors.config"
import NavBar from "../../Components/NavBar";
import Cookies from 'universal-cookie';
import cursoModel from "../../Model/Curso.model";
import swal2 from 'sweetalert2';


const cookies = new Cookies();


class CursosList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usuario: {
                IdUsuario: "",
                IdStatus: "",
                status: "",
                nombre: "",
                paterno: "",
                materno: "",
                telefono: "",
                correo: "",
                username: "",
                IdUsuarioTipo: ""
            },
            isMobileDevice: false,
            cursosList: [
            ],
            isOpen: true,
            isSearchCurso: false,
            titulo: "",
            array: [
                {
                    i: 1
                },
                {
                    i: 2
                },
                {
                    i: 3
                },
            ],
        };
    }

    Toast = swal2.mixin({
        toast: true,
        position: 'bottom-start',
        showConfirmButton: false,
        timer: 7000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });


    sleep = (seconds) => new Promise(
        resolve => setTimeout(resolve, (seconds * 1000))
    );

    async componentDidMount() {

        document.body.style.backgroundColor = colors.azul_1;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        let usuario = await cookies.get('usuario', { path: "/" });


        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                //timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',

                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {

                window.location.href = '/login-administrador';

            });

        } else {
            //alert(usuario.username);
            //await this.sleep(2);
            await this.cargarDataPage();
            await this.setState({
                isMobileDevice: isMobileDevice,
                usuario
            });

        }


    }

    cargarDataPage = async () => {

        await cursoModel.curso_listar((data) => {

            let cursosList = data.cursos

            if (data.status === 200) {
                this.setState({
                    cursosList,
                    isOpen: false
                });
                // if (!this.state.isMobileDevice) {
                //     this.Toast.fire({
                //         icon: cursosList.length === 0 ? 'warning' : 'info',
                //         title: 'Cursos',
                //         text: `Se encontraron ${cursosList.length} cursos`,
                //         timer: "5000"
                //     });
                // }
            }
        });
    }

    buscarCurso = async () => {

        cursoModel.cursos_buscar(this.state.titulo, (data) => {

            let { cursos } = data;

            this.setState({
                isSearchCurso: !this.state.isSearchCurso,
                cursosList: cursos
            });
            if (!this.state.isMobileDevice) {
                this.Toast.fire({
                    icon: cursos.length === 0 ? "warning" : "success",
                    title: cursos.length === 0 ? "No se encotraron cursos" : `Se encontraron ${cursos.length} cursos`
                });
            }
        });




    }

    fucusElement = (Id) => {
        document.getElementById(Id).focus()
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} usuario={this.state.usuario} />
                <Row
                    style={{
                        //margin: "20px",
                        marginRight: "20px",
                        marginLeft: "20px",
                        marginTop: this.state.isMobileDevice ? "10px" : "70px",
                        backgroundColor: colors.blanco,
                        height: "100px",
                        borderRadius: "8px"
                    }}
                >
                    <div>
                        <Label
                            style={{
                                fontSize: "32px",
                                color: "#000",
                                borderRadius: "8px",
                                fontWeight: "bold"
                            }}
                        >
                            Buscar curso
                            <button
                                style={{
                                    border: "none",
                                    backgroundColor: "transparent"
                                }}
                                onClick={() => {
                                    this.cargarDataPage();
                                }}
                            >
                                <Cached
                                    style={{
                                        color: colors.azul_1,
                                        height: "32px",
                                        marginLeft: "3px"
                                    }}

                                />
                            </button>
                        </Label>

                    </div>
                    <InputGroup
                        style={{
                            width: "90vh",
                            height: "24px",
                            marginTop: "-30px"
                        }}
                    >

                        <Input
                            id="iptNombreCurso"
                            placeholder="Nombre del curso"
                            style={{
                                fontSize: "26px",
                                height: "36px"
                            }}
                            onChange={async (e) => {
                                let { value } = e.target;
                                await this.setState({
                                    titulo: value
                                });
                            }}
                            value={this.state.titulo}
                            onKeyDown={async (e) => {
                                if (e.key === "Enter") {
                                    this.fucusElement("iptNombreCurso");
                                    await this.setState({
                                        isSearchCurso: !this.state.isSearchCurso
                                    });
                                    this.buscarCurso();
                                }
                            }}
                        />
                        <InputGroupText
                            style={{
                                backgroundColor: colors.azul_1,
                                cursor: "pointer"
                            }}
                            onClick={async () => {
                                this.fucusElement("iptNombreCurso");
                                await this.setState({
                                    isSearchCurso: !this.state.isSearchCurso
                                });
                                this.buscarCurso();

                            }}
                        >

                            {this.state.isSearchCurso ?
                                <Spinner size="sm" style={{ color: "#fff", height: "22px", width: "22px", fontSize: "18px" }} >Loading...</Spinner>
                                :
                                <Search
                                    style={{
                                        color: colors.blanco,
                                        height: "24px"
                                    }}
                                />
                            }
                        </InputGroupText>
                    </InputGroup>

                </Row>
                <Row
                    style={{
                        marginRight: "10px",
                        marginLeft: "10px"
                    }}
                >
                    {this.state.cursosList.map((curso) => {

                        return (
                            <Col
                                md={3}
                                style={{
                                    height: "425px",
                                    marginTop: "20px",

                                }}
                            >
                                <Card                                        >
                                    <CardImg
                                        alt="Card image cap"
                                        src={curso.img.length === 0 ? imgNueva : "data:image/*;base64," + curso.img}
                                        style={{
                                            height: "200px"
                                        }}
                                        top
                                    />
                                    <CardBody>
                                        <p align="right">
                                            <abbr
                                                title={`Curso ${curso.mostrarCurso === 1 ? "visible" : "no visible"}`}
                                            >
                                                {
                                                    curso.mostrarCurso === 1 ?
                                                        <Visibility
                                                            style={{
                                                                color: colors.azul_1,
                                                                textDecoration: "nonde"
                                                            }}
                                                        />
                                                        :
                                                        <VisibilityOff
                                                            style={{
                                                                color: "grey"
                                                            }}
                                                        />
                                                }
                                            </abbr>
                                            <IconButton
                                                onClick={() => {
                                                    cookies.set("IdCurso", curso.IdCurso, { path: "/" });
                                                    window.location.href = "/ver-curso"
                                                }}
                                            >


                                                <Edit
                                                    style={{
                                                        color: colors.azul_1
                                                    }}
                                                />
                                            </IconButton>
                                        </p>
                                        <CardTitle tag="h5">
                                            {curso.titulo}
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            {curso.subTitulo}
                                        </CardSubtitle>
                                        <Input
                                                type="textarea"
                                                value={curso.textoInformativo}
                                                disabled
                                            >
                                                {curso.textoInformativo}
                                            </Input>
                                        <Button
                                            style={{
                                                backgroundColor: colors.azul_1,
                                                fontWeight: "bold",
                                                width: "125px",
                                                marginRight: "5px",
                                                marginTop: "5px"
                                            }}
                                            onClick={() => {
                                                cookies.set("IdCurso", curso.IdCurso, { path: "/" });
                                                window.location.href = "/curso-privado";
                                            }}
                                        >
                                            Ver curso
                                        </Button>
                                        {/* <Button
                                            style={{
                                                backgroundColor: colors.azul_1,
                                                fontWeight: "bold",
                                                width: "125px",
                                            }}
                                            onClick={async () => {
                                                await console.log(curso);
                                                await this.sleep(2);
                                                await cookies.set("IdCurso", curso.IdCurso, { path: "/" });
                                                window.location.href = "/videos-del-curso";
                                            }}
                                        >
                                            Video
                                        </Button> */}

                                    </CardBody>
                                </Card>
                            </Col>
                        )

                    })}
                </Row>
                {!this.state.isMobileDevice ?
                    this.state.isOpen ?

                        <Row
                            style={{
                                marginTop: "20px",
                                marginLeft: !this.state.isMobileDevice ? "10px" : "20px"
                            }}
                        >
                            {this.state.array.map((value) => {
                                return (
                                    <Col
                                        md={3}
                                        style={{
                                            height: "425px",
                                            //marginTop: "20px"
                                        }}
                                    >
                                        <Box
                                            overflow="hidden"
                                            style={{}}>

                                            <Grid
                                                container
                                                wrap="nowrap"
                                                style={{
                                                    color: "red",
                                                    //marginLeft:"-7px"
                                                }}
                                            >

                                                <Box
                                                    width={370}
                                                    //marginRight={0.5}
                                                    my={5}>

                                                    <Skeleton

                                                        variant="rect"
                                                        width={370}
                                                        height={270} />
                                                    <Box pr={2}>

                                                        <Typography gutterBottom variant="body2">
                                                        </Typography>

                                                        <Typography display="block" variant="caption">
                                                        </Typography>

                                                        <Typography variant="caption" >
                                                        </Typography>

                                                    </Box>

                                                    <Box pt={0.5}>
                                                        <Skeleton />
                                                        <Skeleton width="80%" />
                                                        <Skeleton width="60%" />
                                                        <Skeleton width="60%" />
                                                        {/* <Skeleton width="60%" /> */}
                                                    </Box>


                                                </Box>

                                            </Grid>

                                        </Box>
                                    </Col>

                                );
                            })}



                        </Row>
                        :
                        null
                    :

                    <div
                        style={{
                            textAlign: "center",
                            marginTop: "50%"
                        }}
                    >
                        {this.state.isOpen ?
                            <>
                                <Spinner size="xl" style={{ color: colors.blanco, height: "100px", width: "100px", fontSize: "24px" }} >Loading...</Spinner>
                                <br />
                                <label
                                    style={{
                                        marginTop: "25px",
                                        fontWeight: "bold",
                                        color: colors.blanco,
                                        fontSize: "25px"
                                    }}
                                >
                                    Cargando Página
                                </label>
                            </>
                            :
                            null
                        }
                    </div>

                }
            </>
        );
    }
}

export default CursosList;

