import { Component } from "react";
import colors from "../../Configuration/Colors.config"
import Cookies from 'universal-cookie';
import cursoModel from "../../Model/Curso.model";
import NavBar from "../../Components/NavBar";
import imgNueva from "../../Multimedia/imgNueva.png";
import IconButton from "@material-ui/core/IconButton";
import swal2 from 'sweetalert2';
import Time from "../../Model/Time.model";
import Date from "../../Model/Date.model";
import TableData from 'react-data-table-component';
import imgYouTube from "../../Multimedia/imgYouTube.jpg";
import {
    Cached,
    Image,
    Delete,
    Backup,
    HelpOutline,
    Edit,
    PlayArrow,
    YouTube,
    Add,
    Check,
    ArrowForwardIos,
    ArrowBackIos
} from "@material-ui/icons/";
import {
    Input,
    Row,
    Col,
    Label,
    Button,
    Modal,
    Spinner,
    ModalBody,
    ModalFooter,
    Progress,
    ModalHeader,
    FormGroup
} from "reactstrap";
import moment from "moment";
import laminaModel from "../../Model/Lamina.model";
import ReactPlayer from 'react-player';
let vuelta = 0;
let porcentaje = 0;
let pesoLaminas = 0;
let log = "";
let posicionVideo = "antes"


const cookies = new Cookies();
class Lamina extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenModalPosicionVideo : false,
            tempLaminaList: [],
            dataLamina: {
                nombre: "",
                urlVideo: "",
            },
            laminas: [],
            isAddRef: false,
            isOpenVideoPlay: false,
            dataVideo: {
                IdVideo: 0,
                IdCurso: 0,
                nombreVideo: "",
                url: "",
                code: ""
            },
            videoList: [
            ],
            openModalDataVideo: false,
            openModalVideo: false,
            isMobileDevice: false,
            curso: {
                IdCurso: "",
                img: "",
                titulo: "",
                cursoNombre: "",
                subTitulo: "",
                textoInformativo: "",
                fechaCreacion: "",
                horaCreacion: "",
                mostrarCurso: 1
            },
            isOpen: false,

            porcentaje: 0,
            isLoadLaminas: false,
            isOpenLog: false,
            log: "",
            usuario: {
                IdUsuario: "",
                IdStatus: "",
                status: "",
                nombre: "",
                paterno: "",
                materno: "",
                telefono: "",
                correo: "",
                username: ""
            },
            columsVideo: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre del video</p>,
                    selector: row =>
                        <Label
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            {row.nombreVideo}
                        </Label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>URL</p>,
                    selector: row =>
                        <abbr
                            title={row.url}
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <button
                                style={{
                                    backgroundColor: "#fff",
                                    border: 'none'
                                }}
                                onClick={() => {
                                    window.open(row.url);
                                }}
                            >
                                <YouTube
                                    style={{
                                        color: 'red'
                                    }}
                                />
                                <Label
                                    style={{
                                        marginLeft: "3px",
                                        marginTop: "2px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    ver video en youtube
                                </Label>
                            </button>
                        </abbr>,
                    sortable: true
                },
                //https://img.youtube.com/vi/UokZMBmnUGM/maxresdefault.jpg
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Miniatura</p>,
                    selector: row =>
                        <img
                            src={`https://img.youtube.com/vi/${row.code}/maxresdefault.jpg`}
                            height={50}
                        />
                    ,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones</p>,
                    selector: row =>
                        <>

                            <IconButton
                                style={{
                                    color: colors.azul_1
                                }}
                                onClick={() => {
                                    this.setState({
                                        dataVideo: row,
                                        openModalDataVideo: true
                                    });
                                }}
                            >
                                <Edit />
                            </IconButton>
                            {
                                this.state.isAddRef ?
                                    <IconButton
                                        style={{
                                            color: colors.azul_1
                                        }}
                                        onClick={async () => {

                                            await this.setState({
                                                dataLamina: {
                                                    ...this.state.dataLamina,
                                                    urlVideo: row.url
                                                },
                                                isAddRef: false,
                                                openModalVideo: false,
                                                // openModalVideo: false,
                                            });




                                            let array = await this.state.tempLaminaList;
                                            let temp = [];

                                            for (let i = 0; i < array.length; i++) {

                                                if (array[i].name === this.state.dataLamina.nombre) {
                                                    array[i].videoRef = this.state.dataLamina.urlVideo;

                                                    if(posicionVideo === -1){                                                        
                                                        temp.push({
                                                            ...array[i],
                                                            tieneVideo: true,
                                                            name: "Video de youtube"
                                                        });
                                                        temp.push(array[i]);
                                                    }else{
                                                        temp.push(array[i]);
                                                        temp.push({
                                                            ...array[i],
                                                            tieneVideo: true,
                                                            name: "Video de youtube"
                                                        });
                                                    }
                                                    

                                                } else {
                                                    temp.push(array[i]);
                                                }

                                            }

                                            await this.setState({
                                                // laminas: temp,
                                                isOpenModalPosicionVideo:false,
                                                tempLaminaList: temp,
                                            });

                                            // window.scroll();

                                        }}
                                    >
                                        <Check />
                                    </IconButton>
                                    :
                                    <IconButton
                                        style={{
                                            color: colors.azul_1
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                dataVideo: row,
                                                isOpenVideoPlay: true
                                            });
                                        }}
                                    >
                                        <PlayArrow />
                                    </IconButton>


                            }

                        </>,
                    sortable: true
                },
            ],

        };
    }

    // componentWillUnmount() {
    //     let IdCurso = cookies.get("IdCurso", { path: "/" });

    //     
    //     if (IdCurso != null) {

    //         this.cargarCurso(IdCurso);
    //         //cookies.remove("IdCurso",{path: "/"});
    //     }

    // }

    getCodeYouTube = (url) => {

        url = url.split("");
        url = url.reverse();
        url = url.join("");

        let code = "";

        for (let i = 0; i < url.length; i++) {

            if (url[i] === "=" && url[i + 1] === "v" && url[i + 2] === "?") break
            code = code + url[i];
        }

        code = code.split("");
        code = code.reverse();
        code = code.join("");

        let temp = code;
        code = "";


        for (let i = 0; i < temp.length; i++) {

            if (temp[i] === "&") break
            code = code + temp[i];
        }

        return code;
    }

    focusElement = (IdElemento) => {
        document.getElementById(IdElemento).focus();
    }

    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 7000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });

    eliminarDiapositivas = async () => {

        await swal2.fire({
            title: 'Atencion',
            text: `Esta seguro que quiere eliminar las diapositivas`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: colors.rojo,
            timer: "15000",
            reverseButtons: true,
            timerProgressBar: true,
            confirmButtonText: "Si eliminar",
            footer: '<p style="color:#E60026;">No se podra recuperar una ves eliminado</p>'
        }).then((result) => {
            if (result.isConfirmed) {

                laminaModel.elaminas_eliomnar_todas(this.state.curso.IdCurso, (data) => {
                    let { status } = data;
                    if (status === 200) {
                        this.Toast.fire({
                            icon: "success",
                            title: "Diapositivas eliminadas"
                        })
                    }
                });

            }
            this.setState({
                isLogarDelete: false
            });
        });

    }

    async componentDidMount() {

        document.body.style.backgroundColor = colors.azul_1;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);


        let IdCurso = cookies.get("IdCurso", { path: "/" });


        if (IdCurso != null) {

            this.cargarCurso(IdCurso);
            //cookies.remove("IdCurso",{path: "/"});
        }

        let usuario = await cookies.get('usuario', { path: "/" })

        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',

                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {
                window.location.href = '/login-administrador'
            });
        }

        laminaModel.video_cursos_list(IdCurso, async (data) => {
            let videoList = data.videos

            await this.setState({
                videoList
            });
        });

        await this.setState({
            isMobileDevice: isMobileDevice,
            usuario
        });
    }

    opneCloseModal = async () => {
        await this.setState({
            isOpen: !this.state.isOpen
        })
    }

    cargarCurso = async (IdCurso) => {
        await cursoModel.curso_porId(IdCurso, (data) => {
            let { curso } = data;

            this.setState({
                curso
            });
        });

    }

    convertirImgAbase64 = (file, callback) => {

        const reader = new FileReader();
        reader.onload = function () {
            let base64 = reader.result.replace("data:", "").replace(/^.+,/, "");
            callback(base64);

        }

        reader.readAsDataURL(file);
    }

    cargarLaminas = (file) => {
        let videoRef = "";
        if (file.videoRef) {
            videoRef = file.videoRef;
        }

        this.convertirImgAbase64(file, (data) => {
            let img = data;

            let nombre = file.name;
            laminaModel.lamina_crear(this.state.curso.IdCurso, nombre, img, videoRef, async (data) => {
                vuelta = vuelta + 1;
                porcentaje = (vuelta / this.state.laminas.length) * 100;
                porcentaje = Math.round(porcentaje, 2);

                if (data.error === true) {


                    log = log + `>>${Time.timeFormatoSimple(moment().format("HH:mm:ss"))}: No se pudo guardar la diapositiva con el nombre ${nombre}, asegurate que tenga el formato de nombre correcto\n`;


                } else {

                    if (porcentaje === 100) {
                        this.Toast.fire({
                            icon: "success",
                            title: "Diapositivas cargadas"
                        })
                    }

                }

                this.setState({
                    porcentaje: porcentaje,
                    log: log
                });
            });
        });




    }

    formatSizeUnits = (bytes) => {


        if (bytes >= 1073741824) {
            bytes = (bytes / 1073741824).toFixed(2) + ", GB";
        }
        else if (bytes >= 1048576) {
            bytes = (bytes / 1048576).toFixed(2) + ", MB";

        }
        else if (bytes >= 1024) {
            bytes = (bytes / 1024).toFixed(2) + ", KB";
        }
        else if (bytes > 1) {
            bytes = bytes + ", bytes";
        }
        else if (bytes === 1) {
            bytes = bytes + ", byte";
        }
        else {
            bytes = "0, bytes";
        }

        return bytes;
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} usuario={this.state.usuario} />
                <div
                    style={{
                        marginTop: this.state.isMobileDevice ? "10px" : "90px",
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "15px",
                        marginRight: "15px"
                    }}
                >

                    <div

                    >
                        <Row>
                            {!this.state.isMobileDevice ?
                                <Col

                                    style={{
                                        width: "250px",
                                        margin: "10px"
                                    }}
                                >


                                    <img
                                        style={{
                                            //marginLeft: "100px",
                                            borderRadius: "8px"
                                        }}
                                        src={this.state.curso.img.length === 0 || this.state.curso.img == null ? imgNueva : "data:image/*;base64," + this.state.curso.img}
                                        height="200px"
                                        alt=""
                                    />
                                </Col>
                                :
                                null
                            }

                            <Col>
                                <Label
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Curso # {this.state.curso.IdCurso}

                                    {this.state.isMobileDevice ?
                                        <IconButton
                                            onClick={() => {
                                                this.opneCloseModal();
                                            }}
                                        >
                                            <Image
                                                style={{
                                                    color: colors.azul_1
                                                }}
                                            />
                                        </IconButton>
                                        :
                                        null
                                    }
                                </Label>
                                <br />
                                <Label
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Nombre: {this.state.curso.titulo}
                                </Label>
                                <br />
                                <Label
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "bold"
                                    }}
                                >
                                    Creado el {Date.dateFormatoSimple(this.state.curso.fechaCreacion.substring(0, 10))} a las
                                </Label>
                                <Label
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        marginLeft: "3px"
                                    }}
                                >
                                    {Time.timeFormatoSimple(this.state.curso.horaCreacion)}
                                </Label>
                                {this.state.log.length > 0 ?
                                    <>
                                        <br />
                                        <Label
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                color: 'red'
                                            }}
                                        >
                                            Ocurrio un problema al momento de cargar las diapositivas <br /> revisa el log
                                        </Label>
                                    </>
                                    :
                                    null
                                }
                                {this.state.isMobileDevice ?
                                    <br />
                                    :
                                    null
                                }

                            </Col>
                        </Row>
                        {!this.state.isMobileDevice ?
                            <>
                                <Row
                                    style={{
                                        marginTop: "10px",
                                        backgroundColor: colors.blanco,
                                        margin: "10px"
                                    }}
                                >

                                    <Col>
                                        <Label
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Seleccionar diapositivas
                                        </Label>
                                        <Input
                                            type="file"
                                            multiple={true}
                                            //accept="image/*"
                                            onChange={async (e) => {

                                                pesoLaminas = 0;
                                                let files = e.currentTarget.files;
                                                let laminas = [];
                                                for (let i = 0; i < files.length; i++) {
                                                    // files[i].tieneVideo = "";
                                                    files[i].videoRef = "";
                                                    laminas.push(files[i]);

                                                    pesoLaminas = pesoLaminas + files[i].size

                                                }
                                                await this.setState({
                                                    laminas,
                                                    tempLaminaList: laminas,
                                                });



                                            }}
                                            disabled={this.state.porcentaje > 0 && this.state.porcentaje < 100 ? true : false}
                                        />

                                    </Col>
                                    <Col>
                                        <Label
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Seleccionar videos
                                        </Label>
                                        <br />
                                        <Button
                                            style={{
                                                width: "100%",
                                                backgroundColor: "#163776"//colors.azul_1,
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    openModalVideo: true,
                                                })
                                            }}
                                        >
                                            {/* <YouTube/> */}
                                            Administrar videos

                                        </Button>
                                    </Col>
                                    <Col
                                        style={{
                                            marginTop: "36px"
                                        }}
                                    >
                                        <Button
                                            onClick={async () => {
                                                if (await this.state.laminas.length === 0) {
                                                    swal2.fire({
                                                        icon: 'warning',
                                                        title: 'Atencion',
                                                        text: "No hay diapositivas para cargar",
                                                        timer: "5000",
                                                        confirmButtonColor: '#016390',
                                                        confirmButtonText: 'Okey',
                                                        allowEscapeKey: true,
                                                        timerProgressBar: true,
                                                    }).then(() => {
                                                        return;
                                                    });

                                                } else {


                                                    let data = await this.formatSizeUnits(pesoLaminas).split(',');

                                                    await swal2.fire({
                                                        icon: 'question',
                                                        title: '¿Guardar diapositivas?',
                                                        // text: `¿Estas seguro de guargar ${this.state.laminas.length == 1 ? "esta dispositiva e este curso?" : `estas ${this.state.laminas.length}  diapositivas a este curso?`}`,
                                                        text : `Se guardaran ${data[0]} ${data[1]} en la base de datos.`,
                                                        timer: "10000",
                                                        confirmButtonColor: '#016390',
                                                        confirmButtonText: 'Sí, guardar',
                                                        showCancelButton: true,
                                                        cancelButtonText: "Cancelar",
                                                        allowEscapeKey: true,
                                                        timerProgressBar: true, 
                                                        reverseButtons: true,
                                                        // footer: `<p style="color:${colors.azul_1}; font-weight: bold;">Se guardaran ${data[0]} ${data[1]}</p>`
                                                    }).then(async (value) => {
                                                        if (value.isConfirmed) {

                                                            await laminaModel.elaminas_eliomnar_todas(this.state.curso.IdCurso, (data) => {
                                                                let { status } = data;
                                                                if (status === 200) {
                                                                    this.Toast.fire({
                                                                        icon: "success",
                                                                        title: "Diapositivas eliminadas"
                                                                    })
                                                                }
                                                            });

                                                            await this.setState({
                                                                porcentaje: 0,
                                                                //isLoadLaminas: true,
                                                            })

                                                            vuelta = 0;
                                                            porcentaje = 0
                                                            this.Toast.fire({
                                                                icon: "info",
                                                                title: "Cargando diapositivas",
                                                                timer: "50000"
                                                            })
                                                            for (let i = 0; i < this.state.laminas.length; i++) {

                                                                this.cargarLaminas(this.state.laminas[i]);
                                                            }
                                                            // this.state.laminas.map((lamina) => {
                                                            //     this.cargarLaminas(lamina);                                                    
                                                            // });


                                                        }
                                                    });

                                                }

                                            }}
                                            style={{
                                                backgroundColor: colors.azul_1,
                                                marginRight: "3px",
                                                width: "200px"
                                            }}
                                            disabled={this.state.porcentaje > 0 && this.state.porcentaje < 100 ? true : false}
                                        >
                                            {this.state.porcentaje > 0 && this.state.porcentaje < 100 ?
                                                <Spinner
                                                    style={{
                                                        height: "24px",
                                                        width: "24px"
                                                    }}
                                                >
                                                    Loading...
                                                </Spinner> :
                                                <>
                                                    <Backup />
                                                    <label
                                                        style={{
                                                            marginLeft: "3px"
                                                        }}
                                                    >
                                                        Cargar diapositivas
                                                    </label>
                                                </>
                                            }


                                        </Button>
                                        <Button
                                            disabled={this.state.porcentaje > 0 && this.state.porcentaje < 100 ? true : false}
                                            onClick={async () => {

                                                await this.eliminarDiapositivas();
                                                await this.setState({
                                                    porcentaje: 0
                                                });


                                            }}
                                            style={{
                                                backgroundColor: "RED"
                                            }}
                                        >
                                            <Delete />Eliminar diapositivas
                                        </Button>
                                        <Button
                                            style={{
                                                border: "none",
                                                backgroundColor: "transparent"
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    isOpenLog: true
                                                });
                                            }}
                                        >
                                            <HelpOutline
                                                style={{
                                                    color: colors.azul_1,
                                                    marginTop: "-7px",
                                                    height: "20px"
                                                }}
                                            />
                                        </Button>
                                        {/* <Row>
                                            <Col
                                                style={{
                                                    marginTop: "36px"
                                                }}
                                            >

                                                <Button
                                                    style={{
                                                        marginTop: "10px",
                                                        backgroundColor: colors.azul_1,
                                                        width: "200px",
                                                    }}
                                                >
                                                    <Backup />
                                                    <label
                                                        style={{
                                                            marginLeft: "3px"
                                                        }}
                                                    >
                                                        Guardar Video
                                                    </label>
                                                </Button>
                                            </Col>
                                        </Row> */}
                                    </Col>

                                </Row>
                                <Row
                                    style={{
                                        marginTop: "10px",
                                        backgroundColor: colors.blanco,
                                        margin: "10px"
                                    }}
                                >
                                    {!this.state.porcentaje === 0 ?
                                        <div
                                            style={{
                                                textAlign: ""
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold", fontSize: "20px"
                                                }}
                                            >
                                                Cargando, por favor espere
                                            </Label>
                                        </div>
                                        :
                                        null
                                    }
                                    <Progress
                                        style={{
                                            backgroundColor: colors.blanco
                                        }}
                                        value={this.state.porcentaje}
                                    >
                                        {this.state.porcentaje}%
                                    </Progress>
                                </Row>
                                <Row
                                    style={{
                                        marginBottom: "100px"
                                    }}
                                >
                                    <div

                                    >
                                        <div
                                            style={{
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                            }}
                                        >
                                            {/* {this.state.tempLaminaList.map((value) => {
                                                return (
                                                    <>
                                                        <Row
                                                            style={{
                                                                marginLeft: "10px",
                                                                marginRight: "10px",
                                                            }}
                                                        >
                                                            <Col>
                                                                <Label>{value.name}</Label>
                                                            </Col>
                                                            <Col>
                                                                {

                                                                    value.tieneVideo ?

                                                                        <img
                                                                            src={imgYouTube}
                                                                            alt=""
                                                                            height="75px"
                                                                        />
                                                                        :
                                                                        <img
                                                                            src={URL.createObjectURL(value)}
                                                                            alt=""
                                                                            height="75px"
                                                                        />

                                                                }
                                                            </Col>
                                                            <Col>
                                                                <abbr
                                                                    style={{ textDecoration: "none" }}
                                                                    title="Agregar video antes de esta diapositiva"
                                                                >
                                                                    <IconButton
                                                                        style={{
                                                                            color: colors.azul_1
                                                                        }}
                                                                        onClick={async () => {
                                                                            await this.setState({
                                                                                dataLamina: {
                                                                                    ...this.state.dataLamina,
                                                                                    nombre: value.name
                                                                                },
                                                                                isAddRef: true,
                                                                                openModalVideo: true,
                                                                            });


                                                                            await console.log(this.state.dataLamina);

                                                                        }}
                                                                    >
                                                                        <Add />
                                                                    </IconButton>
                                                                </abbr>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                );
                                            })} */}
                                            <TableData
                                                title="Diapositivas seleccionadas"
                                                data={this.state.tempLaminaList}
                                                columns={[
                                                    {
                                                        name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre</p>,
                                                        selector: row =>
                                                            row.tieneVideo ?
                                                                <img
                                                                    src={imgYouTube}
                                                                    alt=""
                                                                    height="75px"
                                                                />
                                                                :
                                                                <Label>{row.name}</Label>,
                                                        sortable: true,

                                                    },
                                                    {
                                                        name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>img</p>,
                                                        selector: row =>
                                                            row.tieneVideo ?

                                                                <img
                                                                    src={`https://img.youtube.com/vi/${this.getCodeYouTube(row.videoRef)}/maxresdefault.jpg`}
                                                                    alt=""
                                                                    height="75px"
                                                                    onClick={() => {
                                                                        //
                                                                        this.setState({
                                                                            dataVideo: {
                                                                                IdVideo: 0,
                                                                                IdCurso: 0,
                                                                                nombreVideo: "",
                                                                                url: row.videoRef,
                                                                                code: ""
                                                                            },
                                                                            isOpenVideoPlay: true,
                                                                        })
                                                                    }}
                                                                />
                                                                :
                                                                <img
                                                                    src={URL.createObjectURL(row)}
                                                                    alt=""
                                                                    height="75px"
                                                                />
                                                        ,
                                                        sortable: true
                                                    },
                                                    // {
                                                    //     name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Video</p>,
                                                    //     selector: row =>
                                                    //         <Label>
                                                    //             {row.videoRef.length > 0 ? `${row.videoRef}` : ""}
                                                    //         </Label>,
                                                    //     sortable: true
                                                    // },
                                                    //YouTube
                                                    {
                                                        name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones</p>,
                                                        selector: row =>
                                                            !row.tieneVideo ?
                                                                <>
                                                                    <abbr
                                                                        style={{ textDecoration: "none" }}
                                                                        title="Agregar video"
                                                                    >
                                                                        <IconButton
                                                                            style={{
                                                                                color: colors.azul_1
                                                                            }}
                                                                            onClick={async () => {
                                                                                
                                                                                await this.setState({
                                                                                    isOpenModalPosicionVideo : true,
                                                                                });
                                                                                await this.setState({
                                                                                    dataLamina: {
                                                                                        ...this.state.dataLamina,
                                                                                        nombre: row.name
                                                                                    },
                                                                                    // isAddRef: true,
                                                                                    // openModalVideo: true,
                                                                                });


                                                                                // await console.log(this.state.dataLamina);

                                                                            }}
                                                                        >
                                                                            <Add />
                                                                        </IconButton>
                                                                    </abbr>
                                                                </>
                                                                :
                                                                null
                                                        ,
                                                        sortable: true
                                                    },

                                                ]}

                                            />
                                        </div>
                                    </div>
                                </Row>
                            </>
                            :
                            <div
                                style={{
                                    marginTop: "10px",
                                    backgroundColor: colors.blanco,
                                    margin: "10px"
                                }}
                            >
                                <Row

                                >

                                    <Input
                                        style={{
                                            //backgroundColor: colors.azul_1,
                                            width: "90%",
                                            marginLeft: "5%",

                                        }}
                                        type="file"
                                        multiple={true}
                                        accept="image/*"
                                        onChange={async (e) => {

                                            pesoLaminas = 0;
                                            let files = e.currentTarget.files;
                                            let laminas = [];
                                            for (let i = 0; i < files.length; i++) {
                                                laminas.push(files[i]);

                                                pesoLaminas = pesoLaminas + files[i].size
                                            }
                                            await this.setState({
                                                laminas,
                                                tempLaminaList: laminas,
                                            });

                                        }}
                                        disabled={this.state.porcentaje > 0 && this.state.porcentaje < 100 ? true : false}
                                    />

                                    <Button

                                        onClick={() => {
                                            if (this.state.laminas.length === 0) {
                                                swal2.fire({
                                                    icon: 'warning',
                                                    title: 'Atencion',
                                                    text: "No hay diapositivas para cargar",
                                                    timer: "5000",
                                                    confirmButtonColor: '#016390',
                                                    confirmButtonText: 'Okey',
                                                    allowEscapeKey: true,
                                                    timerProgressBar: true
                                                }).then(() => {
                                                    return;
                                                });

                                            } else {


                                                let data = this.formatSizeUnits(pesoLaminas).split(',');

                                                swal2.fire({
                                                    icon: 'question',
                                                    title: '¿Guardar Diapositivas?',
                                                    // text: `¿Quieres cargar esta ${this.state.laminas.length} diapositivas a este curso?`,
                                                    text: `Se guardaran ${data[0]} ${data[1]} en la base de datos.`,                                                    
                                                    timer: "10000",
                                                    confirmButtonColor: '#016390',
                                                    confirmButtonText: 'Sí, guardar',
                                                    showCancelButton: true,
                                                    cancelButtonText: "Cancelar",
                                                    allowEscapeKey: true,
                                                    timerProgressBar: true,
                                                    reverseButtons: true,
                                                    // footer: `<p style="color:${colors.azul_1}; font-weight: bold;">Se guardaran ${data[0]} ${data[1]}</p>`
                                                }).then((value) => {
                                                    if (value.isConfirmed) {
                                                        this.setState({
                                                            porcentaje: 0,
                                                            //isLoadLaminas: true,
                                                        })

                                                        vuelta = 0;
                                                        porcentaje = 0
                                                        this.Toast.fire({
                                                            icon: "info",
                                                            title: "Cargando diapositivas",
                                                        })
                                                        for (let i = 0; i < this.state.laminas.length; i++) {

                                                            this.cargarLaminas(this.state.laminas[i]);
                                                        }
                                                        // this.state.laminas.map((lamina) => {
                                                        //     this.cargarLaminas(lamina);                                                    
                                                        // });


                                                    }
                                                });

                                            }

                                        }}
                                        style={{
                                            backgroundColor: colors.azul_1,
                                            width: "90%",
                                            marginLeft: "5%",
                                            marginTop: "30px"
                                        }}
                                        disabled={this.state.porcentaje > 0 && this.state.porcentaje < 100 ? true : false}
                                    >
                                        {this.state.porcentaje > 0 && this.state.porcentaje < 100 ?
                                            <Spinner
                                                style={{
                                                    height: "24px",
                                                    width: "24px"
                                                }}
                                            >
                                                Loading...
                                            </Spinner> :
                                            <>
                                                <Backup />
                                                <label
                                                    style={{
                                                        marginLeft: "3px"
                                                    }}
                                                >
                                                    Cargar Diapositivas
                                                </label>
                                            </>
                                        }


                                    </Button>
                                </Row>
                                <br />
                                <Row>
                                    <Button
                                        disabled={this.state.porcentaje > 0 && this.state.porcentaje < 100 ? true : false}
                                        onClick={async () => {

                                            await this.eliminarDiapositivas();
                                            await this.setState({
                                                porcentaje: 0
                                            });


                                        }}
                                        style={{
                                            backgroundColor: "RED",
                                            width: "90%",
                                            marginLeft: "5%"
                                        }}
                                    >
                                        <Delete />Eliminar diapositivas
                                    </Button>
                                </Row>
                                <Row>
                                    <Button
                                        style={{
                                            border: "none",
                                            backgroundColor: "transparent"
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                isOpenLog: true
                                            });
                                        }}
                                    >
                                        <HelpOutline
                                            style={{
                                                color: colors.azul_1,
                                                marginTop: "-7px",
                                                height: "20px"
                                            }}
                                        />
                                    </Button>
                                </Row>
                                <Row>
                                    {!this.state.porcentaje === 0 ?
                                        <div
                                            style={{
                                                textAlign: ""
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    fontWeight: "bold", fontSize: "20px"
                                                }}
                                            >
                                                Cargando, por favor espere
                                            </Label>
                                        </div>
                                        :
                                        null
                                    }
                                    <Col>
                                        <Progress
                                            style={{
                                                width: "100%",
                                                //marginLeft: "5%",
                                                backgroundColor: colors.blanco
                                            }}
                                            //animated
                                            value={this.state.porcentaje}
                                        >
                                            {this.state.porcentaje}%
                                        </Progress>
                                    </Col>
                                </Row>
                            </div>
                        }
                        <Row><Col></Col></Row>
                    </div>
                </div >
                <Modal isOpen={this.state.isOpen}>
                    <ModalFooter>
                        <IconButton
                            onClick={() => {
                                this.opneCloseModal();
                            }}
                        >
                            <Edit
                                style={{
                                    color: "#000"
                                }}
                            />
                        </IconButton>
                    </ModalFooter>
                    <ModalBody>
                        <div
                            style={{
                                textAlign: "center"
                            }}
                        >
                            <img
                                style={{
                                    borderRadius: "8px"
                                }}
                                src={this.state.curso.img.length === 0 || this.state.curso.img == null ? imgNueva : "data:image/*;base64," + this.state.curso.img}
                                height="200px"
                                alt=""
                            />
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.isOpenLog} size="xl">

                    <ModalHeader>
                        Detalles de la carga de diapositivas
                    </ModalHeader>

                    <ModalBody>
                        <Input
                            type="textarea" value={this.state.log}
                            style={{
                                height: "250px",
                                color: 'red',
                                fontWeight: "bold"
                            }}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={() => {
                                this.setState({
                                    isOpenLog: false,

                                });
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>
                <Modal
                    isOpen={this.state.openModalVideo}
                    size="xl"
                >

                    <ModalHeader>
                        Videos cargados a este curso
                    </ModalHeader>

                    <ModalBody>
                        <div
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button
                                style={{
                                    marginRight: "5px",
                                    backgroundColor: colors.azul_1
                                }}
                                onClick={() => {
                                    this.setState({
                                        dataVideo: {
                                            IdVideo: 0,
                                            IdCurso: this.state.curso.IdCurso,
                                            nombreVideo: "",
                                            url: "",
                                            code: "",
                                        },
                                        openModalDataVideo: true
                                    });
                                }}
                            >
                                <Add />
                            </Button>
                            <Button
                                style={{
                                    marginRight: "5px",
                                    backgroundColor: colors.azul_1
                                }}
                                onClick={() => {
                                    laminaModel.video_cursos_list(this.state.curso.IdCurso, async (data) => {
                                        let videoList = data.videos

                                        await this.setState({
                                            videoList,

                                        });
                                        this.Toast.fire({
                                            icon: "info",
                                            text: "Tabla actualizada"
                                        });
                                    });
                                }}
                            >
                                <Cached />
                            </Button>
                        </div>
                        <Row    
                            style={{
                                marginTop: "5px"
                            }}
                        >
                            <TableData
                                columns={this.state.columsVideo}
                                data={this.state.videoList}
                                clearSelectedRows
                                pagination
                                dense
                                noDataComponent={<p style={{ fontWeight: "bold" }}>No hay videos cargados</p>}
                            />
                        </Row>

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul_1
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalVideo: false,
                                    isAddRef: false,
                                })
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>

                <Modal
                    isOpen={this.state.openModalDataVideo}
                    size="md"
                    onOpened={() => {
                        this.focusElement("IdNpmbreVideo");
                    }}
                >

                    <ModalHeader>
                        Video
                    </ModalHeader>

                    <ModalBody>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label >
                                        Nombre del video
                                    </Label>
                                    <Input
                                        id="IdNpmbreVideo"
                                        name="nombreVideo"
                                        placeholder="Nombre del video"
                                        type="text"
                                        value={this.state.dataVideo.nombreVideo}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                this.focusElement("IdUrlVideo");
                                            }
                                        }}
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            this.setState({
                                                dataVideo: {
                                                    ...this.state.dataVideo,
                                                    nombreVideo: value
                                                }
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label >
                                        URL
                                    </Label>
                                    <Input
                                        id="IdUrlVideo"
                                        name="url"
                                        placeholder="URL de YouTube del video"
                                        type="text"
                                        value={this.state.dataVideo.url}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                this.focusElement("btnGuartarVideo");
                                            }
                                        }}
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            let url = value;
                                            // url = url.split("");
                                            // url = url.reverse();
                                            // url = url.join("");
                                            let code = this.getCodeYouTube(url);


                                            // for (let i = 0; i < url.length; i++) {

                                            //     if (url[i] === "=" && url[i + 1] === "v" && url[i + 2] === "?") break
                                            //     code = code + url[i];
                                            // }

                                            // code = code.split("");
                                            // code = code.reverse();
                                            // code = code.join("");

                                            // let temp = code;
                                            // code = "";


                                            // for (let i = 0; i < temp.length; i++) {

                                            //     if (temp[i] === "&") break
                                            //     code = code + temp[i];
                                            // }

                                            this.setState({
                                                dataVideo: {
                                                    ...this.state.dataVideo,
                                                    url: value,
                                                    code
                                                }
                                            })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row

                        >
                            <Col
                                style={{
                                    textAlign: "right",
                                }}
                            >
                                <Button
                                    style={{
                                        width: "30%",
                                        marginRight: "10px",
                                        backgroundColor: "red"
                                    }}
                                    onClick={() => {

                                        swal2.fire({
                                            icon: 'question',
                                            title: '¿Eliminar video?',
                                            text: `¿Estas seguro de que quieres eliminar este video?`,
                                            timer: "10000",
                                            confirmButtonColor: '#016390',
                                            confirmButtonText: 'Sí, guardar',
                                            showCancelButton: true,
                                            cancelButtonText: "Cancerlar",
                                            allowEscapeKey: true,
                                            timerProgressBar: true,
                                            reverseButtons: true,
                                            footer: `<p style="color:${colors.azul_1}; font-weight: bold;">No se podra recuperar el video una ves borrado</p>`
                                        }).then((value) => {

                                            if (value.isConfirmed) {
                                                laminaModel.video_curso_eliminar(this.state.dataVideo.IdVideo, (data) => {

                                                    laminaModel.video_cursos_list(this.state.curso.IdCurso, (data) => {
                                                        let videoList = data.videos;
                                                        this.setState({
                                                            videoList
                                                        });
                                                    });
                                                });
                                            }
                                        });


                                    }}
                                >
                                    Eliminar
                                </Button>
                                <Button
                                    id="btnGuartarVideo"
                                    style={{
                                        width: "30%",
                                        backgroundColor: colors.azul_1
                                    }}
                                    onClick={() => {

                                        if (this.state.dataVideo.nombreVideo.length === 0 || this.state.dataVideo.url.length === 0) {

                                            this.Toast.fire({
                                                icon: "warning",
                                                text: "Falta el nombre o la URL del video"
                                            });
                                        } else {

                                            laminaModel.video_cursos_guardar(this.state.dataVideo, (data) => {

                                                laminaModel.video_cursos_list(this.state.curso.IdCurso, (data) => {
                                                    let videoList = data.videos;
                                                    this.setState({
                                                        videoList
                                                    });
                                                    this.Toast.fire({
                                                        icon: "success",
                                                        text: "Se ha guardado el video"
                                                    });
                                                });

                                            });
                                        }

                                    }}
                                >
                                    Guardar
                                </Button>
                            </Col>


                        </Row>

                    </ModalBody>

                    <ModalFooter>
                        {/* <Button
                            style={{
                                backgroundColor: colors.azul_1,
                                width: "30%",
                                //width: "29%",
                                //marginRight: "1px",
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalDataVideo: false,
                                    openModalVideo: false,
                                })
                            }}
                        >
                            Cerrar Todo
                        </Button> */}
                        <Button
                            style={{
                                backgroundColor: colors.azul_1,
                                width: "30%",
                                //width: "29%",                                
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalDataVideo: false,
                                })
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>
                <Modal
                    isOpen={this.state.isOpenVideoPlay}
                    size="lg"
                >
                    <ModalHeader>
                        {this.state.dataVideo.nombreVideo}
                    </ModalHeader>

                    <ModalBody
                    // style={{
                    //     height: '450px'
                    // }}
                    >
                        <div
                            style={{
                                height: 300
                            }}
                        >
                            {/* <Row>
                                <Col>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                             fontSize: "28px"
                                        }}
                                    >
                                        {this.state.dataVideo.nombreVideo}
                                    </Label>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    <ReactPlayer
                                        url={`${this.state.dataVideo.url}`}
                                        className='react-player'
                                        playing={false}
                                        width='100%'
                                        height='300px'
                                        controls
                                        pla
                                    //playing={false}
                                    //muted
                                    />
                                </Col>
                            </Row>

                        </div>

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul_1,
                                width: "30%",
                                //width: "29%",                                
                            }}
                            onClick={() => {

                                this.setState({
                                    isOpenVideoPlay: false,
                                    dataVideo: {
                                        IdVideo: "",
                                        nombreVideo: "",
                                        url: "",
                                        code: ""
                                    }
                                })
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>
                <Modal
                    style={{
                        marginTop: "15%"
                    }}
                    isOpen={this.state.isOpenModalPosicionVideo}
                >
                    <ModalHeader>
                        Posicion del Video    
                    </ModalHeader>
                    <ModalBody
                    >
                        <Row>
                            <Col
                                // md={3}
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <abbr
                                    style={{
                                        textDecoration: "none",
                                        title: "Antes de esta diapo",
                                    }}
                                >
                                    <Button
                                        style={{
                                            margin: "5px",
                                            backgroundColor: colors.azul_1,
                                            borderColor: colors.azul_1,
                                        }}
                                        onClick={ async () => {
                                             posicionVideo = await -1;
                                             await this.setState({
                                                // isOpenModalPosicionVideo:false,
                                                isAddRef: true,
                                                openModalVideo: true,
                                            });
                                        }}
                                    >
                                        <ArrowBackIos/>
                                    </Button>
                                    <br />
                                    <Label>Antes de esta diapo</Label>
                                </abbr>                                
                            </Col>
                            <Col
                                // md={3}
                                style={{
                                    textAlign: "left"
                                }}
                            >
                                <abbr
                                    style={{
                                        textDecoration: "none",
                                        title: "Despues de esta diapos",
                                    }}
                                >
                                    <Button
                                        style={{
                                            margin: "5px",
                                            backgroundColor: colors.azul_1,
                                            borderColor: colors.azul_1,
                                        }}
                                        onClick={async () => {
                                            posicionVideo = await 1;
                                            await this.setState({
                                                // isOpenModalPosicionVideo:false,
                                                isAddRef: true,
                                                openModalVideo: true,
                                            });
                                        }}
                                    >
                                       <ArrowForwardIos/>
                                    </Button>
                                    <br />
                                    <Label>Despues de esta diapos</Label>
                                </abbr>
                                
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                this.setState({
                                    isOpenModalPosicionVideo: false,
                                })
                            }}
                            style={{
                                backgroundColor: colors.azul_1,
                                borderColor: colors.azul_1,
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

export default Lamina;

