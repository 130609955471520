import { Component } from "react";
import userModel from "../../Model/Usuario.model";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupText,
    FormFeedback,
    Button
} from "reactstrap";
import {
    AccountCircle,
    Lock,
    VisibilityOff,
    Visibility,
} from '@material-ui/icons';
import swal2 from 'sweetalert2';
//import video from "../media/video.mp4";
import colors from "../../Configuration/Colors.config"
import imgLogin from "../../Multimedia/LOGIN.png"

import Cookies from "universal-cookie";
const cookies = new Cookies();

class LoginAdministrador extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: false,
            inputType: "password",
            user: {
                username: '',
                password: '',
                IdUsuarioTipo: 1
            },
            isVisibilityPassword: false,
            opacity: 1,
            inputs: {
                username: {
                    invalid: false,
                },
                password: {
                    invalid: false,
                }
            },
            formFeedbackStyle: {
                marginLeft: "50px"
            },
            userDenegado: false,
        };
    }

    async componentDidMount() {

        document.body.style.backgroundColor = "#00548D"//colors.azul_1;
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });

        console.clear();
        //this.animacionPage();

    }

    animacionPage = async () => {
        for (let i = 0; i < 200; i++) {
            await this.setState({
                opacity: this.state.opacity + 0.007,
            });
            //await this.sleep(1);
        }   
    }

    sleep = (seconds) => new Promise(
        resolve => setTimeout(resolve, (seconds * 1000))
    );

    handleChange = async (e) => {

        this.setState({
            user: {
                ...this.state.user,
                [e.target.name]: e.target.value,
            },
            inputs: {
                ...this.state.inputs,
                [e.target.name]: {
                    invalid: e.target.value === "" ? true : false,
                },

            }
        });
    }

    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 60000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });

    validarInputsUser = async (callback) => {

        let icon = "warning";
        let text = "";
        let pasa = true;

        if (this.state.user.username === "" && this.state.user.password === "") {

            text = "usuario y contraseña no espesificados";
            pasa = false;
            await this.setState({
                inputs: {
                    username: {
                        invalid: true,
                    },
                    password: {
                        invalid: true,
                    }
                },
            });
            document.getElementById("iptNombreUsuario").focus();

        } else {

            if (this.state.user.username === "") {

                text = "usuario no espesificados";
                pasa = false;
                await this.setState({
                    inputs: {
                        username: {
                            invalid: true,
                        },
                        password: {
                            invalid: false,
                        }
                    },
                });
                document.getElementById("iptNombreUsuario").focus();
            }

            if (this.state.user.password === "") {

                text = "contraseña no espesificados";
                pasa = false;
                await this.setState({
                    inputs: {
                        username: {
                            invalid: false,
                        },
                        password: {
                            invalid: true,
                        }
                    },
                });
                document.getElementById("iptContrasenaUsuario").focus();
            }

        }

        if (!pasa) {
            await this.Toast.fire({
                icon,
                title: text,
                //text,
            })
        }

        callback(pasa);

    }

    inisiarSesion = async () => {

        this.validarInputsUser(async (response) => {


            if (response) {
                this.Toast.fire({
                    icon: "info",
                    title: "Iniciando sesión",
                    timer: "60000",
                });
                // await this.sleep(3);
                let user = this.state.user;
                userModel.usuario_login(user, (data) => {
              
                    let { status, usuario } = data;

                    let icon = "";
                    let title = "";
                    let text = "";

                    if (status === 200 && usuario.IdStatus === 1) {
                        // this.Toast.fire({
                        //     icon: "success",
                        //     title: "Acceso Autorizado",
                        //     timer: "2000",
                        // }).then(()=>{
                        //     cookies.set("usuario", usuario, { path: "/" });
                        //     cookies.set("token", usuario.token, { path: "/" });
    
                        //     window.location.href = '/listado-cursos'
                        // });
                        cookies.set("usuario", usuario, { path: "/" });
                            cookies.set("token", usuario.token, { path: "/" });
    
                            window.location.href = '/listado-cursos'
                       

                    } else {
                        if (status === 200 && usuario.IdStatus === 2) {
                            icon = "warning";
                            title = "Cuenta Bloqueada";
                            text = "Esta cuenta ha sido bloqueada por los administradores";
                        }

                        if (status === 404) {
                            icon = "error";
                            title = "Acceso denegado";
                            text = "No se encontró un perfil con ese nombre de usuario y/o contraseña";
                        }

                        swal2.fire({
                            icon,
                            title,
                            text,
                            timer: "60000",
                            confirmButtonColor: colors.azul_1,
                            confirmButtonText: 'Okey',
                            allowEscapeKey: true,
                            timerProgressBar: true,
    
                        });

                    }



                });



            }

            document.getElementById("iptNombreUsuario").focus();

        });

    }

    render() {
        return (
            <>
                {!this.state.isMobileDevice ?

                    <div
                        style={{

                            height: "650px",
                            marginLeft: "10%",
                            marginRight: "10%",
                            marginTop: "3%",
                            borderRadius: "8px",
                        }}
                    >
                        <Row
                            style={{
                                borderRadius: "8px",
                                height: "650px",
                                // marginLeft: "1%",
                                // marginRight: "1%",
                            }}
                        >
                            <Col
                                style={{
                                    backgroundColor: colors.azul_1,
                                    borderRadius: "8px 0px 0px 8px"
                                }}
                            >
                                <FormGroup
                                    style={{
                                        width: "80%",
                                        marginRight: "10%",
                                        marginLeft: "10%",
                                        marginTop: "75px"
                                    }}
                                >
                                    <p align="center">
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "40px",

                                                opacity: this.state.opacity,
                                                color: colors.blanco
                                            }}
                                        >
                                            Cursos de Domninos
                                        </Label>

                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "35px",
                                                color: colors.blanco,
                                                opacity: this.state.opacity,

                                            }}
                                        >
                                            Panel de Administracion
                                        </Label>
                                        {this.state.userDenegado ?
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "28px",
                                                    color: "#B00409",
                                                    opacity: this.state.opacity,

                                                }}
                                            >
                                                ACCESO DENEGADO
                                            </Label>
                                            :
                                            null
                                        }
                                    </p>
                                </FormGroup>
                                <FormGroup
                                    style={{
                                        width: "80%",
                                        marginRight: "10%",
                                        marginLeft: "10%",
                                        marginTop: "5px"
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                            color: colors.blanco,
                                            opacity: this.state.opacity,
                                        }}
                                    >
                                        Nombre de Usuario
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText style={{ opacity: this.state.opacity, }}>
                                            <AccountCircle style={{ color: colors.azul_1, }} />
                                        </InputGroupText>
                                        <Input
                                            id="iptNombreUsuario"
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                                opacity: this.state.opacity,
                                            }}
                                            type="usernema"
                                            placeholder="Nombre de Usuario"
                                            value={this.state.user.username}
                                            name="username"
                                            onChange={(e) => this.handleChange(e)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("iptContrasenaUsuario").focus();
                                                }
                                            }}
                                            invalid={this.state.inputs.username.invalid}
                                            autoFocus
                                        />
                                        <FormFeedback
                                            tooltip
                                            style={this.state.formFeedbackStyle}
                                        >
                                            Falta el Nombre de Usuario.
                                        </FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup
                                    style={{
                                        width: "80%",
                                        marginRight: "10%",
                                        marginLeft: "10%",
                                        marginTop: "50px"
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                            color: colors.blanco,
                                            opacity: this.state.opacity,
                                        }}
                                    >
                                        Contraseña
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText style={{ opacity: this.state.opacity, }}>
                                            <Lock style={{ color: colors.azul_1 }} />
                                        </InputGroupText>
                                        <Input
                                            id="iptContrasenaUsuario"
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                                opacity: this.state.opacity,
                                            }}
                                            placeholder="Contraseña"
                                            type={this.state.inputType}
                                            value={this.state.user.password}
                                            name="password"
                                            onChange={(e) => this.handleChange(e)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.inisiarSesion();
                                                }
                                            }}
                                            invalid={this.state.inputs.password.invalid}
                                        />
                                        <FormFeedback
                                            tooltip
                                            style={this.state.formFeedbackStyle}
                                        >
                                            Falta la Contraseña del Usuario.
                                        </FormFeedback>
                                        <InputGroupText
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: "#fff",
                                                opacity: this.state.opacity,
                                            }}
                                            onClick={async () => {
                                                await this.setState({
                                                    isVisibilityPassword: !this.state.isVisibilityPassword,
                                                    inputType: this.state.isVisibilityPassword ? "password" : "text",
                                                });
                                            }}>
                                            {this.state.isVisibilityPassword ?
                                                <Visibility style={{ color: colors.azul_1 }} />
                                                :
                                                <VisibilityOff style={{ color: colors.azul_1 }} />
                                            }
                                        </InputGroupText>
                                    </InputGroup>
                                    <FormGroup
                                        style={{
                                            marginTop: "50px",

                                        }}
                                    >
                                        <Button
                                            id="btnCancelar"
                                            style={{
                                                width: "45%",
                                                marginRight: "10%",
                                                backgroundColor: "#fff",
                                                borderColor: "#f5f5f5",
                                                borderRadius: "10px",
                                                color: colors.azul_1,
                                                fontSize: "19px",
                                                fontWeight: "bold",
                                                opacity: this.state.opacity,
                                            }}
                                            onClick={async () => {
                                                await this.setState({
                                                    user: {
                                                        username: '',
                                                        password: '',
                                                    },
                                                    inputs: {
                                                        username: {
                                                            invalid: false,
                                                        },
                                                        password: {
                                                            invalid: false,
                                                        }
                                                    },
                                                    userDenegado: false,
                                                });
                                                document.getElementById("iptNombreUsuario").focus();
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            id="btnInisiarSesion"
                                            style={{
                                                width: "45%",
                                                borderRadius: "10px",
                                                backgroundColor: colors.blanco,
                                                fontSize: "19px",
                                                fontWeight: "bold",
                                                opacity: this.state.opacity,
                                                color: colors.azul_1,
                                            }}
                                            onClick={() => {
                                                this.inisiarSesion();
                                            }}
                                        >
                                            Iniciar sesión
                                        </Button>
                                    </FormGroup>
                                </FormGroup>
                                <Row
                                    style={this.state.formFeedbackStyle}
                                // style={{
                                //     backgroundColor: 'red'
                                // }}
                                >
                                    <Col>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.blanco,
                                            }}
                                        >
                                            ¿No eres administrador?
                                            <a
                                                href="/"
                                                style={{
                                                    marginLeft: "7px",
                                                    //fontWeight: "normal",
                                                    color: colors.blanco,
                                                }}
                                            >ir al login de usuario</a>
                                        </Label>
                                    </Col>
                                </Row>

                            </Col>
                            <Col
                                style={{
                                    backgroundColor: "#F5F4F6",
                                    borderRadius: "0px 8px 8px 0px",
                                }}
                            >


                                <div
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        style={{
                                            //marginTop: "5%",
                                            opacity: this.state.opacity,
                                        }}
                                        width="100%"
                                        //height="100%"
                                        src={imgLogin}
                                        alt=""
                                        onMouseOver={()=>{
                                            //alert("")
                                        }}
                                    />
                                </div>

                            </Col>
                        </Row>
                    </div>
                    :
                    <div
                        style={{
                            width: "94%",
                            marginRight: "3%",
                            marginLeft: "3%",
                        }}
                    >
                        <Row
                            style={{
                                marginTop: "40px",
                            }}
                        >
                            <Col>
                                <div
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        alt=""
                                        src={imgLogin}
                                        style={{
                                            //marginLeft: "12%",
                                            height: "150px",
                                            borderRadius: "8px",
                                            opacity: this.state.opacity,
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "30px"
                            }}
                        >
                            <Col>
                                {this.state.userDenegado ?
                                    <p
                                        align="center"
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "20px",
                                                color: "#B00409",
                                                opacity: this.state.opacity,

                                            }}
                                        >
                                            ACCESO DENEGADO
                                        </Label>
                                    </p>
                                    :
                                    null

                                }
                                <FormGroup
                                    style={{
                                        marginTop: this.state.userDenegado ? "-20px" : "",
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                            color: colors.blanco,
                                            opacity: this.state.opacity,
                                        }}
                                    >
                                        Nombre de Usuario *
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText style={{ opacity: this.state.opacity, }}>
                                            <AccountCircle style={{ color: colors.azul_1 }} />
                                        </InputGroupText>
                                        <Input
                                            id="iptNombreUsuario"
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                                opacity: this.state.opacity,
                                            }}
                                            placeholder="Nombre de Usuario"
                                            type="text"
                                            value={this.state.user.username}
                                            name="username"
                                            onChange={(e) => this.handleChange(e)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.inisiarSesion();
                                                }
                                            }}
                                            invalid={this.state.inputs.username.invalid}
                                            autoFocus
                                        />
                                        <FormFeedback
                                            tooltip
                                            style={this.state.formFeedbackStyle}
                                        >
                                            Falta la Contraseña del Usuario.
                                        </FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "10px"
                            }}
                        >
                            <Col>
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                            color: colors.blanco,
                                            opacity: this.state.opacity,
                                        }}
                                    >
                                        Contraseña
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText style={{ opacity: this.state.opacity, }}>
                                            <Lock style={{ color: colors.azul_1 }} />
                                        </InputGroupText>
                                        <Input
                                            id="iptNombreUsuario"
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                                opacity: this.state.opacity,
                                            }}
                                            placeholder="Contraseña"
                                            type={this.state.inputType}
                                            value={this.state.user.password}
                                            name="password"
                                            onChange={(e) => this.handleChange(e)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    document.getElementById("iptNombreUsuario").focus();
                                                }
                                            }}
                                            invalid={this.state.inputs.password.invalid}
                                        />
                                        <FormFeedback
                                            tooltip
                                            style={this.state.formFeedbackStyle}
                                        >
                                            Falta la Contraseña del Usuario.
                                        </FormFeedback>
                                        <InputGroupText
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: "#fff",
                                                opacity: this.state.opacity,
                                            }}
                                            onClick={async () => {
                                                await this.setState({
                                                    isVisibilityPassword: !this.state.isVisibilityPassword,
                                                    inputType: this.state.isVisibilityPassword ? "password" : "text",
                                                });
                                            }}>
                                            {this.state.isVisibilityPassword ?
                                                <Visibility style={{ color: colors.azul_1 }} />
                                                :
                                                <VisibilityOff style={{ color: colors.azul_1 }} />
                                            }
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                marginTop: "10px",

                            }}
                        >
                            <Col>
                                <FormGroup
                                >
                                    <Button
                                        id="btnInisiarSesion"
                                        style={{
                                            width: "100%",
                                            borderRadius: "8px",
                                            backgroundColor: colors.blanco,
                                            fontSize: "19px",
                                            fontWeight: "bold",
                                            opacity: this.state.opacity,
                                            color: colors.azul_1,

                                        }}
                                        onClick={() => {
                                            this.inisiarSesion();
                                        }}
                                    >
                                        Iniciar sesión
                                    </Button>
                                    <Button
                                        id="btnCancelar"
                                        style={{
                                            width: "100%",
                                            //marginRight: "2%",
                                            backgroundColor: colors.blanco,
                                            borderColor: "#f5f5f5",
                                            borderRadius: "8px",
                                            color: colors.azul_1,
                                            fontSize: "19px",
                                            fontWeight: "bold",
                                            opacity: this.state.opacity,
                                            marginTop: "25px"
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                user: {
                                                    username: '',
                                                    password: '',
                                                },
                                                inputs: {
                                                    username: {
                                                        invalid: false,
                                                    },
                                                    password: {
                                                        invalid: false,
                                                    }
                                                },
                                                userDenegado: false,
                                            });
                                            document.getElementById("iptNombreUsuario").focus();
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                </FormGroup>
                                <Row
                                    style={{
                                        marginTop:"30px"
                                    }}
                                >
                                    <Col>
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                color: colors.blanco,
                                            }}
                                        >
                                            ¿No eres administrador?
                                            <a
                                                href="/"
                                                style={{
                                                    marginLeft: "7px",
                                                    //fontWeight: "normal",
                                                    color: colors.blanco,
                                                }}
                                            >ir al login de usuario</a>
                                        </Label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                }
            </>
        );
    }
}



export default LoginAdministrador;

