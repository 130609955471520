import { Component } from "react";
import NavBar from "../../Components/NavBar";
import colors from "../../Configuration/Colors.config";
import Cookies from "universal-cookie";
import userModel from "../../Model/Usuario.model";
//import browserModel from "../Model/Browser.model.js";
import Table from "react-data-table-component";
//import phoneNumber from "../../Model/Phone.model.js";
import IconButton from "@material-ui/core/IconButton";
import {
    //Delete,
    Search,
    Update,
    Visibility,
    WhatsApp,
    PhoneEnabled,
    Settings,
    Person,
    List,
    Replay
} from "@material-ui/icons/";
import {
    Row,
    Col,
    Label,
    Input,
    FormGroup,
    InputGroup,
    InputGroupText,
    FormFeedback,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Spinner
} from "reactstrap"

import swal2 from 'sweetalert2'



const cookies = new Cookies();


class UsersList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            estaBuscando: false,
            busqueda: {
                tipoBusqueda: 1,
                referencia: "",
                IdStatusUsuario: 1
            },
            invalid: false,
            textBuscar: "Todos",
            usuario: {},
            dataUsuario: {},
            referencia: "",
            isMobileDevice: false,
            colorStatus: {
                1: "#57AF57",
                2: "#A1A1A1",
                //3: "#C20000",
            },
            colorGenero: {
                1: "#A1A1A1",
                2: "#00BAEF",
                3: "#E32977",
            },
            isOpenModal: false,
            colums: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.nombre + " " + row.paterno + " " + row.materno} >{row.nombre + " " + row.paterno + " " + row.materno}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre de Usuario</p>,
                    selector: row => row.username,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Tipo de Usuario</p>,
                    selector: row =>
                        <>
                            {row.IdUsuarioTipo === 1 ?
                                <Settings
                                    style={{
                                        color: colors.azul_1,
                                    }}
                                />
                                :
                                <Person
                                    style={{
                                        color: colors.azul_1,
                                        backgroundColor: colors.blanco
                                    }}
                                />
                            }
                            <label
                                style={{
                                    marginLeft: "5px",
                                }}
                            >{row.nombreUsuarioTipo}</label>
                        </>
                    ,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Telefono</p>,
                    selector: row =>
                        <button
                            style={{
                                border: "none",
                                backgroundColor: "#FFFFFF"
                            }}
                            onClick={async () => {
                                await this.setState({
                                    dataUsuario: row
                                })
                                this.openCloseModal();
                            }}
                        >
                            {row.telefono}
                        </button>
                    // {<button
                    //     style={{
                    //         border: "none",
                    //         backgroundColor: "transparent"
                    //     }}
                    //     onClick={() => {
                    //         if (row.telefono.length > 0) window.location.href = "tel:" + row.telefono;
                    //     }}
                    // >
                    //     {row.telefono}
                    // </button> 
                    //  https://api.whatsapp.com/send?phone="+529613004618" 
                    // <a href='https://wa.me/+529611778913' >{row.telefono}</a>

                    ,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Correo</p>,
                    selector: row => <abbr title={`${row.correo}`} style={{ textDecoration: "none" }}>{row.correo}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Estatus</p>,
                    selector: row => <p style={{ fontWeight: "bold", color: this.state.colorStatus[row.IdStatus], marginTop: "15px" }}>{row.status}</p>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Genero</p>,
                    selector: row => <p style={{ fontWeight: "bold", color: this.state.colorGenero[row.IdGenero], marginTop: "15px" }}>{row.genero}</p>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Acciones</p>,
                    selector: row =>
                        <>
                            <abbr
                                style={{ textDecoration: "none" }}
                                title="Ver al usuario"
                            >
                                <IconButton
                                    style={{
                                        color: colors.azul_1
                                    }}
                                    onClick={() => {
                                        cookies.set("dataUsuario", row, { path: "/" });
                                        window.location.href = "/ver-usuario";
                                    }}
                                >
                                    <Visibility />
                                </IconButton>
                            </abbr>
                            {/* <abbr
                                style={{ textDecoration: "none" }}
                                title="Ver historial del usuario"
                            >
                                <IconButton
                                    style={{
                                        color: colors.azul_1
                                    }}
                                    onClick={() => {
                                        cookies.set("dataUsuario", row, { path: "/" });
                                        //window.location.href = "/ver-usuario";
                                    }}
                                >
                                    <Update />
                                </IconButton>
                            </abbr> */}
                        </>,
                    sortable: true
                },
            ],
            usuariosList: [
                // {
                //     IdUsuarios: "",
                // }
            ]
        }
    }

    Toast = swal2.mixin({
        toast: true,
        position: 'bottom-start',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });

    focusElement = (IdElemento) => {
        document.getElementById(IdElemento).focus();
    }

    async componentDidMount() {

        document.body.style.backgroundColor = colors.azul_1;
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });

        this.cargarDatosPagina();

    }


    cargarDatosPagina = async () => {
        let usuario = await cookies.get('usuario', { path: "/" })

        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',

                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {
                window.location.href = '/login-administrador'
            });

        } else {
            await this.listarUsuarios(usuario);
        }
        await this.setState({
            usuario
        });

    }



    listarUsuarios = async (usuario) => {

        await userModel.usuarios_list(usuario, (data) => {
            let usuariosList = data.usuarios;
            this.setState({
                usuariosList,
                invalid: false,
            })
        });
    }

    buscarUsuario = async () => {



        if (this.state.referencia.length === 0) {
            await this.setState({
                invalid: true,
            })
            this.Toast.fire({
                icon: "warning",
                title: "Agrega una referencia"
            })
            return;
        }

        await this.setState({
            estaBuscando: true,
        });

        await userModel.buscar_usuario({ IdUsuario: this.state.usuario.IdUsuario, referencia: this.state.referencia }, async (data) => {
            let usuariosList = await data.usuarios;
            if (!this.state.isMobileDevice) {
                this.Toast.fire({
                    icon: usuariosList.length === 0 ? "warning" : "success",
                    title: usuariosList.length === 0 ? "No se encontraron usuarios" : `Se encontraron ${usuariosList.length} usuarios`,
                });
            }

            await this.setState({
                usuariosList: usuariosList == null ? [] : usuariosList,
                estaBuscando: false,
            })
        });
    }


    openCloseModal = async () => {
        await this.setState({
            isOpenModal: !this.state.isOpenModal
        });
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} usuario={this.state.usuario} />
                <div
                    style={{
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginTop: "80px",
                        height: "90%",
                    }}
                >
                    <Row
                        style={{
                            marginTop: "20px"
                        }}
                    >
                        <Col
                            style={{
                                marginTop: "20px",
                                marginLeft: "20px",
                                width: "50%",
                                //backgroundColor: "red",
                            }}
                        >

                            <Row>
                                <FormGroup
                                    style={{

                                    }}
                                >
                                    <Label
                                        style={{
                                            //fontWeight: "bold",
                                            fontSize: "20px",
                                            color: "#000",
                                        }} F
                                    >
                                        Buscar Usuario
                                    </Label>
                                    <InputGroup>

                                        <Input
                                            id="iptTextUsuarioBuscar"
                                            style={{
                                                backgroundColor: "#FFFFFF",
                                            }}
                                            type="text"
                                            placeholder="Nombre(s), Apellidos, Nombre de usuario, telefono, correo"
                                            value={this.state.referencia}

                                            onChange={async (e) => {
                                                let { value } = e.target
                                                await this.setState({
                                                    referencia: value,
                                                    invalid: value.length === 0 ? true : false
                                                })
                                            }}

                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    this.buscarUsuario();
                                                }
                                            }}
                                            autoFocus
                                            invalid={this.state.invalid}
                                        />
                                        <FormFeedback
                                            tooltip
                                            style={this.state.formFeedbackStyle}
                                        >
                                            Espesifique una referencia para buscar al usuario.
                                        </FormFeedback>
                                        <InputGroupText
                                            onClick={() => {
                                                this.buscarUsuario();
                                            }}
                                            style={{
                                                backgroundColor: "#fff",
                                                cursor: "pointer",
                                            }}
                                        >
                                            {this.state.estaBuscando ? <Spinner style={{ marginTop: "0px", fontSize: "14px", height: "24px", width: "24px", color: colors.azul_1 }} >Loading...</Spinner> : <Search style={{ color: colors.azul_1, }} />}
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Row>
                        </Col>
                        {!this.state.isMobileDevice ?
                            <Col
                                style={{
                                    marginTop: "20px",
                                    marginRight: "30px",

                                    //backgroundColor: "blue",
                                }}
                            >
                                <p align="right">
                                    <abbr
                                        title="Cargar la tabla"
                                        style={{
                                            textDecoration: "none"
                                        }}
                                    >
                                        <button
                                            style={{
                                                backgroundColor: colors.azul_1,
                                                color: colors.blanco,
                                                border: "none",
                                                width: "60px",
                                                height: "40px",
                                                borderRadius: "8px",
                                                marginTop: "30px",
                                                marginRight: "10px"
                                            }}
                                            onClick={async () => {
                                                await this.cargarDatosPagina();
                                                this.Toast.fire({
                                                    icon: "info",
                                                    title: "Usuarios Listados"
                                                })
                                            }}
                                        >
                                            <List />
                                        </button>
                                    </abbr>
                                    <abbr
                                        title="Actualizar busqueda"
                                        style={{
                                            textDecoration: "none"
                                        }}
                                    >
                                        <button
                                            style={{
                                                backgroundColor: colors.azul_1,
                                                color: colors.blanco,
                                                border: "none",
                                                width: "60px",
                                                height: "40px",
                                                borderRadius: "8px",
                                                marginTop: "30px",
                                            }}
                                            onClick={async () => {
                                                await this.buscarUsuario();
                                                // this.Toast.fire({
                                                //     icon: "info",
                                                //     title: "Tabla actualizada"
                                                // })
                                            }}
                                        >
                                            <Replay />
                                        </button>
                                    </abbr>
                                </p>
                            </Col>
                            :
                            <Row
                                style={{
                                    marginLeft: "10px"
                                }}
                            >
                                <Col>
                                    <button
                                        style={{
                                            backgroundColor: colors.azul_1,
                                            color: colors.blanco,
                                            border: "none",
                                            width: "100%",
                                            height: "40px",
                                            borderRadius: "8px",
                                            marginTop: "30px",
                                            marginRight: "10px"
                                        }}
                                        onClick={async () => {
                                            await this.cargarDatosPagina();
                                            this.Toast.fire({
                                                icon: "info",
                                                title: "Usuarios Listados"
                                            })
                                        }}
                                    >
                                        <List />Lisar Usuarios
                                    </button>
                                </Col>
                                <Col>
                                    <button
                                        style={{
                                            backgroundColor: colors.azul_1,
                                            color: colors.blanco,
                                            border: "none",
                                            width: "100%",
                                            height: "40px",
                                            borderRadius: "8px",
                                            marginTop: "30px",
                                        }}
                                        onClick={async () => {
                                            await this.buscarUsuario();
                                            // this.Toast.fire({
                                            //     icon: "info",
                                            //     title: "Tabla actualizada"
                                            // })
                                        }}
                                    >
                                        <Replay />Refrescar
                                    </button>
                                </Col>
                            </Row>
                        }
                    </Row>
                    <div
                        style={{
                            borderRadius: "8px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}
                    >
                        <Table
                            columns={this.state.colums}
                            data={this.state.usuariosList}
                            title={this.state.isMobileDevice ? "Lista de Usuarios: " + this.state.usuariosList.length + " encontrados" : "Lista de Usuarios: " + this.state.usuariosList.length + " usuarios " + (this.state.usuariosList.length === 1 ? "listado" : "listados")}
                            noDataComponent={<p style={{ fontWeight: "bold" }}>No hay usuarios registrados</p>}
                            pagination
                            dense
                        />
                    </div>

                </div>
                <Modal
                    isOpen={this.state.isOpenModal}
                    style={{
                        marginTop: "10%"
                    }}
                >
                    <ModalHeader>
                        {this.state.dataUsuario.nombre} {this.state.dataUsuario.paterno} {this.state.dataUsuario.materno}
                    </ModalHeader>

                    <ModalBody>
                        <abbr
                            style={{
                                textDecoration: "none"
                            }}
                            title="Llamar por telefono"
                        >
                            <Button
                                style={{
                                    backgroundColor: "#22519C",
                                    borderColor: "#22519C",
                                    margin: "3px"
                                }}
                                onClick={() => {
                                    //this.openCloseModal();
                                    window.location.href = (`tel:${this.state.dataUsuario.telefono}`);
                                }}
                            >
                                <PhoneEnabled />

                            </Button>
                        </abbr>
                        <abbr
                            style={{
                                textDecoration: "none"
                            }}
                            title="Mandar mensaje por WhatsApp"
                        >
                            <Button
                                style={{
                                    backgroundColor: "#41B94E",
                                    borderColor: "#41B94E",
                                    margin: "3px"
                                }}
                                onClick={() => {
                                    //this.openCloseModal();
                                    //window.location.href = "https://wa.me/+529611778913"
                                    window.open(`https://wa.me/+52${this.state.dataUsuario.telefono}`);
                                }}
                            >
                                <WhatsApp
                                    style={{
                                        backgroundColor: "#41B94E"
                                    }}
                                />
                            </Button>
                        </abbr>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul_1
                            }}
                            onClick={() => {
                                this.openCloseModal();
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>
            </>
        )
    }
}

export default UsersList;

