import { Component } from "react";
import colors from "../../Configuration/Colors.config.js"
import Cookies from 'universal-cookie';
import NavBar from "../../Components/NavBar";
import {
    Input,
    Row,
    Col,
    FormGroup,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    InputGroup,
    InputGroupText,
    Spinner
} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import cursoModel from "../../Model/Curso.model";
import imgNueva from "../../Multimedia/imgNueva.png";
import {
    Save,
    Cached,
    Refresh,
    ToggleOff,
    ToggleOn,
    Search,
    Check,
    Delete,
    Add
} from "@material-ui/icons"
import swal2 from 'sweetalert2'
import TableData from 'react-data-table-component'
const cookies = new Cookies();


class CursoView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            usuario:{},
            isOpenModal: false,
            isSearchCurso: false,
            isMobileDevice: false,
            isLoadGuardar: false,
            isLogarDelete: false,
            curso: {
                IdCurso: "",
                img: "",
                titulo: "",
                cursoNombre: "",
                subTitulo: "",
                textoInformativo: "",
                fechaCreacion: "",
                horaCreacion: "",
                mostrarCurso: 1
            },
            cursoInput: {
                IdCurso: {
                    valid: false,
                    invalid: false
                },
                img: {
                    valid: false,
                    invalid: false
                },
                titulo: {
                    valid: false,
                    invalid: false
                },
                cursoNombre: {
                    valid: false,
                    invalid: false
                },
                subTitulo: {
                    valid: false,
                    invalid: false
                },
                textoInformativo: {
                    valid: false,
                    invalid: false
                },
            },
            //, , , cursoNombre, , textoInformativo, fechaCreacion, horaCreacion, mostrarCurso
            colums: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}># Curso</p>,
                    selector: row => row.IdCurso,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombrer Curso</p>,
                    selector: row => row.titulo,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Sub Titulo</p>,
                    selector: row => row.subTitulo,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Es Visible</p>,
                    selector: row => row.mostrarCurso === 1 ? <label style={{fontWeight: "bold"}}>Si</label>:<label style={{fontWeight: "bold"}}>No</label>,
                    sortable: true
                },
                
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Imagen del curso</p>,
                    selector: row => <img alt="" src={row.img.length === 0 ? imgNueva : "data:image/JPG;base64," + row.img} height="75px" width="125px" />,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Accion</p>,
                    selector: row =>
                        <abbr style={{ textDecoration: "none" }} title="Seleccionar Curso">
                            <IconButton
                                onClick={() => this.selecionarCurso(row)}
                            >
                                <Check style={{ color: '#016390' }} />
                            </IconButton>
                        </abbr>,
                    sortable: true
                },
            ],
            cursosList: [],
            titulo: ""
        }
    }

    selecionarCurso = async (row) => {
        await this.setState({
            curso: row
        });
        this.openCloseModal();
    }

    openCloseModal = async () => {
        await this.setState({
            isOpenModal: !this.state.isOpenModal
        })
    }

    // componentWillUnmount() {

    //     let IdCurso = cookies.get("IdCurso", { path: "/" });

  
    //     if (IdCurso != null) {
  
    //         this.cargarCurso(IdCurso);
    //         //cookies.remove("IdCurso",{path: "/"});
    //     }
    // }

    async componentDidMount() {

        document.body.style.backgroundColor = colors.azul_1;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);


        let IdCurso = cookies.get("IdCurso", { path: "/" });


        let usuario = cookies.get('usuario', { path: "/" })
        
        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',
                
                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {
                window.location.href = '/login-administrador'
            });

        }else{

            if (IdCurso != null) {       
                this.cargarCurso(IdCurso);
                cookies.remove("IdCurso",{path: "/"});
            }

            await this.setState({
                isMobileDevice: isMobileDevice,
                usuario
            });
    
        }


     
     

    }

    cargarCurso = async (IdCurso) => {
        await cursoModel.curso_porId(IdCurso, (data) => {
            let { curso } = data;
            
            this.setState({
                curso
            });
        });

    }

    fucusElement = (Id) => {
        document.getElementById(Id).focus();
    }

    nuevoCurso = async () => {
        await this.setState({
            curso: {
                IdCurso: "",
                img: "",
                titulo: "",
                cursoNombre: "",
                subTitulo: "",
                textoInformativo: "",
                fechaCreacion: "",
                horaCreacion: "",
                mostrarCurso: 1
            },
            cursoInput: {
                IdCurso: {
                    valid: false,
                    invalid: false
                },
                img: {
                    valid: false,
                    invalid: false
                },
                titulo: {
                    valid: false,
                    invalid: false
                },
                cursoNombre: {
                    valid: false,
                    invalid: false
                },
                subTitulo: {
                    valid: false,
                    invalid: false
                },
                textoInformativo: {
                    valid: false,
                    invalid: false
                }

            }
        });

        this.fucusElement("textNobmreCurso");
    }

    handleChangeImagenCurso = async (e) => {
        let file = e.currentTarget.files[0];

        

        //let _img = URL.createObjectURL(e.currentTarget.files[0])

        let structurImg = this.formatSizeUnits(file.size).split(',');
        

        if (structurImg[1] === " MB" && parseFloat(structurImg[0]) > 1) {

            await swal2.fire({
                title: 'Atencion',
                text: `La imagen pesa ${structurImg[0]}${structurImg[1]} , este tamño  de imagen es demaciado grande para guardar`,
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: colors.azul_1,
                timer: "15000",
                reverseButtons: true,
                timerProgressBar: true,
                confirmButtonText: "Okey"
                //footer: '<p style="color:#E60026;">No se podra recuperar el cliente una ves eliminado</p>',
            });
            return;
        }

        this.convertirImgAbase64(file, (data) => {
            let img = data;

            this.setState({
                curso: {
                    ...this.state.curso,
                    img
                }
            })
        });

    }

    formatSizeUnits = (bytes) => {


        if (bytes >= 1073741824) {
            bytes = (bytes / 1073741824).toFixed(2) + ", GB";
        }
        else if (bytes >= 1048576) {
            bytes = (bytes / 1048576).toFixed(2) + ", MB";

        }
        else if (bytes >= 1024) {
            bytes = (bytes / 1024).toFixed(2) + ", KB";
        }
        else if (bytes > 1) {
            bytes = bytes + ", bytes";
        }
        else if (bytes === 1) {
            bytes = bytes + ", byte";
        }
        else {
            bytes = "0, bytes";
        }

        return bytes;
    }

    handleChangeDatosCurso = async (e) => {

        let { name, value } = e.target;
        console.log(name, value);
        if(! (value.charAt(value.length - 1) == '"') ){   
            await this.setState({
                curso: {
                    ...this.state.curso,
                    [name]: value
                },
                cursoInput: {
                    ...this.state.cursoInput,
                    [name]: {
                        ...this.state.cursoInput[name],
                        valid: value.length > 0 && value.length <= 50 ? true : false,
                        invalid: !(value.length > 0) && (value.length <= 50) ? true : false
                    }
                }
            })
        }else{
            this.Toast.fire({
                icon:"warning",
                title:"Caracter invalido",
                text : `No se adminte el caracter doble comilla ( " ) `
            });
        }
        

    }



    convertirImgAbase64 = (file, callback) => {
        const reader = new FileReader();
        reader.onload = function () {
            callback(reader.result.replace("data:", "").replace(/^.+,/, ""));
        }
        reader.readAsDataURL(file);
    }

    validarInputs = () => {

        let value = true;

        if (this.state.curso.titulo.length === 0) {
            value = false;
            this.fucusElement("textNobmreCurso");
            this.setState({
                cursoInput: {

                    img: {
                        valid: false,
                        invalid: true
                    },
                    titulo: {
                        valid: false,
                        invalid: true
                    },
                    subTitulo: {
                        valid: false,
                        invalid: true
                    },
                    textoInformativo: {
                        valid: false,
                        invalid: true
                    }
                }
            });

        }

        return value;

    }

    guardarCurso = async () => {

        let value = this.validarInputs();


        if (!value) {
            await swal2.fire({
                title: 'Faltan poner el nombre del curso',
                text: "Es necesario ponerle por lo menos nombre al curso",
                icon: 'warning',
                //showCancelButton: true,
                confirmButtonColor: colors.azul_1,
                timer: "15000",
                reverseButtons: true,
                timerProgressBar: true,
                confirmButtonText: "Okey"
                //footer: '<p style="color:#E60026;">No se podra recuperar el cliente una ves eliminado</p>',
            });
            await this.setState({
                isLoadGuardar: false
            })
            return;
        }


        await swal2.fire({
            title: '¿ ' + (this.state.curso.IdCurso === "" ? "Guardar" : "Actualizar") + ' Curso?',
            text: "¿Estas seguro de que quieres " + (this.state.curso.IdCurso === "" ? "guardar" : "actualizar") + " este curso?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: colors.azul_1,
            timer: "15000",
            reverseButtons: true,
            timerProgressBar: true,
            confirmButtonText: 'Sí, ' + (this.state.curso.IdCurso === "" ? "guardar" : "actualizar")
            //footer: '<p style="color:#E60026;">No se podra recuperar el cliente una ves eliminado</p>',
        }).then((result) => {
            if (result.isConfirmed) {
                if (this.state.curso.IdCurso === "") {
                    cursoModel.cursos_crear(this.state.curso, (data) => {

                        this.setState({
                            curso: {
                                ...this.state.curso,
                                IdCurso: data.IdCurso
                            }

                        });
                        if (data.status === 200) {
                            this.Toast.fire({
                                icon: "success",
                                title: 'Curso Guardado',
                                //text: `Se encontraron ${cursosList.length} cursos`,
                                timer: "7000"
                            });
                        }
                    });
                } else {
                    cursoModel.curso_actualizar(this.state.curso, (data) => {

                        if (data.status === 200) {
                            this.Toast.fire({
                                icon: "success",
                                title: 'Curso actualizado',
                                //text: `Se encontraron ${cursosList.length} cursos`,
                                timer: "7000"
                            });
                        }
                    });
                }
            }
        });


        await this.setState({
            isLoadGuardar: false
        })



        this.fucusElement("textNobmreCurso");
    }


    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 7000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });

    buscarCurso = async () => {

        await cursoModel.cursos_buscar(this.state.titulo, (data) => {
            let cursosList = data.cursos;

            this.setState({
                cursosList,
                isSearchCurso: false
            });

            this.Toast.fire({
                icon: cursosList.length === 0 ? "warning" : "info",
                title: `Se encontraron ${cursosList.length} cursos`
            });



        });

    }

    eliminarCursoPorId = async () => {

        await swal2.fire({
            title: 'Atencion',
            text: `Esta seguro que quiere eliminar el curso ${this.state.curso.titulo}`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: colors.rojo,
            timer: "15000",
            reverseButtons: true,
            timerProgressBar: true,
            confirmButtonText: "Sí, eliminar",
            cancelButtonText:"Cancelar"
            //footer: '<p style="color:#E60026;">No se podra recuperar el cliente una ves eliminado</p>',
        }).then((result) => {
            if (result.isConfirmed) {
                cursoModel.curso_elimiar_porId(this.state.curso.IdCurso, (data) => {

                    this.nuevoCurso();
                    this.Toast.fire({
                        icon: "success",
                        title: "Curso eliminado"
                    });

                });
            }
            this.setState({
                isLogarDelete: false
            });
        });
        this.setState({
            isLogarDelete: false
        });
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} usuario={this.state.usuario}/>
                <div
                    style={{
                        marginTop: this.state.isMobileDevice ? "10px" : "90px",
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "15px",
                        marginRight: "15px"
                    }}
                >
                    <Row
                        style={{
                            marginTop: "30px",
                            margin: "10px"
                        }}
                    >
                        <Label
                            style={{
                                fontSize: "22px",
                                fontWeight: "bold",
                                marginTop: "10px"
                            }}
                        >
                            Datos del Curso {this.state.curso.IdCurso !== "" ? `#${this.state.curso.IdCurso}` : ''}
                        </Label>
                    </Row>
                    <Row
                        style={{
                            margin: "10px"
                        }}
                    >
                        <Col
                            md={5}
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontSize: "18px"
                                    }}
                                >
                                    Nombre del Curso
                                    <abbr
                                        style={{
                                            textDecoration: "nonde"
                                        }}
                                        title="Buscar Cursos"
                                    >
                                        <button
                                            style={{
                                                border: "none",
                                                color: colors.azul_1,
                                                backgroundColor: "transparent"
                                            }}
                                            onClick={async () => {
                                                await this.openCloseModal();
                                                await this.setState({
                                                    cursosList: []
                                                });
                                            }}
                                        >
                                            <Search
                                                style={{
                                                    color: colors.azul_1,
                                                    marginLeft: "3px",
                                                    fontSize: "24px",
                                                    backgroundColor: "transparent"

                                                }}
                                            />
                                        </button>
                                    </abbr>
                                </Label>
                                {/* <a href="/" target="_blank"></a> */}
                                <Input
                                    autoFocus
                                    id="textNobmreCurso"
                                    name="titulo"
                                    placeholder="Nombre con el que veran y buscaran el cursos los usurios"
                                    type="text"
                                    valid={this.state.cursoInput.titulo.valid}
                                    invalid={this.state.cursoInput.titulo.invalid}
                                    onChange={async (e) => {

                                        if (this.state.curso.titulo.length < 50) {
                                            await this.handleChangeDatosCurso(e);
                                            await this.setState({
                                                curso: {
                                                    ...this.state.curso,
                                                    cursoNombre: e.target.value.replace(' ', '')
                                                },
                                            });
                                        }

                                    }}
                                    onKeyDown={(e) => {

                                        if (this.state.curso.titulo.length >= 50 && e.key === "Backspace") {
                                            this.setState({
                                                curso: {
                                                    ...this.state.curso,
                                                    titulo: this.state.curso.titulo.substring(0, this.state.curso.cursoNombre.length - 1)
                                                }

                                            })
                                        }
                                    }}
                                    value={this.state.curso.titulo}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Label
                                    style={{
                                        fontSize: "18px",
                                        marginTop: "3px"
                                    }}>
                                    Sub Titulo
                                </Label>
                                <Input
                                    id="examplePassword"
                                    name="subTitulo"
                                    placeholder="Subtitulo del curso"
                                    type="text"
                                    valid={this.state.cursoInput.subTitulo.valid}
                                    invalid={this.state.cursoInput.subTitulo.invalid}
                                    onChange={(e) => {
                                        if (this.state.curso.subTitulo.length < 50) {
                                            this.handleChangeDatosCurso(e);
                                        }

                                    }}
                                    onKeyDown={(e) => {

                                        if (this.state.curso.subTitulo.length >= 50 && e.key === "Backspace") {
                                            this.setState({
                                                curso: {
                                                    ...this.state.curso,
                                                    subTitulo: this.state.curso.subTitulo.substring(0, this.state.curso.subTitulo.length - 1)
                                                }

                                            })
                                        }
                                    }}
                                    value={this.state.curso.subTitulo}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label
                                    style={{
                                        fontSize: "18px"
                                    }}>
                                    Mostar Curso
                                </Label>
                                <br />
                                <button
                                    style={{
                                        marginTop: "-7px",
                                        border: "none",
                                        backgroundColor: "transparent"
                                    }}
                                    onClick={async () => {
                                        await this.setState({
                                            curso: {
                                                ...this.state.curso,
                                                mostrarCurso: this.state.curso.mostrarCurso === 1 ? 0 : 1
                                            }
                                        });
                                    }}
                                >
                                    {this.state.curso.mostrarCurso === 1 ?
                                        <ToggleOn
                                            style={{
                                                fontSize: "50px",
                                                color: colors.azul_1
                                            }}
                                        />
                                        :
                                        <ToggleOff
                                            style={{
                                                fontSize: "50px",
                                                color: "gray"
                                            }}
                                        />
                                    }
                                </button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            margin: "10px"
                        }}
                    >
                        <Col md={12}>
                            <FormGroup>
                                <Label
                                    tyle={{
                                        fontSize: "18px"
                                    }}>
                                    Texto Informativo
                                </Label>
                                <Input
                                    style={{
                                        height: "15vh"
                                    }}
                                    id=""
                                    name="textoInformativo"
                                    placeholder="Descripcion del curso"
                                    type="textarea"
                                    valid={this.state.cursoInput.textoInformativo.valid}
                                    invalid={this.state.cursoInput.textoInformativo.invalid}
                                    onChange={(e) => {
                                        //if (this.state.curso.textoInformativo.length < 50) {
                                            this.handleChangeDatosCurso(e);
                                        //}

                                    }}
                                    onKeyDown={(e) => {

                                        if (this.state.curso.textoInformativo.length >= 50 && e.key === "Backspace") {
                                            this.setState({
                                                curso: {
                                                    ...this.state.curso,
                                                    textoInformativo: this.state.curso.textoInformativo.substring(0, this.state.curso.textoInformativo.length - 1)
                                                }

                                            })
                                        }
                                    }}
                                    value={this.state.curso.textoInformativo}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            margin: "10px"
                        }}
                    >
                        <Col md={6}>
                            <FormGroup>
                                <Label style={{
                                    fontSize: "18px"
                                }}>
                                    Seleciona la Imagen del Curso
                                </Label>
                                <Input
                                    onChange={(e) => {
                                        this.handleChangeImagenCurso(e);
                                    }}
                                    name="img"
                                    placeholder="Descripcion del curso"
                                    type="file"
                                    accept="image/*"
                                    valid={this.state.cursoInput.img.valid}
                                    invalid={this.state.cursoInput.img.invalid}

                                />
                            </FormGroup>
                        </Col>
                        <Col
                            md={6}
                        >
                            <FormGroup>
                                <Label style={{
                                    fontSize: "18px"
                                }}>
                                    Esta Imagen se cargara para este curso
                                </Label>
                                <br />
                                <img
                                    style={{
                                        borderRadius: "8px"
                                    }}
                                    src={this.state.curso.img.length === 0 || this.state.curso.img == null ? imgNueva : "data:image/*;base64," + this.state.curso.img}
                                    height="200px"
                                    width={this.state.isMobileDevice ? "100%":""}
                                    alt=""
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    {!this.state.isMobileDevice ?
                        <div
                            style={{
                                textAlign: "right",
                                marginLeft: "20px",
                                marginRight: "20px"
                            }}
                        >

                            {this.state.curso.IdCurso !== "" ?
                                <>
                                    <Button
                                        style={{
                                            backgroundColor: "#059E45",
                                            width: "200px",
                                            height: "40px",
                                            marginRight: "15px"
                                        }}
                                        onClick={() => {                                            
                                            cookies.set("IdCurso", this.state.curso.IdCurso, { path: "/" });
                                            window.location.href = `/diapositivas`
                                        }}
                                    >
                                        <Add />Agregar Diapositivas
                                    </Button>
                                    <Button
                                        style={{
                                            backgroundColor: "RED",
                                            width: "200px",
                                            height: "40px"
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                isLogarDelete: true
                                            });
                                            this.eliminarCursoPorId();
                                        }}
                                    >
                                        {this.state.isLogarDelete ? <Spinner style={{ marginTop: "-3px" }} >Loading...</Spinner> : <><Delete />Eliminar</>}
                                    </Button>
                                </>
                                :
                                null
                            }
                            <Button
                                style={{
                                    backgroundColor: colors.azul_1,
                                    width: "200px",
                                    marginRight: "15px",
                                    marginLeft: "15px",
                                    height: "40px"
                                }}
                                onClick={() => {
                                    this.nuevoCurso();
                                }}
                            >
                                <Refresh style={{ marginRight: "3px" }} />Nuevo
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: colors.azul_1,
                                    width: "200px",
                                    height: "40px"
                                }}
                                onClick={() => {
                                    this.setState({
                                        isLoadGuardar: true,
                                    });
                                    this.guardarCurso();
                                }}
                            >
                                {this.state.isLoadGuardar ? <Spinner style={{ marginTop: "-3px" }} >Loading...</Spinner> : this.state.curso.IdCurso === "" ? <Save /> : <Cached />} {this.state.isLoadGuardar ? "" : this.state.curso.IdCurso === "" ? "Guarar" : "Actualizar"}

                            </Button>
                        </div>
                        :
                        <>
                            <Row>
                                <Button
                                    style={{
                                        backgroundColor: colors.azul_1,
                                        width: "90%",
                                        marginLeft: "5%",
                                        height: "40px",
                                        marginTop: "10px"
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            isLoadGuardar: true
                                        });
                                        this.guardarCurso();
                                    }}
                                >
                                    {this.state.isLoadGuardar ? <Spinner style={{ marginTop: "-3px" }} >Loading...</Spinner> : this.state.curso.IdCurso === "" ? <Save /> : <Cached />} {this.state.isLoadGuardar ? "" : this.state.curso.IdCurso === "" ? "Guarar" : "Actualizar"}

                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: colors.azul_1,
                                        width: "90%",
                                        marginLeft: "5%",
                                        height: "40px",
                                        marginTop: "10px"
                                    }}
                                    onClick={() => {
                                        this.nuevoCurso();
                                    }}
                                >
                                    <Refresh style={{ marginRight: "3px" }} />Nuevo
                                </Button>

                                {this.state.curso.IdCurso !== "" ?
                                    <>
                                        <Button
                                            style={{
                                                backgroundColor: "#059E45",
                                                width: "90%",
                                                height: "40px",
                                                marginLeft: "5%",
                                                marginTop:"10px"
                                                
                                            }}
                                           
                                            onClick={() => {                                            
                                                cookies.set("IdCurso", this.state.curso.IdCurso, { path: "/" });
                                                window.location.href = `/diapositivas`
                                            }}
                                        >
                                            <Add />Agregar Diapositivas
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: "RED",
                                                width: "90%",
                                                height: "40px",
                                                marginLeft: "5%",
                                                marginTop: "10px"
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    isLogarDelete: true,
                                                });
                                                this.eliminarCursoPorId();
                                            }}
                                        >
                                            {this.state.isLogarDelete ? <Spinner style={{ marginTop: "-3px" }} >Loading...</Spinner> : <><Delete />Eliminar</>}
                                        </Button>
                                    </>
                                    :
                                    null
                                }
                            </Row>
                        </>
                    }
                    <Row style={{ height: "10px" }} ></Row>

                </div>
                <Modal
                    isOpen={this.state.isOpenModal}
                    size="xl"
                    onOpened={() => {
                        this.fucusElement("iptNombreCurso")
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Escape") this.openCloseModal();
                    }}
                >

                    <ModalHeader>
                        <h3>Buscar Curso</h3>
                    </ModalHeader>

                    <ModalBody>
                        <Label
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            Nombre del Curso
                        </Label>

                        <InputGroup>

                            <Input
                                id="iptNombreCurso"
                                placeholder="Buscar por nombre del curso"
                                onChange={async (e) => {
                                    let { value } = e.target;
                                    await this.setState({
                                        titulo: value
                                    });
                                }}
                                value={this.state.titulo}
                                onKeyDown={async (e) => {
                                    if (e.key === "Enter") {
                                        this.fucusElement("iptNombreCurso");
                                        await this.setState({
                                            isSearchCurso: !this.state.isLoadGuardar
                                        });
                                        this.buscarCurso();

                                    }
                                }}
                            />
                            <InputGroupText
                                style={{
                                    backgroundColor: "transparent",
                                    cursor: "pointer"
                                }}
                                onClick={async () => {
                                    this.fucusElement("iptNombreCurso");
                                    await this.setState({
                                        isSearchCurso: !this.state.isLoadGuardar
                                    });
                                    this.buscarCurso();

                                }}
                            >
                                {
                                    this.state.isSearchCurso ?
                                        <Spinner size="sm" style={{ color: colors.azul_1 }} >Loading...</Spinner>
                                        :
                                        <Search
                                            style={{
                                                color: colors.azul_1,
                                                backgroundColor: "transparent"
                                            }}
                                        />
                                }
                            </InputGroupText>
                        </InputGroup>
                        <br />
                        <TableData
                            columns={this.state.colums}
                            data={this.state.cursosList}
                            clearSelectedRows
                            pagination
                            dense
                            noDataComponent={<p style={{ fontWeight: "bold" }}>No hay datos para mostar</p>}
                        />

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul_1
                            }}
                            onClick={() => {
                                this.openCloseModal();
                                this.fucusElement("textNobmreCurso");
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>
            </>

        );
    }
}

export default CursoView;

