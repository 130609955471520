import { Component } from "react";
import NavBar from "../../Components/NavBar";
import colors from "../../Configuration/Colors.config";
import Cookies from "universal-cookie";
import swal2 from 'sweetalert2'
import Table from "react-data-table-component";
import historialModel from "../../Model/Hisrorial.modal"
import Date from "../../Model/Date.model";
import imgNueva from "../../Multimedia/imgNueva.png";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Label,
    FormGroup,
    Input,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    CardImg,
    Spinner,
} from "reactstrap";
import {
    Visibility,
    Replay,    
    Edit,
    VisibilityOff
} from "@material-ui/icons/";
import IconButton from "@material-ui/core/IconButton";
import Time from "../../Model/Time.model"
import moment from "moment";
import React from 'react';


//moment.locale();
moment.lang('es', {
    months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
    monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
    weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
    weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
}
);

const cookies = new Cookies();

class HistorialUsuario extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoad: false,
            isMobileDevice: false,
            usuario: {
                IdUsuario: "",
                IdStatus: "",
                status: "",
                nombre: "",
                paterno: "",
                materno: "",
                telefono: "",
                correo: "",
                username: ""
            },
            dataHistorial: {
                fecha: "",
                hora: "",
                IdMovimiento: "",
                nombreMovimiento: "",
                Descripcion: "",
                nombreUsuarioTipo: "",
                descripcion: "",
                IdUsuario: "",
                nombre: "",
                paterno: "",
                materno: "",
                username: "",
                img: "",
            }, getGenero: {
                1: "Sin espesificar",
                2: "Homre",
                3: "Mujer"
            },
            isOpenModal: false,
            isOpenModalBuscarUSuario: false,
            colums: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Fecha y Hora</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={Date.castDate(row.fecha) + " a las " + Time.timeFormatoSimple(row.hora)} >{Date.dateFormatoSimple(row.fecha) + " a las " + Time.timeFormatoSimple(row.hora)}</abbr>, sortable: true
                },
                // {
                //     name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Hora</p>,
                //     selector: row => Time.timeFormatoSimple(row.hora),
                //     sortable: true   
                // },

                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre Completo</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.nombre + " " + row.paterno + " " + row.materno} >{row.nombre + " " + row.paterno + " " + row.materno}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre de Usuario</p>,
                    selector: row => row.username,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Movimiento</p>,
                    selector: row =>
                        <label
                            style={{
                                //fontWeight: "bold"
                            }}
                        >
                            {row.nombreMovimiento}  {row.IdCurso === 0 ? "" : row.titulo} {row.UrIdUsuario == 0 ? "" : "  " + row.Urusername}
                        </label>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Tipo de Usuario</p>,
                    selector: row =>
                        <>
                            {/* {row.IdUsuarioTipo === 1 ?
                                <Settings
                                    style={{
                                        color: colors.azul_1,
                                    }}
                                />
                                :
                                <Person
                                    style={{
                                        color: colors.azul_1,
                                        backgroundColor: colors.blanco
                                    }}
                                />
                            } */}
                            <label
                                style={{
                                    marginLeft: "5px",
                                }}
                            >
                                {row.nombreUsuarioTipo}
                            </label>
                        </>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones  </p>,
                    selector: row =>
                        <>
                            <IconButton
                                style={{
                                    color: colors.azul_1
                                }}
                                onClick={async () => {
                                    await this.setState({
                                        dataHistorial: row,
                                        isOpenModal: true
                                    });
                                }}
                            >
                                <Visibility />
                            </IconButton>
                        </>,
                    sortable: true
                },
            ],
            historialList: [],
        }

    }


    Toast = swal2.mixin({
        toast: true,
        position: 'bottom-start',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });

    async componentDidMount() {
        document.body.style.backgroundColor = colors.azul_1;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        let usuario = await cookies.get('usuario', { path: "/" });
        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                //timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',

                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {
                window.location.href = '/'
            });

        } else {

            await this.setState({
                isMobileDevice: isMobileDevice,
                usuario
            });
            let IdUsuario = await cookies.get("IdUsuario", { path: "/" });
            await this.cargarDataPage(IdUsuario);
        }

    }

    cargarDataPage = async (IdUsuario) => {
        await this.setState({
            isLoad: true,
        })
        await historialModel.historial_list_usuario(IdUsuario, (data) => {
            let historialList = data.historial;

            // for (let i = 0; i < historialList.length; i++) {
            //     console.log(historialList[i].img);
            // }


            let length = historialList.length

            this.Toast.fire({
                icon: length === 0 ? "warning" : "info",
                text: `${length} movimientos encontrados`
            });

            this.setState({
                historialList,
                isLoad: false
            })

        })
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} usuario={this.state.usuario} />
                <div
                    style={{
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginTop: this.state.isMobileDevice ? "20px" : "80px",
                        height: "90%",
                    }}
                >
                    <div
                        style={{
                            borderRadius: "8px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}
                    >
                        <Row>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "26px"
                                }}
                            >
                                Historial del usuario
                            </Label>
                        </Row>
                        <Row
                            style={{
                                textAlign: "right",
                                marginBottom: '30px'
                            }}
                        >
                            <Col
                                style={{
                                    marginRight: this.state.isMobileDevice ? "10px" : "50px"
                                }}
                            >

                                {/* <abbr
                                    style={{ textDecoration: "none" }}
                                    title="Buscar usuario"
                                >
                                    <Button
                                        style={{
                                            with: "60px",
                                            backgroundColor: colors.azul_1,
                                            width: "60px",
                                            height: "40px",
                                            marginRight: "10px"
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                isOpenModalBuscarUSuario: true,
                                            })
                                        }}
                                    >
                                        <Search />
                                    </Button>
                                </abbr> */}
                                <abbr
                                    style={{ textDecoration: "none" }}
                                    title="Actulizar Historial"
                                >
                                    <Button
                                        style={{
                                            with: "60px",
                                            backgroundColor: colors.azul_1,
                                            width: "60px",
                                            height: "40px",
                                        }}
                                        onClick={async () => {
                                            this.cargarDataPage(await cookies.get("IdUsuario", { path: "/" }));
                                        }}
                                    >
                                        {
                                            this.state.isLoad ?
                                                <Spinner
                                                    style={{
                                                        height: "24px",
                                                        width: "24px"
                                                    }}
                                                >
                                                    Loading...
                                                </Spinner>
                                                :

                                                <Replay />
                                        }

                                    </Button>
                                </abbr>
                            </Col>
                        </Row>

                        {
                            this.state.isLoad ?
                                <Row>

                                </Row>
                                :
                                <Table
                                    columns={this.state.colums}
                                    data={this.state.historialList}
                                    title={""}
                                    noDataComponent={<p style={{ fontWeight: "bold" }}>"Historial vacio"</p>}
                                    // pagination
                                    dense
                                />

                        }

                    </div>
                </div>
                <Modal isOpen={this.state.isOpenModal} size="xl" >

                    <ModalHeader>


                        Detalles del Registro del {Date.castDate(this.state.dataHistorial.fecha) + " a las " + Time.timeFormatoSimple(this.state.dataHistorial.hora)}

                        {/* <Button
                            style={{
                                marginRight: "10px",
                                backgroundColor: colors.azul_1,
                                width:"60px",
                                marginLeft: "0%"
                            }}
                        >
                            X
                        </Button> */}




                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label >
                                        Fecha
                                    </Label>
                                    <Input
                                        style={{
                                            backgroundColor: colors.blanco,
                                        }}
                                        disabled
                                        type="text"
                                        value={this.state.dataHistorial.fecha.substring(0, 10)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label >
                                        Hora
                                    </Label>
                                    <Input
                                        style={{
                                            backgroundColor: colors.blanco,
                                        }}
                                        disabled
                                        type="text"
                                        value={this.state.dataHistorial.hora}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label >
                                        Nombre Completo
                                    </Label>
                                    <Input
                                        style={{
                                            backgroundColor: colors.blanco,
                                        }}
                                        disabled
                                        type="text"
                                        value={this.state.dataHistorial.nombre + " " + this.state.dataHistorial.paterno + " " + this.state.dataHistorial.materno}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label >
                                        Usuario
                                    </Label>
                                    <Input
                                        style={{
                                            backgroundColor: colors.blanco,
                                        }}
                                        disabled
                                        type="text"
                                        value={this.state.dataHistorial.username}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label >
                                        Tipo Usuario
                                    </Label>
                                    <Input
                                        style={{
                                            backgroundColor: colors.blanco,
                                        }}
                                        disabled
                                        type="text"
                                        value={this.state.dataHistorial.nombreUsuarioTipo}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label >
                                        Tipo de Movimiento
                                    </Label>
                                    <Input
                                        style={{
                                            backgroundColor: colors.blanco,
                                        }}
                                        disabled
                                        type="text"
                                        value={this.state.dataHistorial.nombreMovimiento}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label >
                                        Descripcion
                                    </Label>
                                    <Input
                                        style={{
                                            backgroundColor: colors.blanco,
                                        }}
                                        disabled
                                        type="textarea"
                                        value={this.state.dataHistorial.Descripcion}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        {this.state.dataHistorial.IdCurso === 0 ?
                            null
                            :
                            <Row>
                                <Col
                                    md={4}
                                >
                                    <Card                                        >
                                        <CardImg
                                            alt="Card image cap"
                                            width={100}
                                            src={this.state.dataHistorial.img.length === 0 ? imgNueva : "data:image/*;base64," + this.state.dataHistorial.img}
                                            style={{
                                                height: "200px"
                                            }}
                                            top
                                        />
                                        <CardBody>
                                            <p align="right">
                                                <abbr
                                                    title={`Curso ${this.state.dataHistorial.mostrarCurso === 1 ? "visible" : "no visible"}`}
                                                >
                                                    {
                                                        this.state.dataHistorial.mostrarCurso === 1 ?
                                                            <Visibility
                                                                style={{
                                                                    color: colors.azul_1,
                                                                    textDecoration: "nonde"
                                                                }}
                                                            />
                                                            :
                                                            <VisibilityOff
                                                                style={{
                                                                    color: "grey"
                                                                }}
                                                            />
                                                    }
                                                </abbr>
                                                <abbr
                                                    style={{
                                                        textDecoration: "nonde",
                                                    }}
                                                    title="Editar el curso"
                                                >
                                                    <IconButton
                                                        onClick={() => {
                                                            cookies.set("IdCurso", this.state.dataHistorial.IdCurso, { path: "/" });
                                                            window.location.href = "/ver-curso"
                                                            //window.open("/ver-curso");
                                                        }}
                                                    >


                                                        <Edit
                                                            style={{
                                                                color: colors.azul_1
                                                            }}
                                                        />
                                                    </IconButton>
                                                </abbr>
                                            </p>
                                            <CardTitle tag="h5">
                                                {this.state.dataHistorial.titulo}
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                            >
                                                {this.state.dataHistorial.subTitulo}
                                            </CardSubtitle>
                                            <CardText>
                                                {this.state.dataHistorial.textoInformativo}
                                            </CardText>
                                            <Button
                                                style={{
                                                    backgroundColor: colors.azul_1,
                                                    fontWeight: "bold"
                                                }}
                                                onClick={() => {

                                                    cookies.set("IdCurso", this.state.dataHistorial.IdCurso, { path: "/" });
                                                    //window.location.href = "/curso-privado";
                                                    window.open("/curso-privado");
                                                }}
                                            >
                                                Diapositivas
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor: colors.azul_1,
                                                    fontWeight: "bold",
                                                    width : "125px",
                                                    marginLeft: "5px"
                                                }}
                                                onClick={() => {

                                                    cookies.set("IdCurso", this.state.dataHistorial.IdCurso, { path: "/" });
                                                    window.open("/videos-del-curso");
                                                    //window.location.href = "/videos-del-curso";
                                                }}
                                            >
                                                Video
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {this.state.dataHistorial.UrIdUsuario == 0 ?
                            null
                            :
                            <>
                                <Row>
                                    <Label
                                        style={{
                                            fontWeight: "bold", fontSize: "22px"
                                        }}
                                    >
                                        Detalles
                                    </Label>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label >
                                                Nombre Completo
                                            </Label>
                                            <Input
                                                style={{
                                                    backgroundColor: colors.blanco,
                                                }}
                                                disabled
                                                type="text"
                                                value={this.state.dataHistorial.Urnombre + " " + this.state.dataHistorial.Urpaterno + " " + this.state.dataHistorial.Urmaterno}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label >
                                                Nombre de Usuario
                                            </Label>
                                            <Input
                                                style={{
                                                    backgroundColor: colors.blanco,
                                                }}
                                                disabled
                                                type="text"
                                                value={this.state.dataHistorial.Urusername}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label >
                                                Telefono
                                            </Label>
                                            <Input
                                                style={{
                                                    backgroundColor: colors.blanco,
                                                }}
                                                disabled
                                                type="text"
                                                value={this.state.dataHistorial.Urtelefono}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label >
                                                Correo
                                            </Label>
                                            <Input
                                                style={{
                                                    backgroundColor: colors.blanco,
                                                }}
                                                disabled
                                                type="text"
                                                value={this.state.dataHistorial.Urcorreo}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label >
                                                Genero
                                            </Label>
                                            <Input
                                                style={{
                                                    backgroundColor: colors.blanco,
                                                }}
                                                disabled
                                                type="text"
                                                value={this.state.getGenero[this.state.dataHistorial.UrIdGenero]}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </>

                        }
                    </ModalBody>

                    <ModalFooter>
                        {/* <Button
                            onClick={async () => {
                                await cookies.set("IdUsuario", this.state.dataHistorial.IdUsuario, { path: "/" });        
                                await window.open("/historial-de-usuarios");
                            }}
                            style={{
                                backgroundColor: "#A1A1A1",                              
                            }}
                        >
                            Ver historial de este usuario asd
                        </Button> */}
                        <Button
                            onClick={async () => {
                                await this.setState({
                                    isOpenModal: false,
                                });
                            }}
                            style={{
                                backgroundColor: colors.azul_1
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>

                <Modal
                    isOpen={this.state.isOpenModalBuscarUSuario}
                    size="xl"
                >
                    <ModalHeader>

                    </ModalHeader>

                    <ModalBody>

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            onClick={() => {
                                this.setState({
                                    isOpenModalBuscarUSuario: false,
                                })
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>

            </>
        );
    };


}

export default HistorialUsuario;