const isProducction = true;
var API = "";

if (isProducction) {
    
    // API = "https://lddevsoft.com/api";
    API = "https://dominosgpm.com.mx/api"

} else {

    // const IP = "192.168.3.145";
    // const IP = "192.168.1.87";
    const IP = "192.168.1.96";

    const PORT = "5001";

    API = `http://${IP}:${PORT}/api`;

}

export default API;

//export default "https://api-rest-adc.herokuapp.com"+"/api";