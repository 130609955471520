import axios from 'axios';
import API from '../Network/Api.js';
import swal2 from 'sweetalert2';


import Cookies from 'universal-cookie';
const cookies = new Cookies();

const mensajeError = (error) => {
    swal2.fire({
        icon: 'error',
        title: 'Error',
        text: "Error de Red",
        //timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true
    }).then(() => {
        //window.location.href="/";
    });
}


const historial_nuevo_movimiento = (dataMovimiento, callback) => {
     
    axios({
        method: "POST",
        url: API + "/historial/historial_nuevo_movimiento",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },
        data: {
            dataMovimiento
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const historial_list = (callback) => {
     
    axios({
        method: "GET",
        url: API + "/historial/historial_list",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}


const historial_list_usuario = (IdUsuario,callback) => {
    axios({
        method: "GET",
        url: API + "/historial/historial_list_usuario/"+IdUsuario,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const usuario_cursos_vistos = (IdUsuario,callback) => {
    axios({
        method: "GET",
        url: API + "/historial/usuario_cursos_vistos/"+IdUsuario,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const historialModel = {
    historial_nuevo_movimiento,
    historial_list,
    historial_list_usuario,
    usuario_cursos_vistos,
}

export default historialModel;