import React, { useState } from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Navbar,
    Nav,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,

} from 'reactstrap';
//import IconButton from "@material-ui/core/IconButton";
import {
    List,
    Menu,
    Add,
    Person,
    Apps,
    ExitToApp,
    Dashboard,
    ViewCarousel,
    PersonOutline,
    Group,
    PersonAdd,
    Update,
} from '@material-ui/icons';
import historialModel from "../Model/Hisrorial.modal";

import Cookies from 'universal-cookie';
const cookies = new Cookies();


const NavBarMovil = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [style] = useState({
        backgroundColor: "#fff",
        color: '#016390',
        fontWeight: "bold",
        border: "none",
        boxShadow: "none"
    });
    const [styleIcon] = useState({
        marginTop: "-5px",
        marginRight: "5px",
        color: "#016390"
    });


    const open = () => setIsOpen(!isOpen);


    const logOut = () => {
        let usuario = cookies.get('usuario', { path: "/" })
        let dataMovimiento = {
            IdMovimiento: 2,
            IdUsuario: usuario.IdUsuario
        }

        historialModel.historial_nuevo_movimiento(dataMovimiento, (data) => { });
        cookies.remove('usuario', { path: "/" });
        if (props.usuario == null) {
            window.location.href = "/login-administrador";
        } else {
            window.location.href = "/";
        }
    }




    return (

        <div

        >
            <Navbar expand="md" style={{
                backgroundColor: "#fff",
                height: "62px",
                width: "100%"
            }}>
                <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                </Nav>
                <Button
                    //color="primary"
                    style={{ backgroundColor: "#fff", borderColor: "#fff", boxShadow: "none", color: "#016390", fontWeight: "bold" }}
                    onClick={open}
                >
                    <Menu style={{ color: "#016390" }} />
                </Button>
                <Offcanvas
                    toggle={open}
                    isOpen={isOpen}
                    direction="end"
                    style={{
                        backgroundColor: "#fff"
                    }}
                >
                    <OffcanvasHeader toggle={open}>
                        <h2
                            style={{
                                color: "#016390",
                                fontWeight: "bold"
                            }}
                        >
                            Menu
                        </h2>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Nav vertical>

                            <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={style}

                                >
                                    <ViewCarousel style={styleIcon} />Cursos
                                </DropdownToggle>
                                <DropdownMenu>
                                    {/*                               IdUsuarioTipo */}
                                    <DropdownItem href={props.usuario.IdUsuarioTipo === 1 ? '/listado-cursos' : '/listado-cursos-publico'}>
                                        <List style={styleIcon} />Ver Cursos
                                    </DropdownItem>
                                    {props.usuario.IdUsuarioTipo === 1 ?
                                        <>
                                            <DropdownItem divider />
                                            <DropdownItem stle={styleIcon} href="/ver-curso">
                                                <Add style={styleIcon} />Agregar Curso
                                            </DropdownItem>
                                        </>
                                        :
                                        null
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {props.usuario.IdUsuarioTipo === 1 ?
                                <UncontrolledDropdown
                                    className="me-2"
                                    direction="down"
                                    setActiveFromChild
                                >
                                    <DropdownToggle
                                        caret
                                        style={style}
                                    >
                                        <Group style={styleIcon} />Usuario
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem href='/usuarios'>
                                            <List style={styleIcon} />Ver Usuarios
                                        </DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => { window.location.href = '/ver-usuario' }}>
                                            <PersonAdd style={styleIcon} />Agregar Usuario
                                        </DropdownItem>
                                        {/* <DropdownItem divider />
                                        <DropdownItem onClick={() => { window.location.href = '/historial-usuarios' }}>
                                            <Update style={styleIcon} />Ver Hisotial
                                        </DropdownItem> */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                :
                                null
                            }
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    caret
                                    style={style}
                                >
                                    <Apps /> Panel de administración
                                </DropdownToggle>
                                <DropdownMenu>
                                    {props.usuario == null ?
                                        <DropdownItem href='/login-administrador'>
                                            <Dashboard style={styleIcon} /> Panel de administración
                                        </DropdownItem>
                                        :
                                        <>
                                            {/* <DropdownItem href='/login-administrador'>
                                                <Person style={styleIcon} /> Mi Perfil
                                            </DropdownItem>
                                            <DropdownItem divider /> */}
                                            {/* <DropdownItem href='/login-administrador'>
                                                <PersonOutline style={styleIcon} /> Login
                                            </DropdownItem>
                                            <DropdownItem divider /> */}
                                            <DropdownItem onClick={() => logOut()}>
                                                <ExitToApp style={styleIcon} /> cerrar sesion
                                            </DropdownItem>
                                        </>
                                    }

                                </DropdownMenu>
                            </UncontrolledDropdown>

                        </Nav>
                    </OffcanvasBody>
                </Offcanvas>
            </Navbar>
        </div>


    );
}

export default NavBarMovil;