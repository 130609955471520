import axios from 'axios';
import API from '../Network/Api.js';
import swal2 from 'sweetalert2';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const lamina_crear = (IdCurso,nombre,img,videoRef, callback) => {

    axios({
        method: "POST",
        url: API + "/lamina/lamina_crear",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            token: cookies.get("usuario", {
                path: "/"
            }).token,
        },
        data : {
            file:{
                IdCurso,
                img,
                nombre,
                videoRef
            }
        }
    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        callback({
            error:true,
            menssaje : error
        });
  
    });
}


const elaminas_eliomnar_todas = (IdCurso, callback) => {

    axios({
        method: "DELETE",
        url: API + "/lamina/laminas_eliminar_todas/"+IdCurso,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            token: cookies.get("usuario", {
                path: "/"
            }).token,
        }
    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const lamina_obtener_primera = (IdCurso, callback) => {

    axios({
        method: "GET",
        url: API + "/lamina/lamina_obtener_primera/"+IdCurso+"/"+ cookies.get('usuario', { path: "/" }).IdUsuario,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            token: cookies.get("usuario", {
                path: "/"
            }).token,
        }           
    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const lamina_siguiente = (lamina, IdUsuario, callback) => {
    axios({
        method: "POST",
        url: API + "/lamina/lamina_siguiente/",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            token: cookies.get("usuario", {
                path: "/"
            }).token,
        },      
        data:{
            lamina,
            IdUsuario
        }  
    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const video_cursos_list = (IdCurso, callback) => {

    axios({
        method: "GET",
        url: API + "/lamina/video_cursos_list/"+IdCurso,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            token: cookies.get("usuario", {
                path: "/"
            }).token,
        },      

    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const video_cursos_guardar = (curso, callback) => {

    axios({
        method: "POST",
        url: API + "/lamina/video_cursos_guardar/",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },    
        data : {
            curso
        }  

    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}


const lamina_anterior = (lamina, callback) => {
    axios({
        method: "POST",
        url: API + "/lamina/lamina_anterior/",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },      
        data:{
            lamina
        }  
    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}


const mensajeError = (error) =>{
    swal2.fire({
        icon: 'error',
        title: 'Error',
        text: "Error en el servidor\n" + error,
       // timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true
    }).then(() => {
        //window.location.href="/";
    });
}
const video_curso_eliminar = (IdCurso, callback) => {
    axios({
        method: "DELETE",
        url: API + "/lamina/video_curso_eliminar/"+IdCurso,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },      
    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}


const diapoModel = {
    lamina_crear,
    elaminas_eliomnar_todas,
    lamina_obtener_primera,
    lamina_siguiente,
    lamina_anterior,
    video_cursos_list,
    video_cursos_guardar,
    video_curso_eliminar,
}

export default diapoModel;