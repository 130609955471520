import axios from 'axios';
import API from '../Network/Api.js';
import swal2 from 'sweetalert2';



import Cookies from 'universal-cookie';
const cookies = new Cookies();

const curso_listar = (callback) => {
    axios({
        method: "GET",
        url: API + "/cursos/curso_listar",
        headers: {
            //token : cookies.get('token')
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        }
    }).then(response => {
        let data = response.data;
        callback(data);
    }).catch((error) => {
        mensajeError(error);

    });
}

const cursos_crear = (curso, callback) => {
    curso = {
        ...curso,
        IdUsuario : cookies.get('usuario', { path: "/" }).IdUsuario
    }
    axios({
        method: "POST",
        url: API + "/cursos/cursos_crear",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },
        data : {
            curso
        }
    }).then(response => {
        let data = response.data;


        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const cursos_buscar = (titulo, callback) => {

    axios({
        method: "POST",
        url: API + "/cursos/cursos_buscar",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },       
        data:{
            titulo
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const curso_actualizar = (curso, callback) => {
    curso = {
        ...curso,
        IdUsuario : cookies.get('usuario', { path: "/" }).IdUsuario
    }
    axios({
        method: "PUT",
        url: API + "/cursos/curso_actualizar",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        },       
        data:{
            curso
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const curso_elimiar_porId = (IdCurso, callback) => {
    
    axios({
        method: "DELETE",
        url: API + "/cursos/curso_elimiar_porId/"+IdCurso+"/"+cookies.get('usuario', { path: "/" }).IdUsuario,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}


const curso_porId = (IdCurso, callback) => {

    
    axios({
        method: "GET",
        url: API + "/cursos/curso_porId/"+IdCurso,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin" : "*",
            // token: cookies.get("usuario", {
            //     path: "/"
            // }).token,
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const mensajeError = (error) =>{
    
    swal2.fire({
        icon: 'error',
        title: 'Error',
        text: "Error de Red",
        //timer: "5000",    
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true
    }).then(() => {
        //window.location.href="/";
    });
}

const CrusoModel = {
    curso_listar,
    cursos_crear,
    cursos_buscar,
    curso_actualizar,
    curso_elimiar_porId,
    curso_porId
}

export default CrusoModel;