import { Component } from "react";
import Cookies from 'universal-cookie';
import swal2 from 'sweetalert2';
import IconButton from "@material-ui/core/IconButton";
import colors from "../../Configuration/Colors.config"
import NavBar from "../../Components/NavBar";
import Table from "react-data-table-component";
import laminaModel from "../../Model/Lamina.model";
import cursoModel from "../../Model/Curso.model";
import ReactPlayer from 'react-player'
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Button,
    CardImg,
    //Modal,
    Label,
    InputGroup,
    Input,
    InputGroupText,
    Row,
    Col,
    Modal,
    Spinner,
    ModalBody,
    ModalFooter,
    Progress,
    ModalHeader,
    FormGroup
} from "reactstrap";
import {
    Edit,
    Search,
    Cached,
    Visibility,
    VisibilityOff,
    PlayArrow
} from '@material-ui/icons';


const cookies = new Cookies();

class CursoVideo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobileDevice: false,
            usuario: {
                IdUsuario: "",
                IdStatus: "",
                status: "",
                nombre: "",
                paterno: "",
                materno: "",
                telefono: "",
                correo: "",
                username: "",
                IdUsuarioTipo: ""
            },
            dataVideo: {
                IdVideo: 0,
                IdCurso: 0,
                nombreVideo: "",
                url: "",
                code: ""
            },
            isOpenVideoPlay: false,
            curso: {},
            videoList: [
            ],
            columsVideo: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Nombre del video</p>,
                    selector: row =>
                        <Label
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            {row.nombreVideo}
                        </Label>,
                    sortable: true
                },
                // {
                //     name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>URL</p>,
                //     selector: row =>
                //         <abbr
                //             title={row.url}
                //             style={{
                //                 textDecoration: 'none'
                //             }}
                //         >
                //             <button
                //                 style={{
                //                     backgroundColor: "#fff",
                //                     border: 'none'
                //                 }}
                //                 onClick={() => {
                //                     window.open(row.url);
                //                 }}
                //             >
                //                 <YouTube
                //                     style={{
                //                         color: 'red'
                //                     }}
                //                 />
                //                 <Label
                //                     style={{
                //                         marginLeft: "3px",
                //                         marginTop: "2px",
                //                         fontWeight: "bold"
                //                     }}
                //                 >
                //                     ver video en youtube
                //                 </Label>
                //             </button>
                //         </abbr>,
                //     sortable: true
                // },
                //https://img.youtube.com/vi/UokZMBmnUGM/maxresdefault.jpg
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Miniatura</p>,
                    selector: row =>
                        <img
                            src={`https://img.youtube.com/vi/${row.code}/maxresdefault.jpg`}
                            height={50}
                        />
                    ,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "16px" }}>Acciones</p>,
                    selector: row =>
                        <>
                            <IconButton
                                style={{
                                    color: colors.azul_1
                                }}
                                onClick={async () => {
                                    await this.setState({
                                        dataVideo: row,
                                        isOpenVideoPlay: true
                                    });
                                }}
                            >
                                <PlayArrow />
                            </IconButton>
                        </>,
                    sortable: true
                },
            ],
        }
    }

    async componentDidMount() {
        document.body.style.backgroundColor = colors.azul_1;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        let usuario = await cookies.get('usuario', { path: "/" });

        if (usuario == null) {
            swal2.fire({
                icon: 'error',
                title: 'Acceso Denegado',
                text: `Debes iniciar sesion para poder tener acceso al panel de administracion`,
                //timer: "10000",
                confirmButtonColor: colors.azul_1,
                confirmButtonText: 'Okey',

                allowEscapeKey: true,
                timerProgressBar: true,

            }).then(() => {

                window.location.href = '/login-administrador';

            });

        } else {

            await this.cargarDataPage();
            await this.setState({
                isMobileDevice: isMobileDevice,
                usuario
            });


        }
    }
    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 7000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });
    cargarDataPage = async () => {

        let IdCurso = await cookies.get("IdCurso", { path: "/" });

        await cursoModel.curso_porId(IdCurso, async (data) => {
            let { curso } = data;

            await this.setState({
                curso
            });

            await laminaModel.video_cursos_list(IdCurso, async (data) => {
                let videoList = data.videos

                let length = videoList.length
                await this.setState({
                    videoList
                });

                this.Toast.fire({
                    icon: length === 0 ? "warning" : "info",
                    text: length === 0 ? "No hay videos para este curso" : `Se encontraron ${length} videos para este curso`

                })
            });
        });



    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} usuario={this.state.usuario} />

                <div
                    style={{
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginTop: this.state.isMobileDevice ? "20px" : "80px",
                        height: "90%",
                    }}
                >
                    <div
                        style={{
                            borderRadius: "8px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}
                    >

                        <Row
                            style={{
                                marginBottom: "10px"
                            }}
                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "26px"
                                }}
                            >
                                {this.state.curso.titulo}
                            </Label>
                        </Row>
                        <div
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button
                                style={{
                                    marginRight: "5px",
                                    backgroundColor: colors.azul_1
                                }}
                                onClick={() => {
                                    laminaModel.video_cursos_list(this.state.curso.IdCurso, async (data) => {
                                        let videoList = data.videos
                                        await this.setState({
                                            videoList
                                        });
                                        this.Toast.fire({
                                            icon: "info",
                                            text: "Tabla actualizada"
                                        });
                                    });
                                }}
                            >
                                <Cached />
                            </Button>
                        </div>
                        <Table
                            columns={this.state.columsVideo}
                            data={this.state.videoList}
                            title={""}
                            noDataComponent={<p style={{ fontWeight: "bold" }}>"No hay videos para este curso"</p>}
                            //pagination
                            dense
                        />

                    </div>
                    <Row><Col></Col></Row>
                </div>






                <Modal
                    isOpen={this.state.isOpenVideoPlay}
                    size="lg"
                >
                    <ModalHeader>
                        {this.state.dataVideo.nombreVideo}
                    </ModalHeader>

                    <ModalBody
                    // style={{
                    //     height: '450px'
                    // }}
                    >
                        <div
                            style={{
                                height: 300
                            }}
                        >
                            {/* <Row>
                                <Col>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                             fontSize: "28px"
                                        }}
                                    >
                                        {this.state.dataVideo.nombreVideo}
                                    </Label>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    <ReactPlayer
                                        url={`${this.state.dataVideo.url}`}
                                        className='react-player'
                                        playing
                                        width='100%'
                                        height='300px'
                                        controls
                                        playing={false}
                                    //muted
                                    />
                                </Col>
                            </Row>

                        </div>

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                backgroundColor: colors.azul_1,
                                width: "30%",
                                //width: "29%",                                
                            }}
                            onClick={() => {

                                this.setState({
                                    isOpenVideoPlay: false,
                                    dataVideo: {
                                        IdVideo: "",
                                        nombreVideo: "",
                                        url: "",
                                        code: ""
                                    }
                                })
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>

                </Modal>


            </>
        );
    }


}

export default CursoVideo;